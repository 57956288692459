
import {useRef} from 'react'
import { Avatar, Box, Typography } from '@mui/material'
import React from 'react'
import RightArrowIcon from '../../../../icons/RightArrow'
import { useNavigate } from "react-router";

function LinkSection({icon,text,color,background,avatar,arrow,link,out}) {
  const navigate = useNavigate();
  const linkRef = useRef()
  const navigateHandler = ()=>{
    if(out)
    {
      linkRef.current.click()
    }
    else{
      navigate(link)
    }
    
  }
  return (
    <Box onClick={navigateHandler} sx={{cursor:'pointer',display:'flex',justifyContent:'space-between',alignItems:'center',background:background,padding:'1.2rem',borderRadius:2}}>
        <Box sx={{display:'flex',gap:1,alignItems:'center'}}>
            <Avatar sx={{background:avatar,width:30,height:30}}>
            {icon}
            </Avatar>
            
            <Typography variant='body1' component={'span'} color={color}>{text}</Typography>
        </Box>
        <Box>
            <RightArrowIcon color={arrow}/>
        </Box>
        <a style={{display:'none'}} ref={linkRef} href={link} target="_blank" rel="noopener noreferrer"></a>
    </Box>
  )
}

export default LinkSection