import { useState, useEffect, useContext } from "react";
import { dashboardMapUrl, application as applicationUrl, statistics as statisticsUrl } from "../../extentions/apiUrls";
import { default as DeleteWarning } from "../warnings/Warning";
import useHttp from "../../hook/useHttp";
import { Box, CircularProgress } from "@mui/material";
import Dashboard from "./new-design";
import ModalContext from "../../store/modal-context";
import SnackContext from "../../store/snack-context";

const DashboardFirstPage = () => {
  const [data, setData] = useState(undefined);
  const [application, setApplication] = useState(undefined);
  const [statistics, setStatistics] = useState(undefined);
  const { sendRequest: sendDashboardInfoRequests } = useHttp();
  const { isLoading: applicationLoading, sendRequest: getApplicationRequests } = useHttp();
  const { sendRequest: getstatisticsRequests } = useHttp();
  const { isLoading: removeLoading, sendRequest: removeApplicationRequest } = useHttp();
  const { isLoading: createLoading, sendRequest: createApplicationRequest } = useHttp();

  const snackCtx = useContext(SnackContext);
  const modalCtx = useContext(ModalContext);

  const sendDashboardInfoRequestsHandler = (res) => {
    if (res.data.status_code === 200) {
      setData(res.data.data);
    }
  };
  const getApplicationRequestsHandler = (res) => {
    if (res.data.status_code === 200) {
      setApplication(res.data.data);
    }
  };
  const getstatisticsRequestsHandler = (res) => {
    if (res.data.status_code === 200) {
      setStatistics(res.data.data.ticket_options.section_parts);
    }
  };
  useEffect(() => {
    let options = {
      method: "GET",
      url: dashboardMapUrl,
    };
    sendDashboardInfoRequests(options, sendDashboardInfoRequestsHandler);
  }, [sendDashboardInfoRequests]);
  useEffect(() => {
    let options = {
      method: "GET",
      url: applicationUrl,
    };
    getApplicationRequests(options, getApplicationRequestsHandler);
  }, [getApplicationRequests]);
  useEffect(() => {
    let options = {
      method: "GET",
      url: statisticsUrl,
    };
    getstatisticsRequests(options, getstatisticsRequestsHandler);
  }, [getstatisticsRequests]);

  const deleteApplicationHandler = () => {
    modalCtx.modalHandler("");
    const removeApplicationsResponseHandler = (res) => {
      modalCtx.modalHandler("");
      if (res.data.status_code === 200) {
        snackCtx.handleOpen(res.data.message, "");
        setApplication({});
      } else {
        snackCtx.handleOpen(res.data.message, "");
      }
    };
    const options = {
      method: "DELETE",
      url: applicationUrl,
    };
    removeApplicationRequest(options, removeApplicationsResponseHandler);
  };

  const handleDeleteWarningReject = () => {
    modalCtx.modalHandler("");
  };
  const handleDelete = (name, id) => {
    const content = {
      title: "",
      body: (
        <DeleteWarning
          title={"Deactivate Plan"}
          caption={`Are you sure ?`}
          description={"Your Api secret delete permanently"}
          onConfirm={deleteApplicationHandler}
          confirmCaption={'Delete'}
          onReject={handleDeleteWarningReject}
          rejectCaption={'Cancel'}
          haveReject={true}
          delete={true}
        />
      ),
    };

    modalCtx.modalHandler(content);
  };

  const createApplicationResponseHandler = (res) => {
    setApplication(res.data.data);
  };
  const createApplicationHandler = () => {
    const options = {
      method: "POST",
      url: applicationUrl,
    };
    createApplicationRequest(options, createApplicationResponseHandler);
  };

  return data === undefined ? (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress sx={{ mt: 3 }} />
    </Box>
  ) : (
    // <UserDashboard1 data={data}/>
    <Dashboard
      data={data}
      application={application}
      applicationLoading={applicationLoading}
      ticketSection={statistics}
      removeApplicationLoading={removeLoading}
      removeApplication={handleDelete}
      onCreateApplication={createApplicationHandler}
      createLoading={createLoading}
    />
  );
};

export default DashboardFirstPage;
