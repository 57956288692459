export default function UsageHistoryIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.433"
      height="15.433"
      viewBox="0 0 15.433 15.433"
    >
      <g
        fill="none"
        stroke={props.fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        transform="translate(.75 .75)"
      >
        <path
          d="M0 0L0 4.779"
          data-name="Line 11"
          transform="translate(3.742 5.714)"
        ></path>
        <path
          d="M0 0L0 7.066"
          data-name="Line 12"
          transform="translate(6.993 3.427)"
        ></path>
        <path
          d="M0 0L0 2.254"
          data-name="Line 13"
          transform="translate(10.191 8.239)"
        ></path>
        <path
          fillRule="evenodd"
          d="M10.231 0H3.7A3.589 3.589 0 000 3.891v6.151a3.586 3.586 0 003.7 3.891h6.528a3.586 3.586 0 003.7-3.891V3.891A3.586 3.586 0 0010.231 0z"
          data-name="Path 11"
        ></path>
      </g>
    </svg>
  );
}

