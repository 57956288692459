import { Grid, Typography, Divider, Box, Card, Button, IconButton } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import SnackContext from "../../../../store/snack-context";
import { useContext, useState } from "react";
import { VisibilityOff,Visibility } from "@mui/icons-material";
const ApplicationForm = ({ application,onDeleteApplication,onCopySecretKey }) => {
  const [showPassword,setShowPassword] = useState(false);
  const matches = useMediaQuery("(min-width:600px)");
  const snackCtx = useContext(SnackContext);
  return (
    <Card type="card" sx={{padding:'1.4rem'}}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItem: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography component={"span"} color="#202020" variant={"h2"} fontWeight={600}>
              Application Details
            </Typography>
          </Box>

          <Divider sx={{ mb: 2, mt: 1 }} />
        </Grid>
        <Grid item xs={12} lg={6} container >
          <Grid item xs="auto" sx={{ mr: 1 }}>
            <Typography component={"span"} variant="h6" color={"#787777"} fontWeight={"600"}>
              Name
            </Typography>
          </Grid>

          {matches ? (
            <Grid item xs sx={{ position: "relative" }}>
              <Box
                component="span"
                sx={{
                  borderBottom: "1px dashed #787777",
                  display: "block",
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: 8,
                }}
              ></Box>
            </Grid>
          ) : null}
          <Grid item xs="auto" sx={{ ml: 1 }}>
            <Typography component={"span"} variant="h6" color={"#787777"} fontWeight={400}>
              {application?.client_name}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6} container >
          <Grid item xs="auto" sx={{ mr: 1 }}>
            <Typography component={"span"} variant="h6" color={"#787777"} fontWeight={"600"}>
              Client Id
            </Typography>
          </Grid>
          {matches ? (
            <Grid item xs sx={{ position: "relative" }}>
              <Box
                component="span"
                sx={{
                  borderBottom: "1px dashed #787777",
                  display: "block",
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: 8,
                }}
              ></Box>
            </Grid>
          ) : null}
          <Grid item xs={"auto"} sx={{ ml: 1 }}>
            <Typography component={"span"} variant="h6" color={"#787777"} fontWeight={400}>
              {application?.client_id}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6} container alignItems="center">
          <Grid item xs={"auto"} sx={{ mr: 1 }}>
            <Typography component={"span"} variant="h6" color={"#787777"} fontWeight={"600"}>
              Username
            </Typography>
          </Grid>
          {matches ? (
            <Grid item xs sx={{ position: "relative" }}>
              <Box
                component="span"
                sx={{
                  borderBottom: "1px dashed #787777",
                  display: "block",
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: -2,
                }}
              ></Box>
            </Grid>
          ) : null}
          <Grid item xs  sm="auto" sx={{ ml: 1 }}>
            <Typography  variant="h6" color={"#787777"} fontWeight={400} style={{ wordWrap: "anywhere" }}>
              {application?.username}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6} container alignItems="center">
          <Grid item xs={"auto"} sx={{ mr: 1 }}>
            <Typography component={"span"} variant="h6" color={"#787777"} fontWeight={"600"}>
              Password
            </Typography>
          </Grid>
          {matches ? (
            <Grid item xs sx={{ position: "relative" }}>
              <Box
                component="span"
                sx={{
                  borderBottom: "1px dashed #787777",
                  display: "block",
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              ></Box>
            </Grid>
          ) : null}
          <Grid item xs  sm="auto" sx={{ ml: 1,display:'flex',alignItems:'center' }}>
            <Typography  variant="h6" color={"#787777"} fontWeight={400} style={{ wordWrap: "anywhere" }}>
              {
                showPassword ? application?.password : '****...'
              }
              <IconButton onClick={()=>{setShowPassword((prevState)=>{return !prevState})}}>
                 {
                   showPassword ? <VisibilityOff/> : <Visibility/>
                 }
              </IconButton>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent="space-between">
          <Grid item xs="auto" sx={{ mr: 1 }}>
            <Typography component={"span"} variant="h6" color={"#787777"} fontWeight={"600"}>
              Secret key
            </Typography>
          </Grid>
          {matches ? (
            <Grid item xs sx={{ position: "relative" }}>
              <Box
                component="span"
                sx={{
                  borderBottom: "1px dashed #787777",
                  display: "block",
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: 8,
                }}
              ></Box>
            </Grid>
          ) : null}
          <Grid item xs sm="auto" sx={{ ml: 1 }}>
            <Typography
              
              component={"span"}
              color={"#787777"}
              fontWeight={400}
              style={{ wordWrap: "anywhere" }}
            >
              {application.client_secret}
            </Typography>
          </Grid>
          
        </Grid>
        <Grid item xs={12} >
          <Grid container spacing={1} sx={{ p: 1, mt: 3,alignItems:'center',justifyContent:'space-between',flexDirection:{xs:'row',sm:'row-reverse'} }}>
              
          
              <Grid item xs={12} sm="auto">
          <Grid container alignItems='center' sx={{gap:1,justifyContent:'end'}}>  
          <Grid item xs={12} sm={'auto'}>
            <Button
              variant="outlined"
              component={"a"}
              fullWidth
              sx={{
                color: "#7897FE",
                cursor: "pointer", 
                textDecoration: "none", 
                border: "1px solid #7897FE",
                borderRadius:2,  
                maxWidth:'100%',
                padding: "0.8rem 1.4rem"}}

              target="_blank"
                href="https://app.metabypass.tech/docs.html"
            >
              
                Docs
             
            </Button>
          </Grid>
          <Grid item xs={12} sm={'auto'}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => {
                navigator.clipboard.writeText(
                  JSON.stringify({
                    grant_type: "password",
                    client_id: application.client_id,
                    client_secret: application.client_secret,
                    client_name: application.client_name,
                    username: application.username,
                    password: application.password,
                  })
                );
                snackCtx.handleOpen("Copied to clipboard", "");
              }}
              component={"span"}
              sx={{
                color: "#7897FE",
                border: "1px solid #7897FE",
                padding: "0.8rem 1.4rem",
                borderRadius: 2,
                maxWidth:'100%',
                cursor: "pointer",
                
              }}
            >
              Copy Json
            </Button>
          </Grid>
          </Grid>
          </Grid>
          <Grid item xs={12} sm={"auto"}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  color: "#FFFFFF",
                  background:'#F56666',
                  border: "1px solid #F56666",
                  padding: "0.8rem 1.4rem",
                  borderRadius: 2,
                  cursor: "pointer",
                  maxWidth:'100%',
                  '&:hover':{
                    background:'#F56666',
                    boxShadow:'none'
                  }
                }}
                onClick={onDeleteApplication}
              >
                Remove Application
              </Button>
          </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ApplicationForm;
