import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { BrowserRouter as Router } from "react-router-dom";
import { theme } from "../src/components/theme";
import { ThemeProvider } from "@mui/material/styles";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { responsiveFontSizes } from "@mui/material/styles";
import BannerProvider from "./store/BannerProvider";
ReactDOM.render(
  <React.StrictMode>
    <Router basename={process.env.PUBLIC_URL}>
      <ThemeProvider theme={responsiveFontSizes(theme, { factor: 10 })}>
        <GoogleOAuthProvider clientId="1097175000300-10jcvnkp318ceulamdpf74m931smlqt0.apps.googleusercontent.com">
          <BannerProvider>
          <App />
          </BannerProvider>
        </GoogleOAuthProvider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
