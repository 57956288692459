import { useState } from 'react';
import { Box, Divider,Grid ,Card,Typography,Tab,Tabs} from '@mui/material';
import Title from '../layout/Title';
import ImageSection from './ImageSection';
import LinkSection from './LinkSection';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 }}>
           {children}
          </Box>
        )}
      </div>
    );
  }

const HelpForm = (props)=>{
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
        props.onRadioButtonChange(newValue)
    };
    return(

        <Grid container>
            <Grid item xs={12} md={11}>
                <Card type="card">
                    <Title>
                        Help Us
                    </Title>
                    <Divider sx={{mb:3}}/>
                    <Grid container sx={{p:1}}>
                        <Grid item xs={12}> 
                            <Typography variant={"body1"} color="primary.text">
                                You can help us to improve our system with send a link or captcha picture that our system could not solve it.
                            </Typography>
                        </Grid>
                        <Grid  item xs={12}>
                            <Tabs value={value} onChange={handleChange} >
                                <Tab label="Image"
                                     sx={{
                                        fontWeight:'500',fontSize:'13px',
                                        '&.Mui-selected':{color:'primary.text'},
                                        
                                      }} 
                                />
                                <Tab label="Link" sx={{
                                       fontWeight:'500',
                                       '&.Mui-selected':{color:'primary.text'},
                                       
                                     }} />
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                <ImageSection 
                                    file={props.file} 
                                    fileUpload={props.fileUpload} 
                                    acceptedFiles={props.acceptedFiles} 
                                    toast={props.toast} 
                                    onButtonClick={props.onButtonClick}
                                    isLoading={props.isLoading}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <LinkSection 
                                    onButtonClick={props.onButtonClick} 
                                    onLinkChange={props.onLinkChange}
                                    linkValue={props.linkValue}
                                    isLoading={props.isLoading}
                                />
                            </TabPanel>
                        </Grid>
                        
                        
                    </Grid>
                </Card>
            </Grid>
        </Grid>

    )

}

export default HelpForm