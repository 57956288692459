import {
  Grid,
  Card,
  Typography,
  Divider,
  CircularProgress,
  Button,
  TextField,
} from "@mui/material";
import Title from "../../layout/Title";
import TicketButton from '../../dashboard/new-design/ui/ticket-button'

const FeedbackForm = ({
  submitLoading,
  onSubmit,
  onChangeMessage,
  message,
  customMessageList,
  customMessage,
  onSelectCutomMessage,
  payId
}) => {
  return (
    <Grid container>
      <Grid item xs={12} md={11}>
        <Card type="card" sx={{ p: "1.4rem" }}>
          <Grid container>
            <Grid item xs={12}>
              <Title>Payment Feedback</Title>
              <Divider sx={{ mb: 3 }} />
            </Grid>
            <Grid item xs="auto">
            <Typography component="span" sx={{mr:1}} variant="body1">Pay Id :</Typography>
            </Grid>
            <Grid item xs>
              
              <Typography component="span" variant="body1" style={{ wordWrap: "anywhere" }}>{payId}</Typography>
              <Divider sx={{ my: 3 }} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" color="#787777">
                Select Message
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{mt:1.3}}>
              <Grid container spacing={1}>
                {customMessageList.map((item, index) => {
                  
                    return (
                      <Grid key={index} item xs={"auto"}>
                        <TicketButton
                          text={item}
                          color={
                            item === customMessage ? "#FFFFFF" : "#0A1b31"
                          }
                          backgroundColor={
                            item === customMessage ? "#0A1b31" : "#FFFFFF"
                          }
                          borderColor="#0A1b31"
                          callBack={onSelectCutomMessage}
                        />
                      </Grid>
                    );
                })}
                <Grid item xs={'auto'}>
                <TicketButton 
                    text="Custom message" 
                    color={'Custom message' === customMessage ? '#FFFFFF' : "#0A1b31"} 
                    backgroundColor={'Custom message' === customMessage ? "#0A1b31" :"#FFFFFF"}
                    borderColor="#0A1b31"
                    callBack={onSelectCutomMessage}
                />
            </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <TextField
                multiline
                rows={4}
                sx={{
                  backgroundColor: "#FAFBFB",
                }}
                label={"Write message"}
                fullWidth
                value={message}
                onChange={onChangeMessage}
              />
            </Grid>
            <Grid item xs={12} sm="auto" sx={{ mt: 2 }}>
              {submitLoading ? (
                <CircularProgress />
              ) : (
                <Button
                  onClick={onSubmit}
                  variant="contained"
                  sx={{ minWidth: "120px", textTransform: "capitalize",maxWidth:'100%' }}
                  fullWidth
                >
                  Submit
                </Button>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default FeedbackForm;
