import { Button, Typography } from "@mui/material"
import Grid from "@mui/system/Unstable_Grid"
import CodeEditor from '@uiw/react-textarea-code-editor';

const RequestDetailJsonForm = ({json,onClick})=>{

    return(
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h6">
                    JSON
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{mt:2}}>
            <CodeEditor
                       
                data-color-mode="dark"
                disabled={true}
                value={json}
                language="js"
                
                // onChange={(evn) => setCode(evn.target.value)}
                padding={15}
                style={{
                    width:'100%',
                    height:'100%',
                    overflowY:'auto',
                    fontSize: 12,
                    backgroundColor: "#0A1b31",
                    color:'#DBB26C !important',
                    fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                    
                }}
            />
            </Grid>
            
            <Grid item xs={12} sm="auto" sx={{mt:2}}>
                <Button onClick={onClick} variant="contained" sx={{maxWidth:'100%'}} fullWidth>
                    Show Text 
                </Button>
            </Grid>
        </Grid>
    )

}

export default RequestDetailJsonForm