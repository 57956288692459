import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { noContent as noContentImg } from '../../extentions/imagesUrl';
import CirCularProgressComponent from '../layout/CirCularProgressComponent';

const LogTable = (props)=>{

    const createTableOrderRows = ()=>{
        return(
        <TableBody>
            {
                props.logs.map((log,idx)=>{
                    return(
                        <TableRow key={log.id} sx={{cursor:'pointer'}} >
                            <TableCell align="center">{idx + 1}</TableCell>
                            <TableCell align="center">{new Date(log?.created_at * 1000).toLocaleString() }</TableCell>
                            <TableCell align="center">{log?.inputs?.client_ip }</TableCell>
                            <TableCell align="center">{log?.inputs?.country}</TableCell>
                        </TableRow>
                    )
                })
            }
        </TableBody>)
    }
    const createLogTable = ()=>{

        if(props.logs !== undefined)
            return(
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Row</TableCell>
                                <TableCell align="center">Date</TableCell>
                                <TableCell align="center">Ip</TableCell>
                                <TableCell align="center">Country</TableCell>
                            </TableRow>
                        </TableHead>
                        {props.isLoading ?  null : createTableOrderRows()}
                    </Table>
                    {
                     props.isLoading 
                      ? 
                        <CirCularProgressComponent/>
                      : 
                        props.logs.length === 0 
                        ?
                            <div style={{display:'flex',justifyContent:'center'}}>
                                <img style={{height:'auto',width:'300px'}} src={noContentImg} alt={"captcha bypass"} />
                            </div>
                            :
                            null
                      }
                </TableContainer>
                    

            )

    }
    return(
        createLogTable()
    )

}

export default LogTable