import { useContext, forwardRef } from "react";

import SnackContext from "../../store/snack-context";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const SnackBarComponent = () => {
  const snackCtx = useContext(SnackContext);

  return (
    <Snackbar open={snackCtx.showSnack} autoHideDuration={3000} onClose={snackCtx.handleClose}>
      <Alert
        onClose={snackCtx.handleClose}
        icon={false}
        severity="info"
        sx={{ width: "100%", fontSize: "12px", display: "flex" }}
      >
        {snackCtx.snackMessage}
      </Alert>
    </Snackbar>
  );
};

export default SnackBarComponent;
