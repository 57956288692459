import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useHttp from "../../hook/useHttp";
import { profile as profileUrl } from "../../extentions/apiUrls";
import { userSetting as userSettingUrl, userUrl } from "../../extentions/apiUrls";
import SnackContext from "../../store/snack-context";
import ProfileForm from "./ProfileForm";
import { isEqual } from "lodash";
import { Box, CircularProgress } from "@mui/material";

const Profile = () => {
  const [userData, setUserData] = useState(undefined);
  const [userProfile, setuserProfile] = useState(undefined);
  const [change, setChange] = useState(false);
  const [setting, setSetting] = useState({ wallet: { lock: true } });
  const snackCtx = useContext(SnackContext);
  const { sendRequest: getProfileInformationRequest } = useHttp();
  const { sendRequest: updateSettingReqeust } = useHttp();
  const { isLoading, sendRequest: updateProfileRequest } = useHttp();
  const location = useLocation();

  const navigate = useNavigate();
  const validation = () => {
    const user = userData.user;

    if (user.legal == 1 && (user.company === "" || user.company === null)) {
      return false;
    }
    if (user.legal == 0) {
      if (user.first_name === null || user.first_name === "") {
        return false;
      }
      if (user.last_name === null || user.last_name === "") {
        return false;
      }
    }
    if (user.address === null || user.address === "") {
      return false;
    }
    if (user.country === null || user.country === "") {
      return false;
    }
    if (user.city === null || user.city === "") {
      return false;
    }
    if (user.postal_code === null || user.postal_code === "") {
      return false;
    }

    return true;
  };
  const profileUpdateHandler = (res) => {
    snackCtx.handleOpen(res.data.message);
    setChange(false);
    if (location.state !== null) {
      const route = location.state?.callBackurl || null;
      if (route) navigate(route, { replace: true });
    }
  };
  const saveFormHandler = () => {
    const isValid = validation();
    if (isValid) {
      const options = {
        method: "PUT",
        url: userUrl,
        data: { ...userData.user },
      };
      updateProfileRequest(options, profileUpdateHandler);
    } else {
      snackCtx.handleOpen("Please fill up form carefully!");
    }
  };
  const handleChange = (e) => {
    setUserData((prevState) => {
      setChange(!isEqual(userProfile, { ...prevState.user, [e.target.name]: e.target.value }));
      return {
        ...prevState,
        user: {
          ...prevState.user,
          [e.target.name]: e.target.value,
        },
      };
    });
  };

  const saveFormDataHandler = () => {
    saveFormHandler();
  };
  const handleSwitchChange = (event) => {
    setSetting((prevState) => {
      return {
        ...prevState,
        wallet: {
          ...prevState.wallet,
          lock: event.target.checked,
        },
      };
    });

    const handleWalletSwitchChange = (res) => {
      snackCtx.handleOpen(res.data.message, "");
    };
    let options = {
      method: "PUT",
      url: userSettingUrl,
      data: {
        new_options: JSON.stringify({ wallet: event.target.checked ? "lock" : "open" }),
      },
    };
    updateSettingReqeust(options, handleWalletSwitchChange);
  };
  useEffect(() => {
    const handleProfileInformation = (res) => {
      let jsonOptions = JSON.parse(res.data.data.settings.options);
      setSetting((prevState) => {
        return {
          ...prevState,
          wallet: {
            ...prevState.wallet,
            lock: jsonOptions.wallet === "lock" ? true : false,
          },
        };
      });

      let newUser = { ...res.data.data };
      const {
        id,
        email,
        created_at,
        updated_at,
        role,
        banned,
        google_id,
        email_verified,
        role_id,
        status,
        ...profile
      } = res.data.data.user;
      setuserProfile(profile);
      newUser.user = profile;
      setUserData(newUser);
    };

    let options = {
      method: "GET",
      url: profileUrl,
    };
    getProfileInformationRequest(options, handleProfileInformation);
  }, [getProfileInformationRequest]);

  return userData === undefined ? (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress />
    </Box>
  ) : (
    <ProfileForm
      data={userData}
      handleChange={handleChange}
      shouldUpdate={change}
      onSave={saveFormDataHandler}
      saveLoading={isLoading}
      settings={setting}
      onWalletChange={handleSwitchChange}
    />
  );
};

export default Profile;
