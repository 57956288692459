import {useState} from 'react';

import { Typography,TextField,Grid } from '@mui/material';
import ReCAPTCHA from "react-google-recaptcha";
import Button from '@mui/material/Button';

import CircularProgress from '@mui/material/CircularProgress';

const initialFormData={
    email:'',
    password:'',
    confirmPassword:''
}
const initialFormError={
    email:{
        error:false,
        helperText:""
    },
    password:{
        error:false,
        helperText:""
    },
    confirmPassword:{
        error:false,
        helperText:""
    }
}
const RegistrationForm = (props)=>{
    const [formData,setFormData] = useState(initialFormData);
    const [formError,setFormError] = useState(initialFormError);
    const [recaptcha,setRecaptcha] = useState(null)
    const [showRecaptcha,setShowRecaptcha] = useState(true)
    const recaptchaChangleHandler = (res)=>{
        setRecaptcha(res)
    }
    
    const hideRecaptchaHandler = ()=>{
        setShowRecaptcha(false)
        setTimeout(
            ()=>{setShowRecaptcha(true)},2000
        )
    }


    const emailChangeHandler = (event)=>{
        if(formError.email.error){ 
            setFormError(
                (state)=>{
                    return{
                        ...state,
                        email:{
                            error:false,
                            helperText:''
                        }
                    }
                }
            )
        }
        setFormData(
            (state)=>{
                return {
                    ...state,
                    email:event.target.value
                }
            }
        )
    }

    const passwordChangeHandler = (event)=>{
        if(formError.password.error){ 
            setFormError(
                (state)=>{
                    return{
                        ...state,
                        password:{
                            error:false,
                            helperText:''
                        }
                    }
                }
            )
        }
        setFormData(
            (state)=>{
                return {
                    ...state,
                    password:event.target.value
                }
            }
        )
    }
    
    const confirmPasswordChangeHandler = (event)=>{
        if(formError.confirmPassword.error){ 
            setFormError(
                (state)=>{
                    return{
                        ...state,
                        confirmPassword:{
                            error:false,
                            helperText:''
                        }
                    }
                }
            )
        }
        
        setFormData(
            (state)=>{
                return {
                    ...state,
                    confirmPassword:event.target.value
                }
            }
        )
    }

    const emailBlurHandler=(event)=>{
        if (!event.target.value.includes('@'))
        {
            setFormError(
                (state)=>{
                    return{
                        ...state,
                        email:{
                            error:true,
                            helperText:'email field invalid'
                        }
                    }
                }
            )

        }
    }

    const passwordBlurHandler = (event)=>{

        if(event.target.value === ""){
            setFormError(
                (state)=>{
                    return{
                        ...state,
                        password:{
                            error:true,
                            helperText:'Confirm password field invalid'
                        }
                    }
                }
            )

        }
    }
    
    const confirmPasswordBlurHandler = (event)=>{

        if(event.target.value === ""){
            setFormError(
                (state)=>{
                    return{
                        ...state,
                        confirmPassword:{
                            error:true,
                            helperText:'Confirm password field invalid'
                        }
                    }
                }
            )

        }

        if(event.target.value !== formData.password){
            setFormError(
                (state)=>{
                    return{
                        ...state,
                        confirmPassword:{
                            error:true,
                            helperText:'Confirm password field invalid'
                        }
                    }
                }
            )
        }
    }

    
    const registerClickHandler = (event)=>{
        hideRecaptchaHandler()
        event.preventDefault()

        if(!formError.email.error && !formError.password.error && !formError.confirmPassword.error ){
            if(formData.email !== "" && formData.confirmPassword !== "" && formData.password !== "") 

                props.registerHandler(formData.email,formData.password,formData.confirmPassword,recaptcha)
            else
            {
                setFormError(
                    (state)=>{
                        return{
                            email:{
                                error:true,
                                helperText:'email field invalid'
                            },
                            password:{
                                error:true,
                                helperText:'password field invalid'
                            },
                            confirmPassword:{
                                error:true,
                                helperText:'Confirm password field invalid'
                            }
                        }
                    }
                )

            }

        }
    }
    return(
        
        <Grid container spacing={3} sx={{padding:'4px 24px',mt:3}}>
            <Grid item xs={12} md={10} lg={9}>
                <TextField 
                        value={formData.email} 
                        id="email" 
                        label="Email" 
                        variant="outlined" 
                        type="email" 
                        size="small"
                        fullWidth
                        helperText={formError.email.helperText}
                        error={formError.email.error}
                        onChange={emailChangeHandler}
                        onBlur={emailBlurHandler}
                        sx={{
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#76323F"
                              },
                              "& .MuiInputLabel-outlined.Mui-focused": {
                                color: "#76323F"
                              }
                        }}
                    />
            </Grid>
            <Grid item xs={12} md={10} lg={9}>
                <TextField 
                        value={formData.password} 
                        id="standard-password-input" 
                        variant="outlined" 
                        label="Password" 
                        type="password" 
                        size="small"
                        fullWidth
                        helperText={formError.password.helperText}
                        error={formError.password.error}
                        onChange={passwordChangeHandler}
                        onBlur={passwordBlurHandler}
                        sx={{
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#76323F"
                              },
                              "& .MuiInputLabel-outlined.Mui-focused": {
                                color: "#76323F"
                              }
                        }}
                    />
            </Grid>
            <Grid item xs={12} md={10} lg={9}>
                <TextField 
                        value={formData.confirmPassword} 
                        id="standard-confirm-password-input" 
                        variant="outlined" 
                        label="Confirm Password" 
                        type="password" 
                        size="small"
                        fullWidth
                        helperText={formError.confirmPassword.helperText}
                        error={formError.confirmPassword.error}
                        onChange={confirmPasswordChangeHandler}
                        onBlur={confirmPasswordBlurHandler}
                        sx={{
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#76323F"
                              },
                              "& .MuiInputLabel-outlined.Mui-focused": {
                                color: "#76323F"
                              }
                        }}
                        
                    />
            </Grid>
            <Grid item xs={12} md={10} lg={9}>
                {
                    showRecaptcha
                     ?
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_CAPTCHA_SITEKEY}
                            onChange={recaptchaChangleHandler}
                        />
                     :
                        null

                }
                    
            </Grid>
            <Grid item xs={12} sm={7} md={6} lg={5}>
                    {props.loading ? 
                             <div style={{color: '#76323F'}}>
                                <CircularProgress color="inherit"/>
                             </div>
                        :


                           
                            <Button 
                            variant="contained"
                            onClick={registerClickHandler}
                            fullWidth
                            sx={{padding:'8px 8px',
                                background:"#76323F",
                                color:"white",
                                borderRadius:2,
                                fontSize:'17px',
                                boxShadow:0,
                                '&:hover':{
                                    background:"#561f2a",
                                    boxShadow:1
                                },
                                maxWidth:'100%'
                            }}
                        >
                            Register
                        </Button>
                    }
            </Grid>
            <Grid item xs={12} >
                    <Typography variant='body2' sx={{color:'landing.gray'}}>
                            Didnt receive activation mail yet? <span style={{textDecoration:'underline',cursor:'pointer'}} onClick={props.onResendEmailClick} href="">Resend mail</span>
                    </Typography>
            </Grid>
        </Grid>
           
                
            
             
              
        
    
    )
}

export default RegistrationForm;