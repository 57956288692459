import {
  Grid,
  Card,
  TextField,
  Divider,
  Typography,
  Box,
  MenuItem,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material";
import Title from "../../../layout/Title";
import { QRCodeSVG } from "qrcode.react";
import CopyIcon from "../../../icons/CopyIcon";
import { calculateBoost } from "../../../../utils/helper";
const CryptoPaymentForm = ({
  form,
  cryptoAmount,
  walletAddress,
  isCoin,
  cryptoList,
  onChange,
  onSubmit,
  loading,
  onRouteChange,
  fee,
  onCopy,
}) => {
  return (
    <Card type="card" sx={{ padding: "1.4rem" }}>
      <Grid container>
        <Grid item xs={12}>
          <Title>Add Funds</Title>
          <Divider sx={{ mb: 3 }} />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  {isCoin ? (
                    <TextField value={form.coin} label="coin" name="coin" fullWidth size="small" />
                  ) : (
                    <TextField
                      onChange={onChange}
                      value={form.coin}
                      label="coin"
                      name="coin"
                      select
                      fullWidth
                      size="small"
                    >
                      <MenuItem value={""}></MenuItem>
                      {Object.keys(cryptoList).map((key, index) => {
                        return (
                          <MenuItem key={index} value={key}>
                            {key}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={form.network}
                    onChange={onChange}
                    label="network"
                    name="network"
                    fullWidth
                    size="small"
                    select
                  >
                    <MenuItem value={""}></MenuItem>
                    {cryptoList[form.coin]?.networks.map((item, index) => {
                      return (
                        <MenuItem value={item} key={index}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                {walletAddress !== null && cryptoAmount !== null ? (
                  <>
                    <Grid item xs={12}>
                      <Grid container sx={{ borderColor: "gray", border: 1, borderRadius: 2 }}>
                        <Grid item xs={12} sm={3} sx={{ borderBottom: 1, borderRight: { xs: 0, sm: 1 } }}>
                          <Box
                            sx={{
                              backgroundColor: "#e5eaff",
                              width: "100%",
                              height: "100%",
                              borderTopLeftRadius: 8,
                              borderTopRightRadius: { xs: 8, sm: 0 },
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography>Wallet Address</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={9} sx={{ p: 2, borderBottom: 1 }}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography sx={{ wordBreak: "break-all", color: "#829ffe" }}>{walletAddress}</Typography>
                            <IconButton sx={{ ml: 2 }} onClick={() => onCopy(walletAddress)}>
                              <CopyIcon />
                            </IconButton>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          sx={{
                            borderRight: { xs: 0, sm: 1 },
                            borderBottom: { xs: 1, sm: 0 },
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: "#e5eaff",
                              width: "100%",
                              height: "100%",
                              borderBottomLeftRadius: { xs: 0, sm: 8 },
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography>Amount</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} sx={{ p: 2 }}>
                          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography>{cryptoAmount}</Typography>
                            {fee !== null ? (
                              <div style={{ display: "inline-block" }}>
                                <Typography
                                  component={"span"}
                                  fontWeight="bold"
                                  sx={{
                                    color: "white",
                                    textAlign: "center",
                                    p: 0.3,
                                    borderRadius: "4px",
                                    background: "#5BC17E",
                                    fontSize: "10px",
                                  }}
                                >
                                  {`Fee: ${fee}%`}
                                </Typography>
                              </div>
                            ) : null}
                          </Box>
                        </Grid>
                        {form?.boost > 0 && (
                          <>
                            <Grid
                              item
                              xs={12}
                              sm={3}
                              sx={{
                                borderTop: 1,
                                borderRight: { xs: 0, sm: 1 },
                                borderBottom: { xs: 1, sm: 0 },
                              }}
                            >
                              <Box
                                sx={{
                                  backgroundColor: "#e5eaff",
                                  width: "100%",
                                  height: "100%",
                                  borderBottomLeftRadius: { xs: 0, sm: 8 },
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography>Applied Balance</Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={9} sx={{ p: 2, borderTop: { xs: 0, sm: 1 } }}>
                              <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography sx={{ wordBreak: "break-all", color: "#707070" }}>
                                  {String((form.price * calculateBoost(form.boost)).toFixed(2)).replace('.00','')}$
                                </Typography>
                              </Box>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    {form?.boost > 0 && (
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          fontWeight={400}
                          sx={{ wordBreak: "break-all", background: "#ECFCF3", padding: 2, borderRadius: 2 }}
                        >
                          With paying
                          <span style={{ color: "#5BC17E", marginLeft: 4, marginRight: 4, fontWeight: "bolder" }}>
                            {form.price}$
                          </span>
                          by <span style={{textTransform:'capitalize'}}>{form.name}</span>,your wallet will be charged
                          <span style={{ color: "#5BC17E", marginLeft: 4, marginRight: 4, fontWeight: "bolder" }}>
                            {String((form.price * calculateBoost(form.boost)).toFixed(2)).replace('.00','')}$
                          </span>
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography sx={{ wordBreak: "break-all" }}>
                        Your transaction added to payment list after confirmed in selected blockchain network
                      </Typography>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  my: { xs: 3, md: 0 },
                }}
              >
                <Box
                  sx={{
                    borderRadius: 2,
                    display: walletAddress !== null && cryptoAmount !== null ? "block" : "none",
                  }}
                >
                  <QRCodeSVG value={walletAddress} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Grid container justifyContent={"space-between"} spacing={2}>
            <Grid item xs={12} sm="auto">
              {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              ) : (
                walletAddress !== null ||
                (walletAddress !== "" && (
                  <Button onClick={onSubmit} variant="contained" fullWidth sx={{ maxWidth: "100%" }}>
                    Caculated Fund
                  </Button>
                ))
              )}
            </Grid>
            <Grid item xs={12} sm="auto">
              <Button onClick={onRouteChange} variant="outlined" fullWidth sx={{ maxWidth: "100%" }}>
                Payment List
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CryptoPaymentForm;
