import {useState} from 'react'
import DashboardContext from './dashboard-context'

const DashboardProvider = (props)=>{

    const [unread,setUnread]=useState(0)
    const [error,setError] = useState({
        showMessage  : false,
        messageType  : "error",
        messageText  : "The server is offline due to some issues.",
    })

    const setUnreadTicket = (count)=>{
        setUnread(count)
    }

    const setServerError = (value)=>{
        
        setError((prevState)=>{
                return{
                    ...prevState,
                    showMessage:value
                }
        })

    }
    const contextValue = {
        
        unreadTicket:unread,
        error,
        setUnreadTicket:setUnreadTicket,
        setServerError:setServerError


    }
    return(
        <DashboardContext.Provider value={contextValue}>
            {props.children}
        </DashboardContext.Provider>
    )
}

export default DashboardProvider