import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Card, Divider, Box, CircularProgress } from "@mui/material";
import CreateTicketForm from "./CreateTicketForm";
import useHttp from "../../hook/useHttp";
import { ticketOptions as ticketOptionsUrl, createTicket as createTicketUrl } from "../../extentions/apiUrls";
import snackContext from "../../store/snack-context";
import Title from "../layout/Title";

const initialErrorState = {
  department: { error: false, text: "Department is required" },
  message: { error: false, text: "Message body is required" },
  category: { error: false, text: "Category is required" },
  priority: { error: false, text: "Priority is required" },
  subject: { error: false, text: "Subject is required" },
};
const CreateTicket = () => {
  const navigate = useNavigate();
  const snackCtx = useContext(snackContext);
  const [formError, setFormError] = useState(initialErrorState);
  const { sendRequest: getTicketOptionsRequest } = useHttp();
  const { isLoading: sendLoading, sendRequest: sendTicketRequest } = useHttp();
  const [openPriority, setOpenPriority] = useState(false);
  const [priority, setPriority] = useState(0);
  const [priorities, setPriorities] = useState([]);
  const [openDepartment, setOpenDepartment] = useState(false);
  const [department, setDepartment] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(0);
  const [openCategory, setOpenCategory] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(undefined);
  const [encodedFile, setEncodedFile] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const getBase64 = (file, callBack) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (upload) {
      // let result = upload.target.result.split(',')
      let base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
      callBack(base64String);
    };
    reader.onerror = function (error) {};
  };

  const getBase64Result = (res) => {
    setEncodedFile(res);
  };

  const handleFileChange = (e) => {
    try {
      let file = e?.target?.files[0];
      if (file) {
        setFile(file);
        getBase64(file, getBase64Result);
      } else {
        setFile(undefined);
        setEncodedFile(undefined);
      }
    } catch {
      setFile(undefined);
      setEncodedFile(undefined);
    }
  };
  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    setFormError((prevState) => {
      return {
        ...prevState,
        message: {
          ...formError.message,
          error: false,
        },
      };
    });
  };
  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
    setFormError((prevState) => {
      return {
        ...prevState,
        subject: {
          ...formError.subject,
          error: false,
        },
      };
    });
  };
  const handlePriorityOpen = () => {
    setOpenPriority(true);
  };
  const handlePriorityClose = () => {
    setOpenPriority(false);
  };
  const handlePriorityChange = (e) => {
    setPriority(e.target.value);
    setFormError((prevState) => {
      return {
        ...prevState,
        priority: {
          ...formError.priority,
          error: false,
        },
      };
    });
  };
  const handleDepartmentOpen = () => {
    setOpenDepartment(true);
  };
  const handleDepartmentClose = () => {
    setOpenDepartment(false);
  };
  const handleDepartmentChange = (e) => {
    setDepartment(e.target.value);
    setFormError((prevState) => {
      return {
        ...prevState,
        department: {
          ...formError.department,
          error: false,
        },
      };
    });

    let tempCategories = departments.map((item) => {
      
      if (item.operator_name === e.target.value) {
        return item.categories;
      }
      else
        return undefined
    });
    
    tempCategories = tempCategories.filter((element) => {
      return element !== undefined;
    });

    setCategories(...tempCategories);
  };

  const handleCategoryOpen = () => {
    setOpenCategory(true);
  };
  const handleCategoryClose = () => {
    setOpenCategory(false);
  };
  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
    setFormError((prevState) => {
      return {
        ...prevState,
        category: {
          ...formError.category,
          error: false,
        },
      };
    });
  };

  const sendTicketHandler = (res) => {
    if (res.data.status_code === 200) {
      snackCtx.handleOpen(res.data.message);
      navigate("/support/tickets/ticket-list");
    } else {
      snackCtx.handleOpen(res.data.message);
    }
  };

  const handleSendTicket = () => {
    let error = false;
    if (category === 0) {
      error = true;
      setFormError((prevState) => {
        return {
          ...prevState,
          category: {
            ...formError.category,
            error: true,
          },
        };
      });
    }
    if (subject === "") {
      error = true;
      setFormError((prevState) => {
        return {
          ...prevState,
          subject: {
            ...formError.subject,
            error: true,
          },
        };
      });
    }
    if (department === 0) {
      error = true;
      setFormError((prevState) => {
        return {
          ...prevState,
          department: {
            ...formError.department,
            error: true,
          },
        };
      });
    }
    if (priority === 0) {
      error = true;
      setFormError((prevState) => {
        return {
          ...prevState,
          priority: {
            ...formError.priority,
            error: true,
          },
        };
      });
    }
    if (message === "") {
      error = true;
      setFormError((prevState) => {
        return {
          ...prevState,
          message: {
            ...formError.message,
            error: true,
          },
        };
      });
    }

    if (!error) {
      let data = {
        title: subject,
        section: department,
        priority: priority,
        category: category,
        message: message,
        file: encodedFile,
      };

      let options = {
        url: createTicketUrl,
        method: "POST",
        data: data,
      };

      sendTicketRequest(options, sendTicketHandler);
    }
  };

  const handleGetTicketOptions = (res) => {
    if (res.data.status_code === 200) {
      // setCategories(res.data.data.category_options)
      setDepartments(res.data.data.section_parts);
      setPriorities(res.data.data.priority_parts);
      setLoading(false);
    }
  };

  useEffect(() => {
    let options = {
      method: "GET",
      url: ticketOptionsUrl,
    };
    getTicketOptionsRequest(options, handleGetTicketOptions);
  }, [getTicketOptionsRequest]);
  return (
    <Grid container>
      <Grid item xs={12} md={11}>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Card type="card">
            <Title>Send Ticket</Title>
            <Divider sx={{ mb: 2 }} />
            <CreateTicketForm
              priority={priority}
              handlePriorityOpen={handlePriorityOpen}
              handlePriorityClose={handlePriorityClose}
              handlePriorityChange={handlePriorityChange}
              openPriority={openPriority}
              department={department}
              handleDepartmentOpen={handleDepartmentOpen}
              handleDepartmentClose={handleDepartmentClose}
              handleDepartmentChange={handleDepartmentChange}
              openDepartment={openDepartment}
              category={category}
              handleCategoryOpen={handleCategoryOpen}
              handleCategoryClose={handleCategoryClose}
              handleCategoryChange={handleCategoryChange}
              openCategory={openCategory}
              subject={subject}
              handleSubjectChange={handleSubjectChange}
              message={message}
              handleMessageChange={handleMessageChange}
              sendTicketLoading={sendLoading}
              sendTicket={handleSendTicket}
              fileUpload={handleFileChange}
              file={file}
              departments={departments}
              categories={categories}
              priorities={priorities}
              formError={formError}
            />
          </Card>
        )}
      </Grid>
    </Grid>
  );
};

export default CreateTicket;
