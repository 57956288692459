import { createContext } from "react";

const DashboardContext = createContext({

    unreadTicket : 0,
    error:{
        showMessage  : false,
        messageType  : "error",
        messageText  : "The server is offline due to some issues.",
    },
    
    setUnreadTicket:()=>{},
    setServerError:()=>{},
    
})

export default DashboardContext