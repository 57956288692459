import { useState } from 'react';
import {Grid,Card,TextField,Button,CircularProgress,Box,Typography} from '@mui/material';
import ReCAPTCHA from "react-google-recaptcha";

const PasswordResetForm = (props)=>{
    const [showRecaptcha,setShowRecaptcha] = useState(true)

    const hideRecaptchaHandler = ()=>{
        setShowRecaptcha(false)
        setTimeout(
            ()=>{setShowRecaptcha(true)},2000
        )
    }
    const resetPasswordHandler = ()=>{
        hideRecaptchaHandler()
        props.onResetPassword()
    }
    return(
        <Grid container sx={{marginTop:'128px',p:1}} justifyContent="center" >
            <Grid item xs={12} md={7} xl={4}>
                <Card sx={{p:2}}>
                        <Grid container sx={{p:2}} >
                            <Grid item xs={12}>
                                <Typography component={'div'} variant={'h5'} sx={{textAlign:'center'}}>
                                    Reset Password 
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container sx={{p:2}} >
                            <Grid item xs={12} md={6}>
                                <TextField label="Password" type={'password'} variant='standard' value={props.password} onChange={props.onPasswordChange} fullWidth/>
                            </Grid>
                        </Grid>
                        <Grid container sx={{p:2}} >
                            <Grid item xs={12} md={6}>
                                <TextField label="Confirm Password" type={'password'} variant='standard' value={props.confirmPassword} onChange={props.onConfirmPasswordChange} fullWidth/>
                            </Grid>
                        </Grid>
                        <Grid container sx={{p:2}} >
                            <Grid item xs={12} md={6}>
                                {
                                    showRecaptcha
                                        ?
                                            <ReCAPTCHA
                                                sitekey={process.env.REACT_APP_CAPTCHA_SITEKEY}
                                                onChange={props.recaptchaChangleHandler}
                                            />
                                        :
                                            null

                                }
                                
                            </Grid>
                        </Grid>
                        
                        <Grid container sx={{p:2}} justifyContent="center">
                            <Grid item xs={12} md={4} >
                                <Box sx={{display:'flex',justifyContent:"center"}}>
                                    {
                                        props.isLoading
                                            ?
                                                <CircularProgress/>
                                            :
                                                <Button variant='contained' onClick={resetPasswordHandler} fullWidth>
                                                    Reset Password
                                                </Button>
                                    }
                                </Box>
                            </Grid>     
                        </Grid>
                </Card>
            </Grid>
        </Grid>
    )

}

export default PasswordResetForm