import DashboardComponent from "../components/DashboardComponent";
import TestService from "../components/testService/TestService";
import UserOrder from "../components/orders/UserOrder";
import Profile from "../components/profile/Profile";
import AuthContainer from "../components/auth/AuthContainer";
import PasswordReset from "../components/auth/PasswordReset";
import DashboardFirstPage from "../components/dashboard/DashboardFirstPage";
import PageNotFound from "../components/PageNotFound";
import Ticket from "../components/ticketing/Ticket";
import CreateTicket from "../components/ticketing/CreateTicket";
import TicketDetail from "../components/ticketing/TicketDetail";
import TicketList from "../components/ticketing/TicketList";
import Help from "../components/help/Help";

import Feedback from "../components/feedback";
import SuccessfulPayment from "../components/successful-payment";
import { default as UserPlanList } from "../components/plan/PlanList";
import CryptoPayment from '../components/plan/CryptoPayment'

import History from "../components/history/History";
import TransactionList from "../components/transaction/TransactionList";
import PaymentsIcon from "@mui/icons-material/Payments";
import Log from "../components/logs/Log";
import Invoice from "../components/transaction/Invoice";
import DashboardIcon from "../components/icons/DashboardIcon";
import RedirectPage from "../components/redirect/RedirectPage";
import Purchase from "../components/purchase/Purchase";
import ProfileIcon from "../components/icons/ProfileIcon";
import TestServiceIcon from "../components/icons/TestServiceIcon";
import PlanListIcon from "../components/icons/PlanListIcon";
import PaymentHistoryIcon from "../components/icons/PaymentHistory";
import UsageHistoryIcon from "../components/icons/UsageHistoryIcon";
import HelpUsIcon from "../components/icons/HelpUsIcon";
import TicketIcon from "../components/icons/TicketIcon";
import Auth from "../pages/Auth";
import ApiDocIcon from "../components/icons/ApiDocIcon";
const Routes = [
  {
    path: "*",
    title: "Home",
    component: PageNotFound,
    isPrivate: false,
    isExact: true,
    showInMenu: false,
    permissions: [],
  },
  {
    path: "/login",

    title: "Auth",
    component: Auth,
    isPrivate: false,
    isExact: true,
    showInMenu: false,
    permissions: [],
  },
  {
    path: "/redirect-page",

    title: "Redirect",
    component: RedirectPage,
    isPrivate: false,
    isExact: true,
    showInMenu: false,
    permissions: [],
  },
  {
    path: "/auth",

    title: "Auth",
    component: AuthContainer,
    isPrivate: false,
    isExact: true,
    showInMenu: false,
    permissions: [],
    children: [
      {
        path: "/auth/password-reset",
        title: "Password Reset",
        component: PasswordReset,
        isPrivate: false,
        isExact: true,
        showInMenu: false,
        permissions: [],
      },
    ],
  },

  {
    path: "/",

    title: "Dashboard",
    component: DashboardComponent,
    isPrivate: true,
    isExact: true,
    showInMenu: false,
    permissions: [],
    children: [
      {
        path: "/",
        title: "Dashboard",
        isPrivate: true,
        isExact: true,
        showInMenu: true,
        permissions: [],
        icon: DashboardIcon,
        component: DashboardFirstPage,
      },
      {
        path: "/add-funds",
        title: "Add funds",
        isPrivate: true,
        isExact: true,
        showInMenu: true,
        icon: PlanListIcon,
        permissions: [],
        component: UserPlanList,
      },
      {
        path: "/add-funds/crypto",
        title: "Crypto",
        isPrivate: true,
        isExact: true,
        showInMenu: false,
        icon: PlanListIcon,
        permissions: [],
        component: CryptoPayment,
      },
      {
        path: "/place-order",
        title: "dashboard",
        isPrivate: true,
        isExact: true,
        showInMenu: false,
        permissions: [],
        icon: DashboardIcon,
        component: Purchase,
      },

      {
        path: "/application",
        title: "Application",
        isPrivate: true,
        isExact: true,
        showInMenu: true,
        permissions: [],
        icon: TestServiceIcon,
        component: TestService,
      },
      {
        path: "/payment-feedback",
        title: "Feedback",
        isPrivate: true,
        isExact: true,
        showInMenu: false,
        permissions: [],
        icon: TestServiceIcon,
        component: Feedback,
      },
      {
        path: "/payment-success",
        title: "Payment Successful",
        isPrivate: true,
        isExact: true,
        showInMenu: false,
        permissions: [],
        icon: TestServiceIcon,
        component: SuccessfulPayment,
      },
      {
        path: "/history",
        title: "History",
        isPrivate: true,
        isExact: true,
        showInMenu: true,
        icon: PlanListIcon,
        permissions: [],
        children: [
          {
            path: "/history/orders",
            url: "history/orders",
            title: "Order History",
            isPrivate: true,
            isExact: true,
            showInMenu: true,
            icon: PlanListIcon,
            permissions: [],
            component: UserOrder,
          },
          {
            path: "/history/payment-history",
            url: "history/payment-history",
            title: "Payments History",
            isPrivate: false,
            isExact: true,
            showInMenu: true,
            icon: PaymentHistoryIcon,
            permissions: [],
            component: TransactionList,
          },
          {
            path: "/history/payment-history/:id",
            url: "history/payment-history/:id",
            title: "Payments History",
            isPrivate: false,
            isExact: true,
            showInMenu: false,
            icon: PaymentsIcon,
            permissions: [],
            component: Invoice,
          },
          {
            path: "/history/login-history",
            url: "history/login-history",
            title: "Login History",
            isPrivate: true,
            isExact: true,
            showInMenu: true,
            icon: ProfileIcon,
            permissions: [],
            component: Log,
          },
          {
            path: "/history/service-usage-history",
            url: "history/service-usage-history",
            title: "Usage History",
            isPrivate: true,
            isExact: true,
            showInMenu: true,
            icon: UsageHistoryIcon,
            permissions: [],
            component: History,
          },
        ],
      },
      {
        path: "/profile",
        title: "Profile",
        isPrivate: true,
        isExact: true,
        showInMenu: true,
        permissions: [],
        icon: ProfileIcon,
        component: Profile,
      },

      {
        path: "/support",
        title: "Support",
        isPrivate: true,
        isExact: true,
        showInMenu: true,
        icon: PlanListIcon,
        permissions: [],
        children: [
          {
            path: "/support/help-us",
            title: "Help Us",
            isPrivate: true,
            isExact: true,
            showInMenu: true,
            icon: HelpUsIcon,
            permissions: [],
            component: Help,
          },
          {
            path: "/support/tickets",
            title: "Tickets",
            isPrivate: true,
            isExact: true,
            showInMenu: true,
            permissions: [],
            component: Ticket,
            icon: TicketIcon,
            children: [
              {
                path: "/support/tickets/create-ticket",
                title: "Create Ticket",
                isPrivate: true,
                isExact: true,
                showInMenu: true,
                permissions: [],
                component: CreateTicket,
              },
              {
                path: "/support/tickets/ticket-list",
                title: "Ticket List",
                isPrivate: true,
                isExact: true,
                showInMenu: true,
                permissions: [],
                component: TicketList,
              },
              {
                path: "/support/tickets/ticket-detail/:id",
                title: "Ticket Detail",
                isPrivate: true,
                isExact: true,
                showInMenu: false,
                permissions: [],
                component: TicketDetail,
              },
            ],
          },
          {
            path: "https://app.metabypass.tech/docs.html",
            title: "Api Doc",
            target: true,
            isPrivate: true,
            isExact: true,
            showInMenu: true,
            icon: ApiDocIcon,
            permissions: [],
            component: Help,
          },
        ],
      },
    ],
  },
];

export default Routes;
