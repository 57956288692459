import { Box, Grid, Card, Typography, Divider, CircularProgress } from "@mui/material";
import ApplicationForm from "./application-section";
import BalanceSection from "./balance-section";
import StatisticsForm from "./Statistics-section";
import UserForm from "./user-section";
import FilterDate from "../layout/FilterDate";
import TicketSection from "./ticket-section";
import CreateApplicationForm from "../../application/CreateApplicationForm";

function Dashboard({
  data,
  application,
  applicationLoading,
  ticketSection,
  removeApplicationLoading,
  removeApplication,
  onCreateApplication,
  createLoading,
}) {
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={7} lg={4}>
        <BalanceSection balance={data.funds.total_remaining_money} />
      </Grid>
      <Grid item xs={12} md={11} lg={7} sx={{ mt: { xs: 5, lg: 0 } }}>
        <StatisticsForm inquiry={data.inquiries} />
      </Grid>
      <Grid item xs={12} md={11} sx={{ mt: 8 }}>
        {applicationLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50px" }}>
            <CircularProgress />
          </Box>
        ) : application && Object.keys(application).length === 0 ? (
          <CreateApplicationForm loading={createLoading} onCreateAppllication={onCreateApplication} />
        ) : (
          <ApplicationForm application={application} onDelete={removeApplication} loading={removeApplicationLoading} />
        )}
      </Grid>
      <Grid item xs={12} md={11} lg={6} sx={{ mt: 5 }}>
        <TicketSection ticketOption={ticketSection} />
      </Grid>
      <Grid item xs={12} md={11} lg={5} sx={{ mt: 5 }}>
        <UserForm user={data.user} />
      </Grid>
      <Grid item xs={12} md={11} sx={{ mt: 7 }}>
        <Card type="card">
          <Typography color="#202020" variant={"h2"} fontWeight={600}>
            Requests Stats
          </Typography>
          <Divider sx={{ my: 3 }} />
          <FilterDate />
        </Card>
      </Grid>
    </Grid>
  );
}

export default Dashboard;
