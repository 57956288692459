

function FileUploadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.836"
      height="13.91"
      viewBox="0 0 13.836 16.91"
    >
      <path
        fill="#787777"
        d="M10.984 1.06a.771.771 0 01.293.216l5.283 5.283a.771.771 0 01.217.293.687.687 0 01.059.288v7.695a3.075 3.075 0 01-3.075 3.075H6.075A3.075 3.075 0 013 14.836V4.075A3.075 3.075 0 016.075 1H10.7a.689.689 0 01.284.06zm.471 2.56v1.992a.769.769 0 00.769.769h1.992zm-5.38-1.083a1.537 1.537 0 00-1.538 1.538v10.761a1.537 1.537 0 001.537 1.537h7.686a1.537 1.537 0 001.54-1.537V7.918h-3.076a2.306 2.306 0 01-2.306-2.306V2.537z"
        transform="translate(-3 -1)"
      ></path>
    </svg>
  );
}

export default FileUploadIcon;
