import { Grid, Typography,Card,Button, Divider,Box } from "@mui/material"

import PurchaseIcon from '../icons/PurchaseIcon'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Title from '../layout/Title'
const PurchaseForm = (props)=>{

    return(
        <Grid container >
            <Grid item xs={12} md={11}>
                <Card type="card">
                    <Title>
                        Order Detail
                    </Title>
                    <Divider sx={{mb:3}}/>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                           
                                <Typography component={'span'} variant="h6" sx={{fontWeight:'500',}}>
                                    Name:
                                </Typography>
                                <Typography component={'span'} variant="h6" sx={{fontWeight:'100',ml:1}}>
                                    {props.name}
                                </Typography>
                         
                          
                        </Grid>
                        
                        <Grid item xs={12} sm={6} md={4}>
                            
                                <Typography component={'span'} variant="h6" sx={{fontWeight:'500',}}>  
                                  Life Day:
                                </Typography>
                                <Typography component={'span'} variant="h6" sx={{fontWeight:'100',ml:1}}>
                                    {props.life_day}
                                </Typography>
                          
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>   
                                
                                <Typography component={'span'} variant="h6" sx={{fontWeight:'500',}}>
                                    Request Per Second:
                                </Typography>
                                <Typography component={'span'} variant="h6" sx={{fontWeight:'100',ml:1}}>
                                    {props.req_per_sec}
                                </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{my:2}}/>
                        </Grid>
                        <Grid item xs={12} sx={{p:1}}>
                            <FormControlLabel
                                label={<span>I have read and agree to the <span onClick={props.onClickTerms} style={{textDecoration:'underline',color:'blueGrey',cursor:'pointer'}}>Terms of Service</span></span>}
                                control={
                                    <Checkbox
                                    checked={props.agreement}
                                    onChange={props.handleChange}
                                    inputProps={{ 'aria-label': 'agreement' }}
                                    
                                />
                                }/>
                          

                           
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container sx={{alignItems:"center"}} spacing={1}>
                                <Grid item xs={2} sm={2} md={2} lg={2} >
                                    <Typography variant="h1" color={'#6CDBB2'} sx={{fontWeight:'500'}}>
                                        {props.price}$
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} sm={4} md={4} lg={3} xl={2} >
                                    <Box sx={{display:'flex',justifyContent:{xs:'center',sm:"start"}}}> 
                                        <Button 
                                            variant='buy' 
                                            startIcon={<PurchaseIcon/>}
                                            fullWidth
                                            component={'a'}
                                            sx={{color:'white !important'}}
                                            href={props.url}
                                            target="_blank"
                                            disabled={!props.agreement}
                                        >   
                                            Purchase
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3} >
                                    <Box sx={{display:'flex',justifyContent:{xs:'center',sm:"start"}}}> 
                                        <Button 
                                            variant="outlined" 
                                            sx={{
                                                borderColor:"red",
                                                '&:hover':{
                                                    background:'red',
                                                    color:'white',
                                                    borderColor:'red'
                                                }
                                            }} 
                                            onClick={props.onCancel}

                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                    
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                    
                </Card>
            </Grid>
        </Grid>
       
    )

}

export default PurchaseForm