import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TestServiceForm from "./TestServiceForm";
import RecaptchaTestFormContainer from "./RecaptchaTestFormContainer";
import {
  captcha1,
  captcha2,
  captcha3,
  captcha4,
  captcha5,
  captcha6,
  captcha7,
  captcha8,
  captcha9,
  captcha10,
  captcha11,
  captcha12,
  captcha13,
  captcha14,
} from "../../../extentions/imagesUrl";
import { Divider } from "@mui/material";
import Title from "../../layout/Title";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default function CenteredTabs(props) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card type="card">
          <Title>Test Service</Title>
          <Divider sx={{ mb: 3 }} />
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Captcha" sx={{ textTransform: "none" }} />
            <Tab label="reCaptcha" sx={{ textTransform: "none" }} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <TestServiceForm
              fileName={props.fileName}
              handleShowResult={props.handleShowResult}
              handleUploadPicture={props.handleUploadPicture}
              file={props.file}
              fileUpload={props.fileUpload}
              result={props.result}
              isLoading={props.isLoading}
              readFile={props.readFile}
              startAnimation={props.startAnimation}
              images={[
                captcha1,
                captcha2,
                captcha3,
                captcha4,
                captcha5,
                captcha6,
                captcha7,
                captcha8,
                captcha9,
                captcha10,
                captcha11,
                captcha12,
                captcha13,
                captcha14,
              ]}
              onImageClick={props.onImageClick}
              codeLoading={props.codeLoading}
              codeSample={props?.codeSample?.captcha}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <RecaptchaTestFormContainer
              token={props.token}
              onRecaptchFormChange={props.onRecaptchFormChange}
              recaptchData={props.recaptchData}
              onGetTokenClick={props.onGetTokenClick}
              tokenRadioButton={props.tokenRadioButton}
              onTokenRadioButtonChange={props.onTokenRadioButtonChange}
              onTokenChange={props.onTokenChange}
              isLoading={props.isLoading}
              radioButtonDisable={props.radioButtonDisable}
              onCopyClientSecret={props.onCopyClientSecret}
              onSendToken={props.onSendToken}
              recaptchaResponse={props.recaptchaResponse}
              protocolRadioButton={props.protocolRadioButton}
              onProtocolRadioButtonChange={props.onProtocolRadioButtonChange}
              codeLoading={props.codeLoading}
              codeSample={props?.codeSample}
            />
          </TabPanel>
        </Card>
      </Grid>
    </Grid>
  );
}
