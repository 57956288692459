import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { CircularProgress, Divider } from "@mui/material";
import Title from "../layout/Title";
const CreateApplicationForm = (props) => {
  return (
    <Box sx={{ display: "grid" }}>
      <Card type="card">
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Title>Create Application</Title>
            <Divider sx={{ mb: 3 }} />
            <Typography component={"div"} variant={"body1"} sx={{ p: 2, mt: 2 }}>
              You can create application which give you client id and client secret for use in your sevices.
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ p: 2 }}>
          <Grid item xs={12} sm={7} md={4} lg={3} xl={2}>
            {props.loading ? (
              <CircularProgress />
            ) : (
              <Button
                onClick={() => {
                  props.onCreateAppllication();
                }}
                fullWidth
                variant={"contained"}
              >
                Create Application
              </Button>
            )}
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default CreateApplicationForm;
