

function AlertIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18.5 21.503"
    >
      <g transform="translate(.75 .75)">
        <path
          fill="none"
          fillRule="evenodd"
          stroke="#6c86db"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M0 11.787v-.219A3.6 3.6 0 01.6 9.75a4.87 4.87 0 001.2-2.314c0-.666 0-1.342.058-2.009C2.155 2.218 5.327 0 8.461 0h.078c3.134 0 6.306 2.218 6.617 5.427.058.666 0 1.342.049 2.009A4.955 4.955 0 0016.4 9.759a3.507 3.507 0 01.6 1.809v.21a3.566 3.566 0 01-.844 2.39 4.5 4.5 0 01-2.856 1.37 45.074 45.074 0 01-9.615 0 4.554 4.554 0 01-2.85-1.371A3.6 3.6 0 010 11.787zm6.054 7.065a3.086 3.086 0 004.288.5 2.889 2.889 0 00.524-.5"
          data-name="Path 55"
        ></path>
      </g>
    </svg>
  );
}

export default AlertIcon;
