import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, CircularProgress, Grid } from "@mui/material";
import { currencyUrl, walletAddressUrl, currencyExchangeUrl, BASE_URL_ } from "../../extentions/apiUrls";
import useHttp from "../../hook/useHttp";
import CryptoPaymentForm from "./form/crypto";
import SnackContext from "../../store/snack-context";
const CryptoPayment = () => {
  const snackCtx = useContext(SnackContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [cryptoList, setCryptoList] = useState([]);
  const [coin, setCoin] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [cryptoAmount, setCryptoAmount] = useState(null);
  const [planPrice, setPlanPrice] = useState(location.state?.price);
  const [form, setForm] = useState({ coin: "", network: "" });
  const { isLoading: exchangeInfoLoading, sendRequest: exchangeInfoRequest } = useHttp();
  const { isLoading: checkoutLoading, sendRequest: checkoutRequest } = useHttp();
  const { isLoading: listLoading, sendRequest: cryptoListRequest } = useHttp();

  const checkoutRequestHandler = (res) => {
    setWalletAddress(res.data.data.address);
  };
  const cryptoListRequestHandler = (res) => {
    setCryptoList(res.data.data);
  };

  useEffect(() => {
    if (location.state === null) {
      navigate("/add-funds");
    } else if (location.state.paymentMethod.attrs.is_coin) {
      setForm({
        coin: location.state.paymentMethod.attrs.caption,
        network: location.state.paymentMethod.attrs.default_network,
        boost: location.state.paymentMethod.attrs.boost,
        price: location.state.price,
        name:location.state.paymentMethod.name
      });
      const options = {
        method: "GET",
        url: BASE_URL_ + location.state.paymentMethod.attrs.url,
        params: {
          currency: location.state.paymentMethod.attrs.caption,
          network: location.state.paymentMethod.attrs.default_network,
        },
      };
      checkoutRequest(options, checkoutRequestHandler);
      getExchangeInfo(location.state.paymentMethod.attrs.caption);
      const option = {
        method: "GET",
        url: currencyUrl,
      };
      cryptoListRequest(option, cryptoListRequestHandler);
    } else {
      const options = {
        method: "GET",
        url: currencyUrl,
      };
      cryptoListRequest(options, cryptoListRequestHandler);
    }
  }, []);

  const getExchangeInfoHandler = (res) => {
    const rateList = res.data.data.result;
    const rate = rateList.find((item) => {
      return item.to === "USDT";
    });
    let price = planPrice / rate.course;
    price = (Math.round(price * 1000000) / 1000000).toFixed(6);
    setCryptoAmount(price);
  };
  const getExchangeInfo = (coin) => {
    const options = {
      method: "get",
      url: currencyExchangeUrl,
      params: { currency: coin },
    };
    exchangeInfoRequest(options, getExchangeInfoHandler);
  };

  const changeFormHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setForm((prevState) => {
      return {
        ...prevState,
        [name]: value,
        network: name === "coin" ? "" : value,
      };
    });
    if (name === "coin" || name === "network") {
      setWalletAddress(null);
    }
  };

  const submitHandler = () => {
    const options = {
      method: "GET",
      url: BASE_URL_ + location.state.paymentMethod.attrs.url,
      params: {
        currency: form.coin,
        network: form.network,
      },
    };
    checkoutRequest(options, checkoutRequestHandler);
    getExchangeInfo(form.coin);
  };
  const changeRouteHandler = () => {
    navigate("/history/payment-history");
  };
  const copyWalletAddressHandler = (data) => {
    navigator.clipboard.writeText(data);
    snackCtx.handleOpen("Copied to clipboard", "");
  };
  return (location?.state?.paymentMethod?.attrs.is_coin && (exchangeInfoLoading || checkoutLoading || listLoading)) ||
    listLoading ? (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
      <CircularProgress />
    </Box>
  ) : (
    <Grid container>
      <Grid item xs={12} md={11}>
        <CryptoPaymentForm
          isCoin={location?.state?.paymentMethod?.attrs.is_coin}
          walletAddress={walletAddress}
          cryptoAmount={cryptoAmount}
          form={form}
          cryptoList={cryptoList}
          onChange={changeFormHandler}
          loading={exchangeInfoLoading}
          onSubmit={submitHandler}
          onRouteChange={changeRouteHandler}
          fee={location?.state?.paymentMethod?.attrs.fee}
          onCopy={copyWalletAddressHandler}
        />
      </Grid>
    </Grid>
  );
};

export default CryptoPayment;
