import { Avatar, Box, Card, Grid, Typography } from "@mui/material";
import React from "react";
import TrendIcon from "../../../icons/TrendIcon";
import StatisticForm from "../ui/statistics-section";
import ChartIcon from "../../../icons/ChartIcon";
import PlusIcon from "../../../icons/PlusIcon";
import LinkSection from "../ui/link-section";

function StatisticsForm({ inquiry }) {
  const recaptcha = inquiry?.apis.find((item) => {
    if (item.api_info.name === "Bypass Recaptcha") return true;
  });
  const captcha = inquiry?.apis.find((item) => {
    if (item.api_info.name === "Captcha Solver") return true;
  });

  return (
    <Card type="card" sx={{ position: "relative", height: "100%", padding: "1.4rem" }}>
      <Typography variant="h1" fontWeight={600} color="#202020">
        Statistics
      </Typography>

      <Box sx={{ position: "absolute", top: 10, right: 20 }}>
        <Avatar sx={{ backgroundColor: "#E5EAFF", width: 60, height: 60 }}>
          <TrendIcon back={"#6C86DB"} />
        </Avatar>
      </Box>
      <Typography variant="h6" fontWeight={400} color="#787777">
        Your activity summary
      </Typography>
      <Grid container sx={{ mt: 4.5 }} spacing={3}>
        <Grid item xs={12} lg={6}>
          <LinkSection
            link={"/application"}
            icon={<ChartIcon fill={"#202020"} />}
            arrow={"#FFFFFF"}
            text="Test & Use Services"
            avatar={"#FFFFFF"}
            background="#7897FE"
            color={"#FFFFFF"}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <LinkSection
            link={"/history/service-usage-history"}
            icon={<PlusIcon fill={"#DBB26C"} />}
            arrow={"#FFFFFF"}
            text="Usage History"
            avatar={"#FFFFFF"}
            background="#DBB26C"
            color={"#FFFFFF"}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box sx={{ background: "#FAFBFB", p: "1.4rem", borderRadius: 2 }}>
            <StatisticForm
              title={"Captchas Submitted"}
              sectionOne={"Today"}
              valueOne={inquiry.services.today.total_requests}
              sectionTwo={"Week"}
              valueTwo={inquiry.services.week.total_requests}
              sectionThree={"Last month"}
              valueThree={inquiry.services.month.total_requests}
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box sx={{ background: "#FAFBFB", p: "1.4rem", borderRadius: 2 }}>
            <StatisticForm
              title={"Today Requests"}
              sectionOne={"Total"}
              sectionTwo={"Captcha"}
              sectionThree={"reCaptcha"}
              valueOne={captcha.today.total_requests + recaptcha.today.total_requests}
              valueTwo={captcha.today.total_requests}
              valueThree={recaptcha.today.total_requests}
            />
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}

export default StatisticsForm;
