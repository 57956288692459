export default function TicketIcon(props) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.411"
        height="13.88"
        viewBox="0 0 15.411 13.88"
      >
        <g
          fill="none"
          fillRule="evenodd"
          stroke={props.fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          transform="translate(.75 .75)"
        >
          <path
            d="M11.043 4.022L7.958 6.506a1.608 1.608 0 01-1.986 0L2.86 4.022"
            data-name="Path 13"
          ></path>
          <path
            d="M3.535 0h6.819a3.585 3.585 0 012.59 1.15 3.629 3.629 0 01.956 2.679v4.722a3.629 3.629 0 01-.959 2.679 3.585 3.585 0 01-2.59 1.15H3.535A3.593 3.593 0 010 8.551V3.829A3.593 3.593 0 013.535 0z"
            data-name="Path 14"
          ></path>
        </g>
      </svg>
    );
  }
  
  