import { Card, Divider, Grid, Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import { forwardRef } from "react";
import InvoiceTable from "./InvoiceTable";
import PrintIcon from "@mui/icons-material/Print";
import Title from "../layout/Title";
const InvoiceDetailToPrint = forwardRef((props, ref) => {
  return (
    <Grid container ref={ref}>
      <Grid item xs={12} md={11}>
        <Card type={"card"}>
          <Grid container>
            <Grid item xs={12}>
              <Title>Payment Details</Title>
              <Divider sx={{ mb: 3 }} />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography component="span" variant="h6" color="primary.text">
                From:
              </Typography>
              <Typography
                component="span"
                variant="h6"
                color="primary.text"
                sx={{ fontWeight: "100 !important", ml: 1 }}
              >
                MetaBypass
              </Typography>
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <Typography component="span" variant="h6" color="primary.text">
                To:
              </Typography>
              <Typography
                component="span"
                variant="h6"
                color="primary.text"
                sx={{ fontWeight: "100 !important", ml: 1 }}
              >
                {props?.invoice?.user?.first_name} {props?.invoice?.user?.last_name}
              </Typography>
            </Grid> */}
            <Grid item xs={12}>
              <Divider sx={{ my: 3 }} />
            </Grid>
            <Grid item xs={12}>
              <Typography component="span" variant="h6" color="primary.text">
                Payment Id:
              </Typography>
              <Typography
                component="span"
                variant="h6"
                color="primary.text"
                sx={{ fontWeight: "100 !important", ml: 1 }}
              >
                {props?.invoice?.pay_id}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography component="span" variant="h6" color="primary.text">
                Email:
              </Typography>
              <Typography
                component="span"
                variant="h6"
                color="primary.text"
                sx={{ fontWeight: "100 !important", ml: 1 }}
              >
                {props?.invoice?.to?.email}
              </Typography>
            </Grid>
            <Grid item xs={12} md={5} lg={4}>
              <Typography component="span" variant="h6" color="primary.text">
                Date:
              </Typography>
              <Typography
                component="span"
                variant="h6"
                color="primary.text"
                sx={{ fontWeight: "100 !important", ml: 1 }}
              >
                {new Date(props?.invoice?.created_at * 1000).toLocaleString()}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 3 }} />
            </Grid>
            <Grid item xs={12}>
              <Title>Product Info</Title>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: 800 }}>
                <InvoiceTable
                  orders={[
                    {
                      item: props?.invoice?.label,
                      plan_price: props?.invoice?.price,
                      discount: 0,
                      total: props?.invoice?.total,
                    },
                  ]}
                />
              </Typography>
            </Grid>

            <Grid item xs={12} sx={{ mt: 5 }}>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button variant={"contained"} startIcon={<PrintIcon />} onClick={props.printHandler}>
                  Print Form
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
});

export default InvoiceDetailToPrint;
