import { Grid, Typography } from "@mui/material";
function Package({ text, Icon, link }) {
  return (
    <a href={link} target="_blank" style={{ textDecoration: "none", color: "#0A1B31" }}>
      <Grid
        container
        sx={{ p: 2, cursor: "pointer", background: "#FCFBFB", borderRadius: 2, width: "350px", height: "200px" }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs="auto">
          <Icon />
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight={500} variant="h5" textAlign={"center"} sx={{ textTransform: "uppercase" }}>
            {text}
          </Typography>
        </Grid>
      </Grid>
    </a>
  );
}

export default Package;
