
import { useState,useEffect } from 'react'
import TicketForm from './form'
import { statistics as statisticsUrl ,createTicket as createTicketUrl } from "../../../../extentions/apiUrls";
import useHttp from "../../../../hook/useHttp";
import CircularProgressComponent from '../../../layout/CirCularProgressComponent';
import SnackContext from "../../../../store/snack-context";
import { useContext } from "react";
const TicketSection = ({showLoading})=>{
    const [ticketOption,setTicketOption] = useState(undefined)
    const [department,setDepartment] = useState(null)
    const [categoryList,setCategoryList] = useState([])
    const [customMessageList,setCustomMessageList] = useState([])
    const [category,setCategory] = useState(null)
    const [customMessage,setCustomMessage] = useState(null)
    const snackCtx = useContext(SnackContext);
    const [form,setForm] = useState({
        title:'feed back',
        priority:'Important',
        category:null,
        section:null,
        message:''
    })
    const {sendRequest: getstatisticsRequests } = useHttp();
    const { isLoading: sendLoading, sendRequest: sendTicketRequest } = useHttp();
    
    const getstatisticsRequestsHandler = (res) => {
        if (res.data.status_code === 200) {
            const data = res.data.data.ticket_options.section_parts
            const department = res.data.data.ticket_options.section_parts[0].operator_name
            setDepartment(department)
            setForm((prevState)=>{
                return{
                    ...prevState,
                    section:department
                }
            })
            let categories = data.map((item)=>{
                if(item.operator_name === department)
                {
                    return item.categories
                }
            })
            categories = categories.filter(function( element ) {
                return element !== undefined;
             });
             setCategoryList(...categories)
            setTicketOption(res.data.data.ticket_options.section_parts);
        }
      };
    useEffect(() => {
        let options = {
          method: "GET",
          url: statisticsUrl,
        };
        getstatisticsRequests(options, getstatisticsRequestsHandler);
      }, [getstatisticsRequests]);

    const selectDepartmentHandler = (text)=>{
        setDepartment(text)
        setForm((prevState)=>{
            return{
                ...prevState,
                section:text
            }
        })
        let categories = ticketOption.map((item)=>{
            if(item.operator_name === text)
            {
                return item.categories
            }
        })
        categories = categories.filter(function( element ) {
            return element !== undefined;
         });
         setCategoryList(...categories)
         setCategory('')
         setCustomMessage('')
         setCustomMessageList([])
         setForm((prevState)=>{
            return{
                ...prevState,
                message:''
            }
        })
    }
    const selectCategoryHandler = (text)=>{
        setCategory(text)
        setForm((prevState)=>{
            return{
                ...prevState,
                category:text
            }
        })
        let customMessage = categoryList.map((item)=>{
            if(item.name === text)
            {
                return item.msg_suggestions
            }
        })
        customMessage = customMessage.filter(function( element ) {
            return element !== undefined;
         });
        
         
         if(customMessage[0].length === 0 ){
            setCustomMessage('Custom message') 
         }

         setCustomMessage('')
         setForm((prevState)=>{
            return{
                ...prevState,
                message:''
            }
        })
         setCustomMessageList(customMessage)
    }
    const selectCustomMessageHandler = (text)=>{
        setCustomMessage(text)
        setForm((prevState)=>{
            return{
                ...prevState,
                message:text === "Custom message" ? '' :text
            }
        })
    }
    const changeMessageHandler = (e)=>{
        setForm((prevState)=>{
            return{
                ...prevState,
                message:e.target.value
            }
        })
    }

    const sendTicketHandler = (res)=>{
        
        snackCtx.handleOpen(res.data.message)
        setForm((prevState)=>{
            return{
                ...prevState,
                message:""
            }
        })
       
    }
    const formSubmitHandler = ()=>{
        
        if(form.category === null || form.category === "")
        {

            snackCtx.handleOpen('You Should Select Category')
        }
        
        else if(form.message.length < 10)
        {

            snackCtx.handleOpen('Your Message Should Contain at least 10 Characters ')
        }
        
        else{
            const data = {
                title: form.title,
                section: form.section,
                priority: form.priority,
                category: form.category,
                message: form.message,
              };
        
              const options = {
                url: createTicketUrl,
                method: "POST",
                data: data,
              };
        
              sendTicketRequest(options, sendTicketHandler);
        }
        
    }
    return(
        ticketOption === undefined ? showLoading ? <CircularProgressComponent/> : null  :
        <TicketForm 
            department={department}
            category={category}
            customMessage={customMessage}
            ticketOption={ticketOption}
            categoryList={categoryList}
            customMessageList={customMessageList}
            onSelectDepartment={selectDepartmentHandler}
            onSelectCategory={selectCategoryHandler}
            onSelectCutomMessage={selectCustomMessageHandler}
            onChangeMessage={changeMessageHandler}
            message={form.message}
            onSubmit={formSubmitHandler}
            submitLoading={sendLoading}
        />
  )
}

export default TicketSection