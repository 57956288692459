export default function DasboardIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.419"
      height="16.245"
      viewBox="0 0 15.419 16.245"
    >
      <path
        fill="none"
        fillRule="evenodd"
        stroke={props.fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M5.627 14.595v-2.247a1.045 1.045 0 011.043-1.04h2.115a1.045 1.045 0 011.05 1.04h0v2.254a.9.9 0 00.881.893h1.41a2.532 2.532 0 002.544-2.519h0V6.585a1.787 1.787 0 00-.7-1.4L9.144 1.343a2.33 2.33 0 00-2.894 0l-4.8 3.854a1.773 1.773 0 00-.7 1.4v6.384A2.532 2.532 0 003.294 15.5h1.41a.9.9 0 00.909-.9h0"
      ></path>
    </svg>
  );
}