import { useContext, useState } from "react";

import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { IconButton } from "@mui/material";
import BannerContext from '../../../store/banner-context';
export default function BannerDialog({ images, show }) {
  const bannerCtx = useContext(BannerContext)
  // const [open, setOpen] = useState(Boolean(bannerCtx?.banner?.show) || false);
  
  const [showArrow, setShowArrow] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleClose = () => {
    bannerCtx.handleClose(bannerCtx.banner.id)
  };

  return (
    <Dialog
      open={Boolean(bannerCtx?.banner?.show) || false}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        style: {
          height: "400px",
          maxHeight: "400px",
          width: "600px",
          maxWidth: "600px",
          margin: 0,
        },
      }}
    >
      <div className="carousel-wrapper" style={{height:'400px'}}>
        <Carousel
          useKeyboardArrows={true}
          showStatus={false}
          showArrows={showArrow}
          showThumbs={false}
          renderArrowNext={(clickHandler, hasNext) => {
            return (
              hasNext && (
                <IconButton
                  onMouseEnter={(e) => {
                    setShowArrow(true);
                  }}
                  onClick={clickHandler}
                  sx={{
                    position: "absolute",
                    zIndex: 2,
                    top: "calc(50% - 15px)",
                    width: 35,
                    height: 35,
                    cursor: "pointer",
                    right: "12px",
                    background: "white",
                    "&:hover": { background: "white" },
                  }}
                >
                  <ArrowForwardIosIcon fontSize={"small"} />
                </IconButton>
              )
            );
          }}
          renderArrowPrev={(clickHandler, hasPrev) => {
            return (
              hasPrev && (
                <IconButton
                  onMouseEnter={(e) => {
                    setShowArrow(true);
                  }}
                  onClick={clickHandler}
                  sx={{
                    position: "absolute",
                    zIndex: 2,
                    top: "calc(50% - 15px)",
                    width: 35,
                    height: 35,
                    cursor: "pointer",
                    left: "12px",
                    background: "white",
                    "&:hover": { background: "white" },
                  }}
                >
                  <ArrowBackIosIcon fontSize={"small"} />
                </IconButton>
              )
            );
          }}
        >
          {bannerCtx?.banner?.options?.desktopImage?.map((image, index) => (
            <div
              key={index}
              onMouseEnter={(e) => {
                setShowArrow(true);
              }}
              onMouseLeave={() => {
                setShowArrow(false);
              }}
              style={{ backgroundColor: "black", position: "relative", height: "400px", width: "100%" }}
            >
              <img
                style={{ width: "100%", height: "100%", maxWidth: 600, maxHeight: 400 }}
                alt="sample_file"
                src={image.img}
                key={index}
              />
            </div>
          ))}
        </Carousel>
      </div>
    </Dialog>
  );
}
