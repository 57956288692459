import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { noContent as noContentImg } from '../../extentions/imagesUrl';
import CirCularProgressComponent from '../layout/CirCularProgressComponent';
import {activeCurrency} from '../../extentions/appConfig'
import { Button } from '@mui/material';
const TransactionTable = (props)=>{

    const createTableOrderRows = ()=>{
        return(
        <TableBody>
            {
                props.transactions.map((transaction,idx)=>{
                    return(
                        <TableRow key={transaction?.id} sx={{cursor:'pointer'}} >
                            <TableCell align="center">{idx + 1}</TableCell>
                            <TableCell align="center">{transaction?.price}{activeCurrency}</TableCell>
                            <TableCell align="center">{new Date(transaction?.created_at * 1000).toLocaleString() }</TableCell>
                            <TableCell align="center">
                           
                                <Button variant='status' color={transaction?.status} >
                                    {transaction?.status }
                                </Button>
                                
                            </TableCell>
                            <TableCell align="center">
                               
                                    <Button variant='table' onClick={(e)=>props.onRowClick(transaction?.id)}>
                                        Show
                                    </Button>
                               
                            </TableCell>
                        </TableRow>
                    )
                })
            }
        </TableBody>)
    }
    const createTableOrder = ()=>{

        if(props.transactions !== undefined)
            return(
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Row</TableCell>
                                <TableCell align="center">Price</TableCell>
                                <TableCell align="center">Date</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Details</TableCell>
                            </TableRow>
                        </TableHead>
                        {props.isLoading ?  null : createTableOrderRows()}
                    </Table>
                    {
                     props.isLoading 
                      ? 
                        <CirCularProgressComponent/>
                      : 
                        props.transactions.length === 0 
                        ?
                            <div style={{display:'flex',justifyContent:'center'}}>
                                <img style={{height:'auto',width:'300px'}} src={noContentImg} alt={"captcha bypass"} />
                            </div>
                            :
                            null
                      }
                </TableContainer>
                    

            )

    }
    return(
        createTableOrder()
    )

}

export default TransactionTable