import {useContext,useState,useEffect} from 'react';
import {useNavigate,useLocation} from 'react-router-dom';
import LoginForm from '../components/Form/LoginForm';
import Grid from '@mui/material/Grid';
import useHttp from '../hook/useHttp';
import useKeyPress from '../hook/useKeyPress';
import {loginUrl,loginByGoogle} from '../extentions/apiUrls';
import AuthContext from '../store/auth-context';
import ModalContext from '../store/modal-context';
import SnackContext from '../store/snack-context';
import {getSettingUrl} from '../extentions/apiUrls';
import { useGoogleLogin } from '@react-oauth/google';
import Terms from '../components/terms/Terms'
const initialFormData={
    email:'',
    password:'',
}

const Login = (props)=>{
    let navigate = useNavigate()
    const location = useLocation()
    const[formData,setFormData] = useState(initialFormData);
    const[googleAuth,setGoogleAuth] = useState(false)
    const[recaptcha,setRecaptcha] = useState(false)
    const  {isLoading,error,sendRequest:loginRequest} = useHttp()
    const {isLoading:gooleLoading,sendRequest:loginByGoogleRequest} = useHttp()
    const  {isLoading:settingLoadding,sendRequest:settingRequest} = useHttp()
    const authCtx = useContext(AuthContext)
    const modalCtx = useContext(ModalContext)
    const snackCtx = useContext(SnackContext)
    
    const {keyPressHandler} = useKeyPress()
   
    
    const handleTermsClick = ()=>{
        const content = {body:<Terms/>}
        modalCtx.modalHandler(content)
       
    }


    useEffect(()=>{
        const settingRequestHandler = (res)=>{
            try {
                setGoogleAuth(res?.data?.data[0]?.value)
            }
            catch(err){}
        }   
        const options ={
            method:'GET',
            url:getSettingUrl,
        }
        settingRequest(options,settingRequestHandler)
    },[settingRequest])
    function handleLogin(res) {
        if(res.data.status_code === 200)
        {
            modalCtx.modalHandler("")
            let token       = res.data.data.access_token
            let role        = res.data.data.role
            let permissions = res.data.data.permissions
            let firstName   = res.data.data.first_name
            let lastName    = res.data.data.last_name
            let email       = res.data.data.email
            let userId      = res.data.data.user_id
            authCtx.login(token,role,permissions,firstName,lastName,email,userId)
            if(location.state !== null)
            {   
                if(location.state.hasOwnProperty('from')){
                    navigate(location.state.from.pathname); 
                }
            }
            else
            {
                navigate('/'); 
            }
            
        }
        else
        {
            snackCtx.handleOpen(res.data.message,'')
        }

    }
    const login = ()=>{
        
        let data={
            "email": formData.email,
            "password": formData.password,
            "g_response":recaptcha
        }
        
        data = JSON.stringify(data)
        const options = {
            method:'POST',
            url:loginUrl,
            data:data,
        }
        loginRequest(options,handleLogin)
    }
    const inputKeyPressHandler = (e)=>{
        keyPressHandler(e,['Enter','NumpadEnter'],login)
    }
    const onLoginButtonClick = () =>{
        
        login()
    }



    const loginByGoogleHandler = useGoogleLogin({
        onSuccess: (res)=>{
            const options={
                method:'POST',
                url:loginByGoogle,
                data:{token:res?.access_token}
            }
            loginByGoogleRequest(options,handleLogin)
        }
    })
    const recaptchaChangleHandler = (res)=>{
        setRecaptcha(res)
    }


    
    const googleFailureHandler = (res)=>{
    }

       
        const emailChangeHandler = (event)=>{
            setFormData(
                (state)=>{
                    return {
                        ...state,
                        email:event.target.value
                    }
                }
            )
        }
    
        const passwordChangeHandler = (event)=>{
            setFormData(
                (state)=>{
                    return {
                        ...state,
                        password:event.target.value
                    }
                }
            )
        }

    return(
        
           
             <>
                <Grid container>
                    <Grid item xs={12}  md={12} >
                        <LoginForm 
                            isLoading={isLoading} 
                            googleLoading={gooleLoading}
                            error={error} 
                            responseGoogle={loginByGoogleHandler}
                            onFailure={googleFailureHandler}
                            onButtonClick={onLoginButtonClick}
                            onForgetPasswordClick={props.onForgetPasswordClick}
                            onKeyPress={inputKeyPressHandler}
                            formData = {formData}
                            emailChangeHandler={emailChangeHandler}
                            passwordChangeHandler={passwordChangeHandler}
                            authGoogle={googleAuth}
                            settingLoadding={settingLoadding}
                            handleRecaptcha={recaptchaChangleHandler}
                            onTermsClick={handleTermsClick}
                        />
                    </Grid>
                </Grid>
                
             </>
        
    
    )
}


export default Login