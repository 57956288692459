
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
const CircularProgressComponent = (props)=>{

    return(
        <Grid container direction="row" alignItems="center" justifyContent="center" style={{minHeight:props.minHeight ? props.minHeight :'100vh',display:'flex'}}>
            <CircularProgress/>
        </Grid> 
    )
}

export default CircularProgressComponent;