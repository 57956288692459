
import {useContext,Fragment,useState} from 'react'

import { Navigate ,useLocation,useRoutes } from "react-router-dom";

import AuthContext from '../store/auth-context';
import Routes from '../extentions/Routes';
import Appbar from '../components/layout/Appbar';
const AppRoute = ()=>{
    const [mobileOpen, setMobileOpen] = useState(false);
    const authCtx = useContext(AuthContext)
    const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  }

    const checkPermission = (permissions)=>{
        let grant = true
        if(permissions.length === 0 )
        {
            return true
        }
        permissions.forEach(permission => {
            if (!authCtx.checkPermission(permission))
            {
                grant=false
            }
        });
        
        return grant
    }
    
    let routes=[]
    const extractRoute = (routes)=>{
        return routes.map((item)=>{
            let prop = {}
            let Component = item.component
            if(item.title === "Dashboard")
            {
                
                prop = {mobileOpen:mobileOpen ,handleDrawerToggle:handleDrawerToggle}
                
            }
            
            let route
            if(checkPermission(item.permissions)){
                if (Component)
                    route = {path:item.path,element:<CheckedAuth isPrivate={item.isPrivate}><Component {...prop}/> </CheckedAuth>}
                else{
                    route = {path:item.path}
                }
                if (item.children) {route.children = extractRoute(item.children)}
                return route
            }
        })
    }

    
    
    routes = extractRoute(Routes)
    
    
    const filterRoute = (_routes)=>{
     
      let a= _routes.filter((route)=>{        

            if(route !== undefined && route.children)
            {
                route.children = filterRoute(route.children)
                
                return true
                
            }
            else if(route !== undefined)
            {
                return true
            }
        })
        
        return a
    }

    
    filterRoute(routes)
    
    routes = useRoutes(routes)
    
    let location = useLocation()
    return(
        <Fragment>
                {location.pathname === '/login' ? null :<Appbar handleDrawerToggle={handleDrawerToggle}/>}
                <div id="body" >
                    {routes}
                </div>
                
        </Fragment>
    )

}


const CheckedAuth = (props,permission)=>{
    let location = useLocation()
    const authCtx = useContext(AuthContext)
    
    if(props.isPrivate)
    {
        
        if(authCtx.token !== "" && authCtx.token !== null)
        {
            return props.children
        }
        else
        {
            return <Navigate to="/login" replace state={{ from: location }} />;
        }
    }
    else
    {
        return props.children
    }
}

export default AppRoute;