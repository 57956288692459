import { Box, Button, Card, Grid, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const SuccessfulPaymentForm = ({ onRedirect, payment }) => {
  return (
    <Grid container>
      <Grid item xs={12} md={11}>
        <Grid container>
          <Grid item xs={12}>
            <Card type="card" sx={{ padding: "1.4rem" }}>
              <Grid container>
                <Grid item xs={12} sx={{ textAlign: "center", mb: 1 }}>
                  <CheckCircleIcon sx={{ "&.MuiSvgIcon-root": { fontSize: "2.9rem" }, color: "#089839" }} />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h2" sx={{ color: "#089839", textAlign: "center", fontWeight: "600" }}>
                    Successful Payment
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ mt: 5 }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container alignItems="center">
                        <Grid item xs="auto">
                          <Typography variant="h4" sx={{ mr: 1 }}>
                            Price
                          </Typography>
                        </Grid>
                        <Grid item xs sx={{ position: "relative" }}>
                          <Box
                            sx={{
                              borderBottom: "1px dashed gray",
                              position: "absolute",
                              width: "100%",
                              bottom: "-4px",
                            }}
                          ></Box>
                        </Grid>
                        <Grid item xs="auto" sx={{ ml: 1 }}>
                          <Typography variant="h4">{payment?.plan_price}$</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <Grid container alignItems="center">
                        <Grid item xs="auto">
                          <Typography variant="h4" sx={{ mr: 1 }}>
                            Status
                          </Typography>
                        </Grid>
                        <Grid item xs sx={{ position: "relative" }}>
                          <Box
                            sx={{
                              borderBottom: "1px dashed gray",
                              position: "absolute",
                              width: "100%",
                              bottom: "-4px",
                            }}
                          ></Box>
                        </Grid>
                        <Grid item xs="auto" sx={{ ml: 1 }}>
                          <Typography variant="h4">{payment?.status}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 3 }}>
                      <Grid container spacing={2} justifyContent="space-between">
                        <Grid item xs={12} sm="auto">
                          <Button
                            name="/application"
                            onClick={onRedirect}
                            variant="contained"
                            fullWidth
                            sx={{ maxWidth: "100%" }}
                          >
                            Application
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm="auto">
                          <Button name="/" onClick={onRedirect} variant="outlined" fullWidth sx={{ maxWidth: "100%" }}>
                            Dashboard
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SuccessfulPaymentForm;
