import {Grid,Typography} from '@mui/material'
import useMediaQuery from "@mui/material/useMediaQuery";
const TableRow = ({title,borderTop,borderBottom,value,img})=>{
    const mobile = useMediaQuery("(max-width:600px)");
    
    const rightCellStyle={
        p:2,
        
        display:'flex',
        alignItems:"center",
        
        borderBottom:'1px solid gray',
        backgroundColor:'#EEEEEE'
    }

    const leftCellStyle={
        p:2,
        
        display:'flex',
        alignItems:"center",
        borderRight:mobile ? 0  : '1px solid gray',
        borderBottom:'1px solid gray',
        justifyContent:mobile ? 'center' : 'start'
    }
    return(
        <>
        <Grid item xs={12} sm sx={leftCellStyle}>
            
                <Typography nowrap={true} textAlign={mobile?'center':'start'}>
                    {title}
                </Typography>
            
        </Grid>
        <Grid item xs={12} sm sx={rightCellStyle}>
                <Typography nowrap={true} textAlign={mobile?'center':'start'} style={{ wordWrap: "anywhere" }}>
                   
                   {
                       img ? <img width={100} allt="Captcha" src={`data:image/png;base64, ${value}`}/> : value
                   }
                   
                </Typography>
        </Grid>
        </>
    )
}

export default TableRow