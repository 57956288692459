
import {Button, CircularProgress, Grid} from '@mui/material'
import DragFileUpload from '../upload/drag/DragFileUpload';

const ImageSection = (props)=>{


    return(
        <Grid container rowSpacing={4}> 
            <Grid item xs={12} sx={{mt:2}}>
                <DragFileUpload file={props.file} fileUpload={props.fileUpload} acceptedFiles={props.acceptedFiles} toast={props.toast} sizeLimit={'5MB'}/>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                        <Grid item xs={12} sm={'auto'}>
                            {
                                props.isLoading 
                                    ?
                                        <CircularProgress/>
                                    :
                                        <Button sx={{maxWidth:'100%'}} variant="contained" onClick={props.onButtonClick} fullWidth>Send Image</Button>
                            }
                        </Grid>
                </Grid>
            </Grid>
            
        </Grid>
    )

}

export default ImageSection;

