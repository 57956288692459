export function hasChildren(item) {
  const children = item.children;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}

export const checkPermission = (permissions, check) => {
  let grant = true;
  if (permissions.length === 0) {
    return true;
  }
  permissions.forEach((permission) => {
    if (!check(permission)) {
      grant = false;
    }
  });

  return grant;
};

export const calculateBoost = (boost) => {
  if (boost == 0) return 0;
  return (boost * 1 + 100) / 100;
};
