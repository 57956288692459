import { styled } from '@mui/material/styles';
import { Typography, Grid, Box, Card, Divider } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const Message = styled(Card)(({ from }) => {
  if (from === 'other') {
    return {
      boxShadow: 'none',
      borderColor: '#D6D4D4',
      color: '#818080',
      background: 'white',
      borderRadius: '16px',
      borderStyle: 'solid',
      borderWidth: '1px',
      padding: 16,
    };
  }
  if (from === 'me') {
    return {
      boxShadow: 'none',
      color: 'white',
      borderRadius: '16px',
      background: '#88A3FC',
      padding: 16,
    };
  }
});

const TicketDetailFormMessage = (props) => {
  const convertDate = (date) => {
    let d = new Date(date * 1000);
    let dateTime = d.toLocaleString();
    // dateTime = `${dateTime} - ${d.getHours() >= 10 ? d.getHours() :'0' + d.getHours()}: ${d.getMinutes() >= 10 ? d.getMinutes() : '0' + d.getMinutes() }`
    return dateTime;
  };
  return (
    <Grid
      container
      justifyContent={props.fromMe ? 'flex-end' : 'flex-start'}
      sx={{ my: 1 }}>
      <Grid item xs={12} sm={11} md={9} sx={{ wordBreak: 'break-all' }}>
        <Message from={props.fromMe ? 'me' : 'other'}>
          <Grid container>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  component={'div'}
                  variant={'body1'}
                  sx={{ flexGrow: 1 }}>
                  {props.fromMe ? 'you' : props.from.email}
                </Typography>
                <Typography component={'div'} variant={'body2'}>
                  {convertDate(props.date)}
                </Typography>
              </Box>
              <Divider color={'white'} sx={{ my: 1 }} />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  component={'div'}
                  variant={'body1'}
                  sx={{ flexGrow: 1, whiteSpace: 'pre-wrap' }}>
                  {props.message}
                </Typography>
                <Typography component={'div'} variant={'body2'}>
                  {props.attach ? (
                    <Typography
                      component={'a'}
                      href={props.attach}
                      target="_blank"
                      rel="noreferrer">
                      <AttachFileIcon
                        sx={{
                          background: '#bdcdf8',
                          padding: '4px',
                          borderRadius: '50%',
                          color: '#1140db',
                        }}
                      />
                    </Typography>
                  ) : null}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Message>
      </Grid>
    </Grid>
  );
};

export default TicketDetailFormMessage;
