
import {useState,useContext, useEffect} from 'react';
import useHttp from '../../hook/useHttp';
import HelpForm from './HelpForm';
import SnackContext from '../../store/snack-context';
import {helpUs as helpUsUrl} from '../../extentions/apiUrls';
const ACCEPTED_FILES=['PNG','JPG','ZIP']
const Help = ()=>{
    const[fileName,setFileName] = useState('')
    const[radioValue,setRadioValue] = useState(0)
    const[link,setLink] = useState('')
    const[result,setResult] = useState('')
    const[image,setImage] = useState()
    const[readFile,setReadFile] = useState(undefined)
    const[file,setFile] = useState(undefined)
    const snackCtx = useContext(SnackContext)
    const {isLoading,sendRequest:postCaptchaRequest} = useHttp()
    const getBase64 = (file, callBack,callback1)=> {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        
        reader.onload = function (upload) {
            // let result = upload.target.result.split(',')
            callback1(reader.result)
            let base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
            callBack(base64String)
        };
        reader.onerror = function (error) {

        };
    }
    
    const getImage = (res)=>{
        setReadFile(res)
    }
    const getBase64Result = (res)=>{
        
        setImage(res)
    }
    const handleFileChange = (file)=>{
        if(file)
        {
            setFile(file)
            getBase64(file,getBase64Result,getImage)
            setResult("")
        }
        else{
            setFile(undefined)
            setImage(undefined)
            setReadFile(undefined)
            setResult("")
        }
    }

    const handleUploadPicture = (file)=>{
        if(file){
            setFileName(file.name)
            getBase64(file,getBase64Result,getImage)
        }
        
        
        
    }

    const radioButtonChangeHandler = (e)=>{

        setRadioValue(e)
    }

    const linkChangeHandler = (e)=>{
        setLink(e.target.value)
    }

    const postCaptchaRequestHandler =(res)=>{
        snackCtx.handleOpen(res.data.message)
        if(res.data.status_code === 200) 
        {
            setFile(undefined)
            setImage(undefined)
            setReadFile(undefined)
            setResult("")
            setLink('')
        }
    }
    const postCaptchaHandler = (e)=>
    {

        
            let options={
                url:helpUsUrl,
                method:'POST',
                data:radioValue === 1 ? {link:link} :{pics:image}
            }
            postCaptchaRequest(options,postCaptchaRequestHandler)
        

    }

    return(
        <HelpForm
            onRadioButtonChange={radioButtonChangeHandler}
            radioButtonValue={radioValue}
            onLinkChange={linkChangeHandler}
            linkValue={link}
            fileName={fileName} 
            handleUploadPicture={handleUploadPicture} 
            file={file}
            fileUpload={handleFileChange}
            result={result} 
            isLoading={isLoading}
            readFile={readFile}
            acceptedFiles={ACCEPTED_FILES}
            toast={snackCtx.handleOpen}
            onButtonClick={postCaptchaHandler}
        />
    )

}

export default Help