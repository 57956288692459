import {useState,useEffect,useContext, useCallback} from 'react';
import {useParams} from 'react-router-dom';
import useHttp from '../../hook/useHttp';
import {ticketDetail as ticketDetailUrl, sendMessage as sendMessageUrl,ticketStatusUrl} from '../../extentions/apiUrls';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TicketDetailForm from './TicketDetailForm'
import SnackContext from '../../store/snack-context'
import AuthContext from '../../store/auth-context'
import { CircularProgress, Divider } from '@mui/material';
import { Box } from '@mui/material';
import DashboardContext from '../../store/dashboard-context';
import Title from '../layout/Title';
import ModalContext from '../../store/modal-context';
import {default as DeleteWarning} from '../warnings/Warning';

const TicketDetail = ()=>{
    const params   = useParams();
    const snackCtx = useContext(SnackContext)
    const authCtx  = useContext(AuthContext)
    const appCtx   = useContext(DashboardContext)
    const modalCtx = useContext(ModalContext)
    let messages=[]
    const {isLoading,sendRequest:getTicketDetailRequest} = useHttp()
    const {isLoading:loading,sendRequest:sendMessageRequest} = useHttp()
    const {isLoading:loadingStatus,sendRequest:closeTicketRequest} = useHttp()
    const [ticket,setTicket]  = useState(null)
    const [messageHistory,setMessageHistory] = useState([])
    const [message,setMessage]         = useState('')
    const [file,setFile]               = useState(undefined)
    const [encodedFile,setEncodedFile] = useState(undefined)
    const [error,setError]             = useState(false)


    const handleGetTicketDetailRequest =(res)=>{
        
        if(res.data.status_code === 200)
        {
            appCtx.setUnreadTicket(res.data.data.unread_tickets)
            setTicket(res.data.data.ticket)
            messages = res.data.data.messages
            
            try
            {
                let msg =  messages.map((item,index)=>{
              
                return{ 
                        id     : item.message.id,
                        fromMe : item.message.from_id === authCtx.userId,
                        message: item.message.message,
                        attach : item?.attachment ? item.host + "/" + item?.attachment.file_name : undefined,
                        date   : item.message.created_at,
                        from   : item.message.from
                    }
            })
            setMessageHistory(msg)
            }
            catch(err)
            {
               
            }
            // 
            
        }
    }

    
    
    const getMessages = useCallback(()=>{
        let options={
            method:'GET',
            url:ticketDetailUrl,
            params:{ticket_id:params.id,update_messages_status:true}
        }

        getTicketDetailRequest(options,handleGetTicketDetailRequest)
    },[getTicketDetailRequest,params.id,handleGetTicketDetailRequest])

    useEffect(
        ()=>{
            window.scrollTo(0,0)
            getMessages()
        }
    ,[getTicketDetailRequest])


    const getBase64 = (file, callBack)=> {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (upload) {
            // let result = upload.target.result.split(',')
            let base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
            callBack(base64String)
        };
        reader.onerror = function (error) {

        };
    }
    
    const getBase64Result = (res)=>{
        setEncodedFile(res)
    }
    const handleFileChange = (e)=>{
       
        try{
            let file = e?.target?.files[0]
            if(file)
            {
                setFile(file)
                getBase64(file,getBase64Result)
            }
        else
            {
                setFile(undefined)
                setEncodedFile(undefined)
            }
        }
        catch
        {
            setFile(undefined)
            setEncodedFile(undefined)
        }
        
    }
    const handleMessageChange = (msg)=>{
        setMessage(msg)
        setError(false)
    }

    const sendMessageRequestHandler = (res)=>{
        if(res.data.status_code === 200)
        {
            snackCtx.handleOpen(res.data.message)
                // navigate('/dashboard/tickets/ticket-list')
            setMessage('')
            setFile(undefined)
            getMessages()
        }
        else
        {
            snackCtx.handleOpen(res.data.message)    
        }
    }
    
    const handleSendMessage = ()=>{
        if(message === "")
        {
            setError(true)
        }
        
        else
        {
            let options={
                method:'POST',
                url: sendMessageUrl,
                data:{ticket_id:params.id,message:message,file:encodedFile}
            }

            sendMessageRequest(options,sendMessageRequestHandler)
        }
    }

    const closeTicketRequestHandler = (res)=>{

        snackCtx.handleOpen('ticket closed successfully')
    }
    const closeTicketHandler = ()=>{
        modalCtx.modalHandler("")
        const options = {
            method:'PUT',
            url:ticketStatusUrl,
            data:{ticket_id:params.id,status:'closed'}
        }
        closeTicketRequest(options,closeTicketRequestHandler)
    }

    const handleCloseTicketReject = ()=>{
        modalCtx.modalHandler("")
    }

    const handleCloseTicket = ()=>{
        const content={
            title:'',
            body:
            <DeleteWarning 
                title={'Deactivate Plan'} 
                caption={`Are you sure ?`} 
                description={'Ticket colsed permanently'}
                onConfirm={closeTicketHandler} 
                onReject={handleCloseTicketReject}
                haveReject={true}
            />}
            
        modalCtx.modalHandler(content)
        
    }

    return(

        ticket === null
        ?
            <Box sx={{display:'flex',justifyContent:'center'}}><CircularProgress/></Box>
        :
        <Grid container sx={{p:2}}>  
            <Grid item xs={12} md={11}>
                
                    <Box >
                        {
                            isLoading ? null :<>
                        
                            
                            <Box sx={{display:'flex',p:1,flexDirection:{xs:'column',sm:'row'}}}>
                                <Box sx={{flexGrow:1,display:'flex',alignItems:'center'}}>
                                    <Title>
                                        {ticket?.title}
                                    </Title>
                                    
                                        <Button variant={'status'} color={ticket?.priority} >{ticket?.priority}</Button>
                                    
                                </Box>
                                {
                                    loadingStatus 
                                    ?
                                        <Box color={'#FE466A'}> <CircularProgress color={'inherit'}/></Box>
                                    :
                                    ticket.status !== 'closed'?
                                        <Button 
                                            fullWidth
                                            variant={'contained'}
                                            sx={{maxWidth:{xs:"100%",sm:'180px'},mt:{xs:2,sm:0},background:'#FE466A','&:hover':{background:'#FE466A'}}} 
                                            onClick={handleCloseTicket}  >
                                                Close Ticket
                                        </Button>:null
                                }
                            </Box>
                        
                        </>
                        }
                    </Box>
                    <Divider />
                    <TicketDetailForm 
                        messages={messageHistory}
                        status ={ticket.status}
                        file={file} 
                        fileUpload={handleFileChange} 
                        onMessageChange={handleMessageChange}
                        message={message}
                        messageLoading={isLoading}
                        error={error}
                        onSendMessage={handleSendMessage}
                        sendMessageLoading={loading}
                        oncloseTicket={closeTicketHandler}
                        statusLoading={loadingStatus}
                    />
                
                
            </Grid>
        </Grid>
    )


}

export default TicketDetail;