import {Box,Avatar,Typography,Grid} from '@mui/material'
import TickIcon from '../../../icons/TickIcon'
const ProductCard = ({id,checked,price,captcha,recaptcha,onSelectPlan,api})=>{

    return(
        <Box sx={{border:checked ? '2px solid #5BC17E' :'2px solid #EEEEEE',borderRadius:4}} > 
            <Grid container spacing={2}  alignItems="center" justifyContent="space-between"
             sx={{padding:{xs:'0.5rem',xl:'2rem 3rem'},cursor:'pointer'}} 
             onClick={()=>onSelectPlan(id)}
             >
            <Grid item xs={12} sm={2} >
                <Box sx={{display:'flex',justifyContent:{xs:'center',sm:'start'}}}>
                    <Avatar sx={{backgroundColor:'#E5FFF0',color:'#5BC17E',fontWeight:'bold',fontSize:'0.8rem',width:55,height:55}}>
                        {price}$
                    </Avatar>
                </Box>
                
            </Grid>
            <Grid item container xs={12} sm={8}>
                <Grid item xs={12} container>
                    {
                        api?.map((item,index)=>{
                            if(item.remaining_requests !== -1)
                            return(
                                <Grid item key={index} xs={12} container alignItems={'center'}>  
                                    <Grid item xs={'auto'}>
                                        <Typography component="span" color="#787777">
                                            {item?.api_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs sx={{position:'relative'}}>
                                        <Box sx={{position:'absolute',left:3,right:4,borderBottom:'1px dashed gray'}}> 

                                        </Box>
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <Typography component="span" color="#787777" >
                                            {item.remaining_requests === -1 ? 'Unlimited' : item.remaining_requests}
                                        </Typography>
                                    </Grid>            
                                </Grid>
                            )
                        })
                    }
                    
                    
                    
                </Grid>
            </Grid>
            <Grid item xs={12} sm={2} container sx={{justifyContent:{xs:'center',sm:"end"}}}>
                {
                    checked ? <TickIcon/> :null
                }
            </Grid>
        </Grid>
        </Box>
    )

}

export default ProductCard