import { Grid } from "@mui/material";
import RecaptchaTestForm from "./RecaptchaTestForm";
import RecaptchaTestSendToken from "./RecaptchaTestSendToken";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
const RecaptchaTestFormContainer = (props) => {
  return (
    <Grid container>
      <Grid item xs={12} sx={{mt:2}}>
        <FormControl disabled={props.isLoading}>
          <FormLabel id="demo-radio-buttons-group-label">Already have a recapthca id (only for version 2)?</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="No"
            name="radio-buttons-group"
            row={true}
            value={props.tokenRadioButton}
            onChange={props.onTokenRadioButtonChange}
          >
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Grid>
      {props.tokenRadioButton === "Yes" ? null : (
        <Grid item xs={12}>
          <FormControl disabled={props.isLoading}>
            <FormLabel id="demo-radio-buttons-group-label">What is you Protocol?</FormLabel>
            <RadioGroup
              aria-labelledby="radio-buttons-Protocol"
              defaultValue="Https://"
              name="radio-buttons-Protocol"
              row={true}
              value={props.protocolRadioButton || "Https://"}
              onChange={props.onProtocolRadioButtonChange}
            >
              <FormControlLabel value="https://" control={<Radio />} label="Https" />
              <FormControlLabel value="http://" control={<Radio />} label="Http" />
            </RadioGroup>
          </FormControl>
        </Grid>
      )}

      <Grid item xs={12}>
        {props.tokenRadioButton === "No" ? (
          <RecaptchaTestForm
            token={props.token}
            onRecaptchFormChange={props.onRecaptchFormChange}
            recaptchData={props.recaptchData}
            onGetTokenClick={props.onGetTokenClick}
            isLoading={props.isLoading}
            onCopyClientSecret={props.onCopyClientSecret}
            protocol={props.protocolRadioButton}
            radioButtonDisable={props.radioButtonDisable}
            codeSample={props?.codeSample?.re_captcha}
          />
        ) : (
          <RecaptchaTestSendToken
            token={props.token}
            onTokenChange={props.onTokenChange}
            onSendToken={props.onSendToken}
            isLoading={props.isLoading}
            recaptchaResponse={props.recaptchaResponse}
            onCopyClientSecret={props.onCopyClientSecret}
            codeSample={props?.codeSample?.get_result}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default RecaptchaTestFormContainer;
