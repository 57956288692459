export default function ProfileIcon(props) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.434"
        height="19.163"
        viewBox="0 0 15.434 19.163"
        fill={props.fill}
      >
        <g
          fill="none"
          stroke={props.fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          transform="translate(.75 .75)"
        >
          <circle
            cx="4.39"
            cy="4.39"
            r="4.39"
            data-name="Ellipse 4"
            transform="translate(2.574)"
          ></circle>
          <path
            fillRule="evenodd"
            d="M0 14.887a2.036 2.036 0 01.2-.892 3.714 3.714 0 012.59-1.489 15.416 15.416 0 012.153-.3 23.007 23.007 0 014.029 0 15.6 15.6 0 012.153.3 3.574 3.574 0 012.59 1.489 2.086 2.086 0 010 1.791 3.539 3.539 0 01-2.59 1.48 14.446 14.446 0 01-2.153.311 23.729 23.729 0 01-3.28.051 3.73 3.73 0 01-.749-.051 14.166 14.166 0 01-2.143-.31 3.556 3.556 0 01-2.6-1.48 2.1 2.1 0 01-.2-.9z"
            data-name="Path 4"
          ></path>
        </g>
      </svg>
    );
  }
  
