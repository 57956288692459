import React from "react";

function LogOutIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "18"}
      height={props.height || "18"}
      viewBox="0 0 16 20"
    >
      <path
        fill="#ff5353"
        d="M4 12a1 1 0 001 1h7.59l-2.3 2.29a1 1 0 101.42 1.42l4-4a1.034 1.034 0 000-1.42l-4-4a1 1 0 00-1.42 1.42l2.3 2.29H5a1 1 0 00-1 1zM17 2H7a3 3 0 00-3 3v3a1 1 0 002 0V5a1 1 0 011-1h10a1 1 0 011 1v14a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-2 0v3a3 3 0 003 3h10a3 3 0 003-3V5a3 3 0 00-3-3z"
        transform="translate(-4 -2)"
      ></path>
    </svg>
  );
}

export default LogOutIcon;
