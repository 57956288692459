import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useHttp from "../hook/useHttp";
import Box from "@mui/material/Box";
// import Link from '@mui/material/Link';
import { Outlet } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import AuthContext from "../store/auth-context";
import Typography from "@mui/material/Typography";
import { appUrl, getSettingUrl } from "../extentions/apiUrls";
import { Card, Grid, Button, Badge } from "@mui/material";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import ErrorBoundry from "../utils/ErrorBoundry";
import CloseIcon from "@mui/icons-material/Close";
import DashboardContext from "../store/dashboard-context";
import Menu from "../components/menu/Menu";
import ProfileIcon from "../components/icons/ProfileIcon";
import LogOutIcon from "../components/icons/LogOutIcon";
import AlertIcon from "../components/icons/AlertIcon";
import { drawerLogo } from "../extentions/imagesUrl";
import ModalContext from "../store/modal-context";
import BannerContext from "../store/banner-context";
import { default as LogoutWarning } from "../components/warnings/Warning";
import PlusIcon from "../components/icons/PlusIcon";
import TestServiceIcon from "../components/icons/TestServiceIcon";
import BannerDialog from "../components/layout/banner";
const drawerWidth = 250;

function Dashboard(props) {
  const { window } = props;
  const [banner, setBanner] = useState(undefined);
  const authCtx = useContext(AuthContext);
  const appCtx = useContext(DashboardContext);
  const modalCtx = useContext(ModalContext);
  const bannerCtx = useContext(BannerContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { sendRequest: getApplicationData } = useHttp();
  const container = window !== undefined ? () => window().document.body : undefined;

  const rejectLogoutWarningHandler = () => {
    modalCtx.modalHandler("");
  };
  const confirmLogoutWarningHandler = () => {
    modalCtx.modalHandler("");
    authCtx.logout();
  };

  const testServiceClickHandler = () => {
    navigate("/application");
  };
  const addFundsClickHandler = () => {
    navigate("/add-funds");
  };

  const logoutHandler = () => {
    const content = {
      title: "",
      body: (
        <LogoutWarning
          title={"Logout"}
          caption={`Are you sure you want to logout ?`}
          onConfirm={confirmLogoutWarningHandler}
          onReject={rejectLogoutWarningHandler}
          confirmCaption={"Logout"}
          rejectCaption={"Cancel"}
          haveReject={true}
        />
      ),
    };

    modalCtx.modalHandler(content);

    // authCtx.logout()
  };
  useEffect(() => {
    const getApplicationErrorHandler = (res) => {
      const settings = res.data.data;
      let item = settings.filter((item) => {
        return item.option_name === "services.available";
      });
      try {
        item = item[0];
        appCtx.setServerError(!item.value);
      } catch (err) {}
    };
    const getApplicationDataHandler = (res) => {
      appCtx.setUnreadTicket(res.data.data.unread_tickets);
      const options = {
        method: "GET",
        url: getSettingUrl,
      };
      getApplicationData(options, getApplicationErrorHandler);
    };
    const options = {
      method: "GET",
      url: appUrl,
    };
    getApplicationData(options, getApplicationDataHandler);
  }, [location, getApplicationData]);

 
  useEffect(() => {
    const banners = bannerCtx.getBanners();
    const banner = banners.find((item) => {
      return item.slug === location.pathname;
    });
    bannerCtx.loadBanner(banner);
  }, [location]);

  return (
    <Box sx={{ display: "flex", background: "rgb(236,241,241)", position: "relative" }}>
      <Drawer
        container={container}
        variant="temporary"
        open={props.mobileOpen}
        onClose={props.handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            height: "100%",
            background: "#FFF",
            color: "black",
          },
        }}
      >
        <div style={{ display: "flex", justifyContent: "center", marginTop: 16 }}>
          <Avatar
            sx={{
              width: 64,
              height: 64,
              background: "#E5EAFF",
            }}
          >
            <ProfileIcon fill={"#6C86DB"} />
          </Avatar>
        </div>

        <div style={{ textAlign: "center", marginBottom: 30 }}>
          <Typography sx={{ mt: 1 }} variant={"body2"}>
            {authCtx.email}
          </Typography>
          <div style={{ marginTop: "24px" }}>
            <Button
              variant="outlined"
              sx={{
                borderColor: "#f56666",
                minWidth: "120px !important",
                fontSize: "10px !important",
                padding: "2px 4px",
                color: "#f56666",
                "&:hover": {
                  background: "white",
                  color: "#f56666",
                  borderColor: "#f56666",
                },
              }}
              onClick={logoutHandler}
              startIcon={<LogOutIcon height={9} width={9} />}
            >
              Logout
            </Button>
          </div>
        </div>
        <Menu onCloseDrawer={props.handleDrawerToggle} />
      </Drawer>

      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,

          display: { xs: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            fontSize: "0.7rem",
            background: "#FFF",
            color: "black",
            border: 0,
            overflowX: "hidden",
            overflowY: "scroll",
            height: "100%",
            "&::-webkit-scrollbar": {
              width: "0.4em",
            },

            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "primary.main",
              borderRadius: "4px",
            },
          },
          border: 0,
        }}
        open
      >
        <Toolbar>
          <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <img src={drawerLogo} alt="LOGO" style={{ filter: "grayscale(1)" }} />
          </div>
        </Toolbar>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "4vh" }}>
          <Avatar
            sx={{
              width: 64,
              height: 64,
              background: "#E5EAFF",
            }}
          >
            <ProfileIcon fill={"#6C86DB"} />
          </Avatar>
        </div>
        <div style={{ textAlign: "center" }}>
          <Typography sx={{ mb: 2, mt: 2 }} variant={"body1"}>
            {authCtx.email}
          </Typography>
        </div>
        <Menu />
      </Drawer>

      <Grid
        container
        justifyContent={"center"}
        rowSpacing={2}
        sx={{
          flexGrow: 1,
          py: 3,
          marginTop: "4vh",
          width: { md: `calc(100% - ${drawerWidth}px)` },
          background: "rgb(236,241,241)",
        }}
      >
        <Grid item xs={12} md={11} sx={{ padding: { xs: "8px", sm: "32px" }, position: "relative" }}>
          <Grid container>
            <Grid item xs={12} md={11}>
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  position: "relative",
                  justifyContent: "end",
                  alignItems: "center",
                  mb: 2,
                  p: 1,
                  gap: 1,
                }}
              >
                <IconButton
                  sx={{
                    boxShadow: "none",
                    backgroundColor: "#7897FE",
                    color: "#FFFFFF",
                    padding: "10px 20px",
                    borderRadius: "50px",
                    "&:hover": { background: "#7897FE" },
                    display: "flex",
                    alignItems: "center",
                    fontSize: "12px",
                  }}
                  onClick={testServiceClickHandler}
                >
                  <TestServiceIcon fill={"#FFFFFF"} />
                  <span style={{ marginLeft: 4 }}>Test Service</span>
                </IconButton>
                <IconButton
                  sx={{
                    boxShadow: "none",
                    color: "#5BC17E",
                    borderRadius: "50px",
                    "&:hover": { background: "white" },
                    display: "flex",
                    alignItems: "center",
                    fontSize: "12px",
                    background: "white",
                    padding: "10px 20px",
                  }}
                  onClick={addFundsClickHandler}
                >
                  <PlusIcon />
                  <span>Add Funds</span>
                </IconButton>
                <IconButton
                  sx={{
                    boxShadow: "none",
                    color: "#7897FE",
                    padding: "10px 20px",
                    borderRadius: "50px",
                    "&:hover": { background: "white" },
                    display: "flex",
                    alignItems: "center",
                    fontSize: "12px",
                    background: "white",
                  }}
                >
                  <a
                    style={{ textDecoration: "none", color: "#7897FE" }}
                    href={"https://app.metabypass.tech/docs.html"}
                    target={"_blank"}
                    rel={"noreferrer"}
                  >
                    <span>Documentation</span>
                  </a>
                </IconButton>

                <IconButton
                  sx={{
                    boxShadow: "none",
                    padding: "10px",
                    background: "white",
                    "&:hover": { background: "white" },
                  }}
                  onClick={(e) => {
                    navigate("/support/tickets/ticket-list");
                  }}
                >
                  <Badge badgeContent={appCtx.unreadTicket} variant={"dot"} color="error">
                    <AlertIcon />
                  </Badge>
                </IconButton>

                <IconButton
                  sx={{ background: "white", padding: "10px", "&:hover": { background: "white" } }}
                  onClick={logoutHandler}
                >
                  <LogOutIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>

          <Box position={"relative"}>
            {appCtx.error.showMessage ? (
              <Box sx={{ width: "100%" }}>
                <Collapse in={true}>
                  <Alert
                    severity={appCtx.error.messageType}
                    action={
                      <IconButton aria-label="close" color="inherit" size="small">
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}
                  >
                    <strong>{appCtx.error.messageText}</strong>
                  </Alert>
                </Collapse>
              </Box>
            ) : null}
            <ErrorBoundry>
              <Outlet />
              <BannerDialog />
            </ErrorBoundry>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Dashboard;
