

function QuestionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.791"
      height="37.507"
      viewBox="0 0 26.791 37.507"
    >
      <path
        fill="#7897fe"
        d="M8.037 37.358a2.676 2.676 0 00-2.679 2.679 2.679 2.679 0 01-5.358 0A8.039 8.039 0 018.037 32h8.121a10.634 10.634 0 013.851 20.545L14.735 54.6v1.515a2.679 2.679 0 11-5.358 0V54.6a5.346 5.346 0 013.416-4.99l5.274-2.051a5.278 5.278 0 00-1.909-10.2zm4.019 32.149a3.349 3.349 0 113.344-3.349 3.348 3.348 0 01-3.344 3.349z"
        transform="translate(0 -32)"
      ></path>
    </svg>
  );
}

export default QuestionIcon;
