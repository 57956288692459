function PlusIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 30 30">
      <g data-name="Group 274" transform="translate(-525 -313)">
        <g transform="translate(531.438 320.438)">
          <g transform="translate(2 2)">
            <path
              fill={props.fill ? props.fill : "#5bc17e"}
              d="M7.906 7.906h-5.25a.656.656 0 100 1.312h5.25v5.25a.656.656 0 101.312 0v-5.25h5.25a.656.656 0 100-1.312h-5.25v-5.25a.656.656 0 10-1.312 0z"
              transform="translate(-2 -2)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PlusIcon;
