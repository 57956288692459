import { Grid, TextField, Button, CircularProgress } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CaptchaHelpForm from "../form/captcha-doc";
const RecaptchaTestSendToken = (props) => {
  return (
    <Grid container>
      <Grid item xs={12} md={9} xl={7}>
        <TextField
          sx={{ my: 2 }}
          variant="outlined"
          label={"Enter Recaptcha Id"}
          value={props.token}
          onChange={props.onTokenChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} sm={'auto'}>
          <div style={{ display: "flex", justifyContent: "start", padding: "8px", marginBottom: 24 }}>
          {props.isLoading ? (
            <div style={{ display: "flex", justifyContent: "start", marginTop: "4px" }}>
              <CircularProgress />
            </div>
          ) : (
            <Button fullWidth sx={{maxWidth:'100%'}} variant="contained" onClick={props.onSendToken}>
              send recaptcha id
            </Button>
          )}
        </div>
          </Grid>
        </Grid>
        
      </Grid>
      <Grid item xs={12}>
        {props.recaptchaResponse ? (
          <div>
            {props.isLoading ? (
              <div style={{ display: "flex", justifyContent: "center", marginTop: "4px" }}>
                <CircularProgress />
              </div>
            ) : (
              <>
                <span style={{ fontSize: "1.1rem", fontWeight: 500 }}>Your Token : </span>
                <ContentCopyIcon onClick={props.onCopyClientSecret} style={{ cursor: "pointer" }} />
                <br />
                <span style={{ fontSize: "0.9rem", wordBreak: "break-all", textAlign: "justify" }}>
                  {props.recaptchaResponse}
                </span>
              </>
            )}
          </div>
        ) : null}
      </Grid>
      {/* <Grid item xs={12} md={11} justifyContent={"center"} alignItems={"center"} sx={{ mt: 4 }}>
        <CaptchaHelpForm title={"Get Result"} codeSample={props.codeSample || {}} />
      </Grid> */}
    </Grid>
  );
};

export default RecaptchaTestSendToken;
