export default function ApiDocIcon(props) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.315"
        height="15.315"
        viewBox="0 0 15.315 15.315"
      >
        <g transform="translate(.75 .75)">
          <path
            fill="none"
            fillRule="evenodd"
            stroke={props.fill}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M9.868 0h2.256a1.7 1.7 0 011.691 1.706v2.275a1.7 1.7 0 01-1.691 1.706H9.868a1.7 1.7 0 01-1.692-1.706V1.706A1.7 1.7 0 019.868 0zM1.692 0h2.255a1.7 1.7 0 011.692 1.706v2.275a1.7 1.7 0 01-1.692 1.707H1.692A1.7 1.7 0 010 3.981V1.706A1.7 1.7 0 011.692 0zm0 8.127h2.255a1.7 1.7 0 011.692 1.707v2.275a1.7 1.7 0 01-1.692 1.706H1.692A1.7 1.7 0 010 12.109V9.834a1.7 1.7 0 011.692-1.707zm8.176 0h2.256a1.7 1.7 0 011.691 1.707v2.275a1.7 1.7 0 01-1.691 1.706H9.868a1.7 1.7 0 01-1.692-1.706V9.834a1.7 1.7 0 011.692-1.707z"
            data-name="Path 12"
          ></path>
        </g>
      </svg>
    );
  }
  
