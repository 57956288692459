import { useState, useCallback, useContext } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import axios from "axios";
import AuthContext from "../store/auth-context";
import SnackContext from "../store/snack-context";

const useHttp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const authCtx = useContext(AuthContext);
  const snackCtx = useContext(SnackContext);
  const sendRequest = useCallback(
    (requestConfig, applyData, access_token = null) => {
      setIsLoading(true);
      const options = {
        ...requestConfig,
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${access_token || authCtx.token}` },
        // headers config
      };
      return axios(options)
        .then((response) => {
          setIsLoading(false);
          //   console.log(response);
          if (response.data.status_code === 401) {
            authCtx.logout(false);
            snackCtx.handleOpen("Please Log in");
            navigate("/login",{state:{from:location}});
          } else if (response.data.status_code === 403) {
            authCtx.logout(false);
            snackCtx.handleOpen("Please Log in");
            navigate("/login",{state:{from:location}});
          } else if (response.data.status_code === 200 || response.data.status_code === 303) {
            applyData(response);
          } else if (response.data.status_code === 500) {
            snackCtx.handleOpen("Something went wrong !!");
          } else {
            snackCtx.handleOpen(response.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setError(true);
          console.log(error)
          if (error?.response?.status === 401) {
            snackCtx.handleOpen("Please Log in");
            authCtx.logout(false);
            navigate("/login",{state:{from:location}});
          } else {
            
            snackCtx.handleOpen("Something went wrong !");
          }
          //   console.log(error.response);
        });
    },
    [authCtx.token, navigate]
  );

  return {
    isLoading,
    error,
    sendRequest,
  };
};

export default useHttp;
