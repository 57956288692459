import {useState,useEffect} from 'react'
import { Grid, Card,Pagination,Divider } from "@mui/material"
import useHttp from '../../hook/useHttp';
import LogTable from "./LogTable"
import {getRecentActivityUrl} from '../../extentions/apiUrls'
import {CircularProgress,Box} from '@mui/material';
import Title from '../layout/Title';
const Log = ()=>{

    const [data,setData] = useState(null)
    const [page,setPage] = useState(1)
    const [totalPage,setTotalPage] = useState(1)
    const{sendRequest:sendLoginHistoryRequest} = useHttp()

    const pageChangeHandler = (e,v)=>{
        
        setPage(v)
        fetchData(v)

    }
    const sendLoginHistoryRequestHandler = (res)=>{
        
        if(res.data.status_code === 200)
        {
            setData(res?.data?.data?.data)
            setPage(res?.data?.data?.current_page)
            setTotalPage(res?.data?.data?.last_page)
        }
    }
    
    const fetchData = (page=1)=>{
        const options={
            method:"GET",
            url:getRecentActivityUrl,
            params:{page:page}
        }
        sendLoginHistoryRequest(options,sendLoginHistoryRequestHandler)
    }
    
    useEffect(()=>{

        
        fetchData()

    },[])
    return(
        <Grid container>
            <Grid item xs={12} md={11}>
                
                    {
                        data === null
                            ?
                                <Box sx={{display:'flex',justifyContent:'center',mt:4}}>
                                    <CircularProgress/>
                                </Box>
                            :
                                <Card type="card">
                                    <Title>
                                        Login History
                                    </Title>
                                    <Divider sx={{mb:3}}/>
                                    <LogTable logs={data}/>
                                    <Box sx={{mt:2,display:'flex',justifyContent:"center"}}>
                                        <Pagination page={page * 1} onChange={pageChangeHandler} count={totalPage} shape={'rounded'} color="primary"/>
                                    </Box>
                                </Card>
                    }
                    
                
            </Grid>
        </Grid>
        
    )

}

export default Log