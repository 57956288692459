import {Link} from 'react-router-dom';
import Grid from '@mui/material/Grid';


import {pageNotFound as pageNotFoundImg} from '../extentions/imagesUrl';
const PageNotFound = ()=>
{

    return(
        <Grid container justifyContent="center">
            <Grid item xs={12} md={5} sx={{marginTop:'20vh'}}>
                    <img style={{width:'100%'}} src={pageNotFoundImg} alt={'captcha bypass'}/>
                    <div style={{display:'flex',justifyContent:'center',marginTop:'4vh'}}>
                        <Link to={'/'} style={{textDecoration:'none'}} >
                            <span 
                                style={{
                                    fontSize:'30px',fontStyle:'oblique',
                                    color:'#0e2a47',cursor:'pointer',
                                    borderStyle:"groove",padding:'4px',
                                    borderTopWidth:'thin',borderBottomWidth:'thick',
                                    borderRadius:'20px'
                                }}
                            >
                                Return To Home Page
                            </span>
                        </Link>
                    </div>
            </Grid>
        </Grid>
    )
}

export default PageNotFound;