import { Box, Typography, TextField, Grid, FormControl, MenuItem, Button, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import FileUploadIcon from "../icons/FileUploadIcon";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";

const Bullet = styled("div")((props) => ({
  "&::before": {
    color: props.color ? props.color : "#7F8081",
    content: "'\\2022'",
    fontSize: "24px",

    fontWeight: "bold",
  },
}));

const Values = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: theme.palette.primary.text,
}));

const CreateTicketForm = (props) => {
  return (
    <Grid container sx={{ p: 1 }} spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <TextField
            select
            label="Department"
            size="small"
            fullWidth
            open={props.openDepartment}
            onClose={props.handleDepartmentClose}
            value={props.department || ""}
            error={props.formError.department.error}
            onChange={props.handleDepartmentChange}
            sx={{ mt: 1 }}
          >
            
            {props?.departments?.map((item, idx) => {
              return (
                <MenuItem key={idx + 1} value={item?.operator_name}>
                  {item?.operator_name}
                </MenuItem>
              );
            })}
          </TextField>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <TextField
            select
            label="Category"
            size="small"
            fullWidth
            open={props.openCategory}
            onClose={props.handleCategoryClose}
            value={props.category || ""}
            variant={"outlined"}
            onChange={props.handleCategoryChange}
            error={props.formError.category.error}
            sx={{
              mt: 1,
              "& .MuiOutlinedInput-notchedOutline": { borderColor: props.formError.category.error ? "red" : "#c4c4c4" },
            }}
          >
            
            {props?.categories?.map((item, idx) => {
              return (
                <MenuItem key={idx + 1} value={item.name}>
                  {item.name}
                </MenuItem>
              );
            })}
          </TextField>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl fullWidth sx={{ "& .MuiFormControl-root": { margin: "0 !important" } }}>
          <TextField
            select
            label="Priority"
            size="small"
            fullWidth
            open={props.openPriority}
            onClose={props.handlePriorityClose}
            value={props.priority || ""}
            error={props.formError.priority.error}
            onChange={props.handlePriorityChange}
            sx={{ mt: 1 }}
          >
            <MenuItem key={0} value={0}></MenuItem>
            {props?.priorities?.map((item, idx) => {
              return (
                <MenuItem key={idx + 1} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </TextField>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <TextField
            label="Subject"
            variant="outlined"
            value={props.subject}
            onChange={props.handleSubjectChange}
            size="small"
            fullWidth
            error={props.formError.subject.error}
            helperText={props.formError.subject.error ? props.formError.subject.text : null}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <TextField
          multiline
          rows={5}
          fullWidth
          value={props.message}
          onChange={props.handleMessageChange}
          placeholder="Enter your Message"
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1} sx={{ alignItems: "center" }}>
          <Grid item xs={12} sm={'auto'}>
            {props.sendTicketLoading ? (
              <CircularProgress />
            ) : (
              <Button
                onClick={props.sendTicket}
                endIcon={<SendOutlinedIcon />}
                sx={{maxWidth:'100%' }}
                variant="contained"
                fullWidth
              >
                Send
              </Button>
            )}
            </Grid>
            <Grid item xs={12} sm={'auto'}>
            <Button
              size={"small"}
              endIcon={<FileUploadIcon />}
              variant="contained"
              component="label"
              sx={{
                background: "white",
                color: "#787777",
                maxWidth:'100%',
                border: 1,
                borderColor: "#9E9E9E",
                "&:hover": { background: "white" },
              }}
              fullWidth
            >
              <input type="file" hidden onChange={props.fileUpload} />
              Choose File
            </Button>
          </Grid>
          <Grid item xs={12} sm={'auto'}>
            <Typography component={"div"} variant="body2" color="primary.text">
              Accepted File Format : JPEG,JPG,PNG,PDF
            </Typography>
            <Typography component={"div"} variant="body2" color="primary.text">
              Size Limit : 8MB
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {props?.file ? (
              <Values>
                <Bullet color={"#7897FE"} />
                <Typography sx={{ ml: 1 }} variant="body2" component={"div"}>
                  {" "}
                  {props?.file?.name}{" "}
                </Typography>
                <DeleteOutlinedIcon onClick={props.fileUpload} sx={{ cursor: "pointer" }} />
              </Values>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateTicketForm;
