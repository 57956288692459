import CreateApplicationForm from "./CreateApplicationForm";
import useHttp from "../../hook/useHttp";
import { application as createApplicationUrl } from "../../extentions/apiUrls";
import { CircularProgress, Box } from "@mui/material";
const CreateApplication = (props) => {
  const { isLoading, sendRequest: createApplicationRequest } = useHttp();

  const createApplicationHandler = () => {
    const createApplicationResponseHandler = (res) => {
      props.onCreateApplication(res);
    };
    const options = {
      method: "POST",
      url: createApplicationUrl,
    };
    createApplicationRequest(options, createApplicationResponseHandler);
  };

  return isLoading ? (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
      <CircularProgress />
    </Box>
  ) : (
    <CreateApplicationForm onCreateAppllication={createApplicationHandler} />
  );
};

export default CreateApplication;
