export default function PaymentHistoryIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.351"
      height="14.29"
      viewBox="0 0 15.351 14.29"
    >
      <g
        fill="none"
        stroke={props.fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        transform="translate(.75 .75)"
      >
        <path
          fillRule="evenodd"
          d="M13.851 8.248h-2.93a1.95 1.95 0 110-3.9h2.93"
          data-name="Path 8"
        ></path>
        <path
          d="M0.226 0L0 0"
          data-name="Line 9"
          transform="translate(11.027 6.255)"
        ></path>
        <path
          fillRule="evenodd"
          d="M3.8 0h6.255a3.8 3.8 0 013.8 3.8v5.192a3.8 3.8 0 01-3.8 3.8H3.8a3.8 3.8 0 01-3.8-3.8V3.8A3.8 3.8 0 013.8 0z"
          data-name="Path 9"
        ></path>
        <path
          d="M0 0L3.907 0"
          data-name="Line 10"
          transform="translate(3.283 3.284)"
        ></path>
      </g>
    </svg>
  );
}


