import React from "react";

function ChartIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.433" height="13.433" viewBox="0 0 15.433 15.433">
      <path
        fill="none"
        stroke="#7897fe"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M0 0L0 4.779"
        data-name="Line 11"
        transform="translate(4.492 6.464)"
      ></path>
      <path
        fill="none"
        stroke="#7897fe"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M0 0L0 7.066"
        data-name="Line 12"
        transform="translate(7.743 4.177)"
      ></path>
      <path
        fill="none"
        stroke="#7897fe"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M0 0L0 2.254"
        data-name="Line 13"
        transform="translate(10.941 8.989)"
      ></path>
      <path
        fill="none"
        fillRule="evenodd"
        stroke="#7897fe"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10.981.75H4.45a3.589 3.589 0 00-3.7 3.891v6.151a3.586 3.586 0 003.7 3.891h6.528a3.586 3.586 0 003.7-3.891V4.641A3.586 3.586 0 0010.981.75z"
        data-name="Path 11"
      ></path>
    </svg>
  );
}

export default ChartIcon;
