import { useState, useEffect } from "react";
import useHttp from "../../hook/useHttp";
import { usageHistory, getUsageHistoryById } from "../../extentions/apiUrls";
import DialogComponent from "../layout/DialogComponent";
import HistoryTable from "./HistoryTable";
import { Box, CircularProgress} from "@mui/material";
import RequestDetailForm from "./form";

const History = () => {
  const [data, setData] = useState(undefined);
  const { isLoading, sendRequest: getHistoryRequest } = useHttp();
  const { isLoading: getDetailLoading, sendRequest } = useHttp();
  const [totalPage, setTotalPage] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContet] = useState(undefined);
  const [selectedId, setSelectedId] = useState(null);
  const [page, setPage] = useState(1);

  const handlePageChange = (event, value) => {
    setPage(value);
    fetchData(value);
  };

  const getHistoryRequestHandler = (res) => {
    if (res.data.status_code === 200) {
      setData(res.data.data.data);
      setTotalPage(res.data.data.last_page);
    }
  };
  const fetchData = (page = 1) => {
    const options = {
      method: "get",
      url: usageHistory,
      params: { page: page },
    };
    getHistoryRequest(options, getHistoryRequestHandler);
  };

  useEffect(() => {
    fetchData();
  }, [getHistoryRequest]);

  const getRequestDetailHandler = (res) => {
    const content = (
      <RequestDetailForm inputs={res.data.data.inputs} response={res.data.data.response} onClose={handleCloseDialog} />
    );
    setOpenDialog(true);
    setDialogContet(content);
  };

  const getRequseDetail = (id) => {
    setSelectedId(id);
    const options = {
      method: "get",
      url: getUsageHistoryById.replace("{request_id}", id),
    };
    sendRequest(options, getRequestDetailHandler);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return data === undefined || isLoading ? (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
      <CircularProgress />
    </Box>
  ) : (
    <>
      <HistoryTable
        onDetail={getRequseDetail}
        totalPage={totalPage}
        page={page}
        onPageChange={handlePageChange}
        loading={isLoading}
        detailLoading={getDetailLoading}
        selectedId={selectedId}
        data={data}
      />
      <DialogComponent open={openDialog} handleClose={handleCloseDialog} content={dialogContent} />
    </>
  );
};

export default History;
