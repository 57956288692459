import { useState, useEffect, useRef } from "react";
import useHttp from "../../hook/useHttp";
import { useParams } from "react-router-dom";
import { transactionDetailUrl } from "../../extentions/apiUrls";
import InoviceDetailToPrint from "./InoviceDetailToPrint";
import { useReactToPrint } from "react-to-print";
import { CircularProgress } from "@mui/material";
const Invoice = (props) => {
  const componentRef = useRef();
  const [invoice, setInvoice] = useState(null);
  const { isLoading, sendRequest: getInvoiceRequest } = useHttp();
  const params = useParams();

  const getInvoiceRequestHandler = (res) => {
    setInvoice(res.data.data);
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    const options = {
      method: "GET",
      url: transactionDetailUrl.replace(":id", params.id),
    };
    getInvoiceRequest(options, getInvoiceRequestHandler);
  }, [getInvoiceRequest,params.id]);
  return isLoading ? (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {" "}
      <CircularProgress />{" "}
    </div>
  ) : (
    <InoviceDetailToPrint invoice={invoice} ref={componentRef} printHandler={handlePrint} />
  );
};
export default Invoice;
