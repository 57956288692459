import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Box, CircularProgress, Divider } from '@mui/material';
import { BASE_URL_, getPaymentMethod, plans } from '../../extentions/apiUrls';
import useHttp from '../../hook/useHttp';
import Title from '../layout/Title';
import PaymentForm from './form';
import SnackContext from '../../store/snack-context';
import DialogComponent from '../layout/DialogComponent';
import DialogContent from './DialogContent';

const PlanList = () => {
  const navigate = useNavigate();
  const snackCtx = useContext(SnackContext);
  const [planList, setPlanList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(undefined);
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [planLoading, setPlanLoading] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(undefined);
  const [redirectLoading, setRedirectLoading] = useState(false);
  const { isLoading: paymentListLoading, sendRequest: paymentListRequest } =
    useHttp();
  const { isLoading: planListLoading, sendRequest: planListRequest } =
    useHttp();
  const { sendRequest } = useHttp();
  useEffect(() => {
    const planListRequestHandler = (res) => {
      const plans = res.data.data.data;
      plans.sort(function (planA, planB) {
        return planA.price - planB.price;
      });
      const plan = plans?.find((element) => {
        if (element.price === 5) {
          return true;
        }
      });
      setPlanList(plans);
      setSelectedPlan(plan);
    };
    const paymentListRequestHandler = (res) => {
      setPaymentMethodList(res.data.data);
      const list = res.data.data;
      const find = list.find((item) => item?.attrs?.default);
      setSelectedPaymentMethod(find);
    };
    const paymentOption = {
      method: 'GET',
      url: getPaymentMethod,
    };
    const planOption = {
      method: 'GET',
      url: plans,
    };

    planListRequest(planOption, planListRequestHandler);
    paymentListRequest(paymentOption, paymentListRequestHandler);
  }, [paymentListRequest, planListRequest]);

  const selectPlanHandler = (id) => {
    const plan = planList?.find((element) => {
      if (element.id === id) {
        return true;
      }
    });

    setSelectedPlan(plan);
  };

  const paymentMethodHandler = (item) => {
    setPlanLoading(true);
    setSelectedPaymentMethod(item);
    setTimeout(() => setPlanLoading(false), 500);
  };

  const checkoutHandler = (res) => {
    window.location.href = res.data.data.pay_url;
  };
  const openDialogHandler = () => {
    setOpenDialog(true);
  };
  const confirmHandler = () => {
    setOpenDialog(false);
    buyHandler();
  };
  const closeHandler = () => {
    setOpenDialog(false);
  };
  const buyHandler = () => {
    if (selectedPaymentMethod) {
      if (selectedPaymentMethod?.attrs?.is_crypto) {
        navigate('/add-funds/crypto', {
          state: {
            paymentMethod: selectedPaymentMethod,
            price: selectedPlan.price,
          },
        });
      } else {
        const options = {
          method: 'POST',
          url: BASE_URL_ + selectedPaymentMethod.attrs.url,
          data: { product_id: selectedPlan.id, product_type: 'plan' },
        };
        setRedirectLoading(true);
        sendRequest(options, checkoutHandler);
      }
    } else {
      snackCtx.handleOpen('Please select payment method');
    }
  };

  return (planListLoading && planList.length === 0) || redirectLoading ? (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
      <CircularProgress />
    </Box>
  ) : (
    <Grid container>
      <Grid item xs={12} md={11}>
        <Title>Add Funds</Title>
        <Divider sx={{ mb: 3 }} />
      </Grid>
      <Grid item xs={12} md={11}>
        <PaymentForm
          plans={planList}
          onPlanSelect={selectPlanHandler}
          selectedPlan={selectedPlan}
          paymentMethod={selectedPaymentMethod}
          onPaymentSelect={paymentMethodHandler}
          onBuy={openDialogHandler}
          paymentListLoading={paymentListLoading}
          planListLoading={planLoading}
          // settings={settings}
          // onCryptoSelect={cryptoOnChangeHandler}
          // crypto={crypto}
          // activeCrypto={activeCrypto}
          // isLoading={isLoading}
          // walletAddress={walletAddress}
          // cryptoAmount={cryptoAmount}
          // exchangeLoading={exchangeLoading}
          paymentMethodList={paymentMethodList}
        />
      </Grid>
      <DialogComponent
        open={openDialog}
        content={
          <DialogContent onConfirm={confirmHandler} onCancel={closeHandler} />
        }
      />
    </Grid>
  );
};

export default PlanList;
