import * as React from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import Typography from '@mui/material/Typography';
import ModalContext from '../../store/modal-context';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 800,
  overflowY:'auto',
  maxHeight:'calc(100% - 64px)',
  width:'auto',
  height:'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 1,
  border:'none',
  borderRadius:'16px'
};

const ModalComponent =(props)=> {
  
  const modalCtx =  React.useContext(ModalContext);
  
  return (
    <div>
      <Modal
        open={modalCtx.isOpen}
        onClose={modalCtx.modalHandler}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
         
          
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            {modalCtx.content.title}
          </Typography>
          <div>
            {modalCtx.content.body}
          </div>
            
          
        </Box>
      </Modal>
    </div>
  );
}

export default ModalComponent