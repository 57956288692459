import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { noContent as noContentImg } from '../../extentions/imagesUrl';
import CirCularProgressComponent from '../layout/CirCularProgressComponent';
import {activeCurrency} from '../../extentions/appConfig'
const InvoiceTable = (props)=>{

    const createTableOrderRows = ()=>{
        return(
        <TableBody>
            {
                props.orders.map((order,idx)=>{
                    return(
                        <TableRow key={idx} sx={{cursor:'pointer'}} >
                            <TableCell align="center">{order.item}</TableCell>
                            <TableCell align="center">{order.plan_price } {activeCurrency}</TableCell>
                            <TableCell align="center">{order.discount}</TableCell>
                            <TableCell align="center">{order.total} {activeCurrency}</TableCell>
                        </TableRow>
                    )
                })
            }
        </TableBody>)
    }
    const createTableOrder = ()=>{

        if(props.orders !== undefined)
            return(
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Item</TableCell>
                                <TableCell align="center">Price per Unit</TableCell>
                                <TableCell align="center">Line Discount</TableCell>
                                <TableCell align="center">Total</TableCell>
                            </TableRow>
                        </TableHead>
                        {props.isLoading ?  null : createTableOrderRows()}
                    </Table>
                    {
                     props.isLoading 
                      ? 
                        <CirCularProgressComponent/>
                      : 
                        props.orders.length === 0 
                        ?
                            <div style={{display:'flex',justifyContent:'center'}}>
                                <img style={{height:'auto',width:'300px'}} src={noContentImg} alt={"captcha bypass"} />
                            </div>
                            :
                            null
                      }
                </TableContainer>
                    

            )

    }
    return(
        createTableOrder()
    )

}

export default InvoiceTable