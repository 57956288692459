import { useRef } from "react";
import {
  Grid,
  Typography,
  StepContent,
  StepLabel,
  Step,
  Stepper,
  Card,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import ProductSection from "./section-one";
import PaymentSection from "./section-two";
import CryptoForm from "./section-three";
import { calculateBoost } from "../../../utils/helper";
function PaymentForm({
  onCryptoSelect,
  crypto,
  settings,
  plans,
  onPlanSelect,
  selectedPlan,
  paymentMethod,
  onPaymentSelect,
  onBuy,
  isLoading,
  paymentMethodList,
  paymentListLoading,
  planListLoading
}) {
  const buttonRef = useRef(null);
  const stepStyle = {
    "& .MuiStepLabel-label": {
      fontSize: "1rem",
      fontWeight: "900",
      color: "#202020",
    },
    "& .MuiStepIcon-text": {
      fill: "#7897FE",
      fontSize: "1rem",
    },
    "&.MuiStepIcon-root": {
      color: "#E5EAFF",
      fontSize: "1.5rem",
    },
    "& .Mui-active": {
      "&.MuiStepIcon-root": {
        color: "#E5EAFF",
        fontSize: "1.5rem",
      },
    },
    "& .Mui-completed": {
      "&.MuiStepIcon-root": {
        color: "#E5EAFF",
        fontSize: "1.5rem",
      },
      "& .MuiStepConnector-line": {
        borderColor: "secondary.main",
      },
    },
    "& .Mui-disabled .MuiStepIcon-root": { color: "#E5EAFF" },
  };

  const selectPaymentMethodHandler = (item)=>{
    buttonRef?.current?.scrollIntoView({ behavior: "smooth" });
    onPaymentSelect(item)
  }
  
  const selectPlanHandler = (id) => {
    
    onPlanSelect(id);
  };
  return (
    <Card type="card" sx={{ padding: "1.4rem" }}>
      <Grid container>
        <Grid item xs={12}>
          <Stepper sx={stepStyle} activeStep={0} orientation="vertical">
            <Step active={true}>
              <StepLabel>Select Payment Method</StepLabel>
              {paymentListLoading ? null : (
                <StepContent>
                  <PaymentSection
                    paymentMethodList={paymentMethodList}
                    settings={settings}
                    onSelectPayment={selectPaymentMethodHandler}
                    paymentMethod={paymentMethod}
                  />
                </StepContent>
              )}
            </Step>
            <Step
              sx={{ "& .MuiStepIcon-active": { color: "red" } }}
              active={true}
            >
              <StepLabel>Select Product</StepLabel>
                  
              {planListLoading ? <Box sx={{display:'flex',justifyContent:'center',mt:2}}> <CircularProgress/> </Box> :<StepContent>
                <div  ></div>
                <ProductSection
                  paymentMethod={paymentMethod}
                  plans={plans}
                  onSelectPlan={selectPlanHandler}
                  selectedPlan={selectedPlan}
                  buttonRef={buttonRef}
                />
                
                <Grid
                  container
                  spacing={1}
                  item
                  xs={12}
                  sx={{
                    mt: 3,
                    flexDirection: { xs: "column-reverse", sm: "row" },
                    alignItems: { xs: "start", sm: "center" },
                    position: "relative",
                  }}
                  justifyContent="center"
                >
                  <Grid item xs={12} sm={"auto"} sx={{ width: "100%" }}>
                    {isLoading ? (
                      <Box>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Button
                      
                        fullWidth
                        disabled={paymentMethod === null}
                        onClick={onBuy}
                        variant="contained"
                        sx={{
                          backgroundColor: "#5BC17E",
                          height: 45,
                          fontWeight: "bold",
                          fontSize: "0.872rem",
                          ":hover": {
                            backgroundColor: "#089839",
                            boxShadow: "none",
                          },
                          maxWidth: "100%",
                        }}
                      >
                        Add Funds
                      </Button>
                    )}
                  </Grid>

                  <Grid
                    item
                    container
                    xs
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        borderBottom: "1px solid #EBEBEB",
                        left: 8,
                        right: 10,
                        display:{xs:'none',sm:'block'}
                      }}
                    ></Box>
                    <Grid
                    item
                    xs={12}
                    sm={"auto"}
                    sx={{
                      position: { xs: "relative", sm: "absolute" },
                      right: "0",
                      bottom: 10,
                      mb: { xs: 0.5, sm: 0 },
                    }}
                  >
                    <Typography
                      color="#787777"
                      fontSize={23}
                      sx={{ p: { xs: 0, md: 1 } }}
                    >
                      Total Price:{" "}
                      <span style={{ color: "#5BC17E", fontWeight: "bold" }}>
                        {selectedPlan?.price}$
                      </span>
                    </Typography>
                  </Grid>
                     {paymentMethod?.attrs?.boost > 0 && (
                    <Grid
                    item
                    xs={12}
                    sm={"auto"}
                      sx={{
                        position: { xs: "relative", sm: "absolute" },
                        right: 0,
                        top: 10,
                        mb: { xs: 2, sm: 0 },
                      }}
                    >
                      <Typography
                        color="#787777"
                        variant="h5"
                      >
                        Applied Price:{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            color: "#5BC17E",
                            marginLeft: 1,
                          }}
                        >
                          {String((
                            selectedPlan?.price *
                            calculateBoost(paymentMethod?.attrs?.boost)
                          ).toFixed(2)).replace('.00','')}
                          $
                        </span>
                      </Typography>
                    </Grid>
                  )}
                  
                  </Grid>
                 
                  
                </Grid>
              </StepContent>}
            </Step>
          </Stepper>
        </Grid>
      </Grid>
    </Card>
  );
}

export default PaymentForm;
