
import RegistrationForm from '../components/Form/RegistrationForm';
import Grid from '@mui/material/Grid';

const Registraion = (props)=>{
    


    return(
        <Grid item xs={12}>
            <RegistrationForm registerHandler={props.registerHandler} loading={props.loading} onResendEmailClick={props.onResendEmailClick}/>
        </Grid>
        
    )
}

export default Registraion;