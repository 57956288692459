import React from 'react';

const SnackContext = React.createContext({

    snackMessage:'',
    snackType:'',
    showSnack:false,
    handleClose:()=>{},
    handleOpen:(msg,type)=>{}
})

export default SnackContext;