import {useState} from 'react'
import { CircularProgress } from "@material-ui/core";
import { Grid, Typography, Divider, Box, Card, Button, IconButton } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router";
import SnackContext from "../../../../store/snack-context";
import { useContext } from "react";
import { Visibility,VisibilityOff } from "@mui/icons-material";

const ApplicationForm = ({ application, onDelete, loading }) => {
  const [showPassword,setShowPassword] = useState(false)
  const matches = useMediaQuery("(min-width:600px)");
  const snackCtx = useContext(SnackContext);
  const navigate = useNavigate();
  return (
    <Card type="card" sx={{ padding: "1.4rem" }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItem: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography component={"span"} color="#202020" variant={"h2"} fontWeight={600}>
              Application Details
            </Typography>
            {loading ? (
              <CircularProgress sx={{ color: "#f56666" }} />
            ) : (
              <Box
                onClick={onDelete}
                sx={{
                  boxSizing: "border-box",
                  backgroundColor: "#FFFFFF",
                  borderRadius: 2,
                  color: "#f56666",
                  height: "33px",
                  border: "solid 1px #f56666",
                  padding: "0.2rem 0.9rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "0.8rem",
                  width: { xs: "100%", sm: "90px" },
                  cursor: "pointer",
                  mt: { xs: 2, sm: 0 },
                }}
              >
                <span>Remove</span>
              </Box>
            )}
          </Box>

          <Divider sx={{ mb: 2, mt: 1 }} />
        </Grid>
        <Grid item xs={12} lg={6} container>
          <Grid item xs="auto" sx={{ mr: 1 }}>
            <Typography component={"span"} variant="h6" color={"#787777"} fontWeight={"600"}>
              Name
            </Typography>
          </Grid>

          {matches ? (
            <Grid item xs sx={{ position: "relative" }}>
              <Box
                component="span"
                sx={{
                  borderBottom: "1px dashed #787777",
                  display: "block",
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: 8,
                }}
              ></Box>
            </Grid>
          ) : null}
          <Grid item xs sm="auto" sx={{ ml: 1 }}>
            <Typography component={"span"} variant="h6" color={"#787777"} fontWeight={400}>
              {application.client_name}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6} container>
          <Grid item xs="auto" sx={{ mr: 1 }}>
            <Typography component={"span"} variant="h6" color={"#787777"} fontWeight={"600"}>
              Client Id
            </Typography>
          </Grid>
          {matches ? (
            <Grid item xs sx={{ position: "relative" }}>
              <Box
                component="span"
                sx={{
                  borderBottom: "1px dashed #787777",
                  display: "block",
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: 8,
                }}
              ></Box>
            </Grid>
          ) : null}
          <Grid item xs sm="auto" sx={{ ml: 1 }}>
            <Typography component={"span"} variant="h6" color={"#787777"} fontWeight={400}>
              {application.client_id}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6} container alignItems="center">
          <Grid item xs={"auto"} sx={{ mr: 1 }}>
            <Typography component={"span"} variant="h6" color={"#787777"} fontWeight={"600"}>
              Username
            </Typography>
          </Grid>
          {matches ? (
            <Grid item xs sx={{ position: "relative" }}>
              <Box
                component="span"
                sx={{
                  borderBottom: "1px dashed #787777",
                  display: "block",
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              ></Box>
            </Grid>
          ) : null}
          <Grid item xs sm="auto" sx={{ ml: 1 }}>
            <Typography variant="h6" color={"#787777"} fontWeight={400} style={{ wordWrap: "anywhere" }}>
              {application?.username}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6} container alignItems="center">
          <Grid item xs={"auto"} sx={{ mr: 1 }}>
            <Typography component={"span"} variant="h6" color={"#787777"} fontWeight={"600"}>
              Password
            </Typography>
          </Grid>
          {matches ? (
            <Grid item xs sx={{ position: "relative" }}>
              <Box
                component="span"
                sx={{
                  borderBottom: "1px dashed #787777",
                  display: "block",
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              ></Box>
            </Grid>
          ) : null}
          <Grid item xs sm={"auto"} sx={{ ml: 1,display:'flex',alignItems:'center' }}>
            <Typography variant="h6" color={"#787777"} fontWeight={400} style={{ wordWrap: "anywhere" }}>
              {
                showPassword ? application?.password : '****...'
              }
              
            </Typography>
            <IconButton onClick={()=>setShowPassword((prevState)=>{return !prevState })}>
              {
                showPassword ? <VisibilityOff/> : <Visibility/>
              }  
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs="auto" sx={{ mr: 1 }}>
            <Typography component={"span"} variant="h6" color={"#787777"} fontWeight={"600"}>
              Secret key
            </Typography>
          </Grid>
          {matches ? (
            <Grid item xs sx={{ position: "relative" }}>
              <Box
                component="span"
                sx={{
                  borderBottom: "1px dashed #787777",
                  display: "block",
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: 8,
                }}
              ></Box>
            </Grid>
          ) : null}
          <Grid item xs sm="auto" sx={{ ml: 1 }}>
            <Typography
              
              component={"span"}
              color={"#787777"}
              fontWeight={400}
              style={{ wordWrap: "anywhere" }}
            >
              {application.client_secret}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sx={{ my: 3, gap: { xs: 2, sm: 3 }, justifyContent: { xs: "flex-start", md: "flex-end" } }}
        >
          <Grid item xs={12} sm="auto">
            <Button
              component={"a"}
              variant="outlined"
              fullWidth
              sx={{
                color: "#7897FE",
                border: "1px solid #7897FE",
                padding: "0.8rem 1.8rem",
                borderRadius: 2,
                cursor: "pointer",
                maxWidth: "100%",
              }}
              target="_blank"
              href="https://app.metabypass.tech/docs.html"
            >
              Docs
            </Button>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Button
              variant="outlined"
              fullWidth
              onClick={() => {
                navigator.clipboard.writeText(
                  JSON.stringify({
                    grant_type: "password",
                    client_id: application.client_id,
                    client_secret: application.client_secret,
                    client_name: application.client_name,
                    username: application.username,
                    password: application.password,
                  })
                );
                snackCtx.handleOpen("Copied to clipboard", "");
              }}
              component={"span"}
              sx={{
                color: "#7897FE",
                border: "1px solid #7897FE",
                padding: "0.8rem 1.8rem",
                borderRadius: 2,
                cursor: "pointer",
                maxWidth: "100%",
              }}
            >
              Copy Json
            </Button>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Button
              onClick={() => navigate("/application")}
              component={"span"}
              variant={"contained"}
              fullWidth
              sx={{
                color: "#FFFFFF",
                border: "1px solid #7897FE",
                background: "#7897FE",
                padding: "0.8rem 1.8rem",
                borderRadius: 2,
                cursor: "pointer",
                maxWidth: "100%",
              }}
            >
              Test & Use service
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ApplicationForm;
