import { useEffect,useState,useContext } from 'react';
import {useLocation,useNavigate} from 'react-router-dom';
import ModalContext from '../../store/modal-context';
import PurchaseForm from "./PurchaseForm";
import Terms from '../terms/Terms';


const Purchase= ()=>{
    const location = useLocation()

    const [plan,setPlan] = useState()
    const [agree,setAgree] = useState(false)
    const [url,setUrl] = useState()
    const modalCtx = useContext(ModalContext)
    const navigate = useNavigate()
    const termsClickHandler=(e)=>{
        
        e.preventDefault()
        e.stopPropagation()
        const content={body:<Terms/>}
        modalCtx.modalHandler(content)
    }
    const cancelHandler = ()=>{
        navigate('/order-plan',{replace:true})
    }
    
    const handleChange = (e)=>{
        setAgree(e.target.checked)
    }
    useEffect(()=>{
        if(location.state.url)
        {
            setUrl(location.state.url)
            setPlan(location.state.plan)
        }
    },[])
    return(
        <PurchaseForm {...plan} url={url} agreement={agree} handleChange={handleChange} onCancel={cancelHandler} onClickTerms ={termsClickHandler}/>
    )
}

export default Purchase