const root = 'https://app.metabypass.tech/';
export const BASE_URL = root + 'CaptchaSolver/';
export const BASE_URL_ = root + 'CaptchaSolver';
export const rootUrl = root;
export const loginUrl = BASE_URL + 'api/v1/auth/email/signin';
export const registerUrl = BASE_URL + 'api/v1/auth/email/signup';
export const recoveryUrl = BASE_URL + 'auth/recovery';

export const homeUrl = BASE_URL + '/home';
export const getApi = BASE_URL + 'api/v1/management/apis';
export const getApiById = BASE_URL + 'api/v1/management/apis/';
export const createApi = BASE_URL + 'api/v1/owner/apis';
export const updateApiById = BASE_URL + 'api/v1/owner/apis/';
export const deleteApi = BASE_URL + 'api/v1/owner/apis/';

export const createPlanUrl = BASE_URL + 'api/v1/owner/plans';
export const getPlanList = BASE_URL + 'api/v1/management/plans';
export const getPlanDetail = BASE_URL + 'api/v1/management/plans/';
export const updatePlan = BASE_URL + 'api/v1/owner/plans/';
export const deletePlan = BASE_URL + 'api/v1/owner/plans/';

export const userList = BASE_URL + 'api/v1/management/users';
export const orders = BASE_URL + 'api/v1/management/orders';
export const userInfo = BASE_URL + 'api/v1/management/users/profile';
export const userOrders = BASE_URL + 'api/v1/management/users/:id/orders';
export const updateUser = BASE_URL + 'api/v1/owner/users/';

export const getRoles = BASE_URL + 'api/v1/management/roles';
export const searchUser = BASE_URL + 'api/v1/management/users/search';
export const application = BASE_URL + 'api/v1/applications';
export const statistics = BASE_URL + 'api/v1/options';
export const testService = BASE_URL + 'api/v1/services/captchaSolver';
export const recaptchaTestService =
  BASE_URL + 'api/v1/services/bypassReCaptcha';
export const recaptchaGetResult = BASE_URL + 'api/v1/services/getCaptchaResult';

export const createPasswordReset = BASE_URL + 'api/v1/auth/pass/reset';
export const createEmailResendUrl = BASE_URL + 'api/v1/auth/email/resend';
export const checkPasswordReset = BASE_URL + 'api/v1/auth/pass/checkReset';
export const updatePasswordReset = BASE_URL + 'api/v1/auth/pass/doReset';

export const userOrder = BASE_URL + 'api/v1/users/orders';
export const profile = BASE_URL + 'api/v1/users/profile';
export const userSetting = BASE_URL + 'api/v1/settings';

export const dashboardUrl = BASE_URL + '/dashboard';
export const fundingUrl = BASE_URL + '/funding';
export const apiUrl = BASE_URL + '/api-management';

export const ticketList = BASE_URL + 'api/v1/tickets/list';
export const ticketDetail = BASE_URL + 'api/v1/tickets';
export const createTicket = BASE_URL + 'api/v1/tickets';
export const ticketOptions = BASE_URL + 'api/v1/tickets/prepare';
export const sendMessage = BASE_URL + 'api/v1/tickets/messages';
export const ticketStatusUrl = BASE_URL + 'api/v1/tickets/update';
export const checkout = BASE_URL + 'api/v1/stripe/checkout';
export const checkoutPaypal = BASE_URL + 'api/v1/paypal/checkout';
export const checkoutBitpay = BASE_URL + 'api/v1/bitpay/invoice';
export const checkoutPaytabs = BASE_URL + 'api/v1/paytabs/checkout';
export const helpUs = BASE_URL + 'api/v1/helpUs/uploadCaptcha';
export const plans = BASE_URL + 'api/v1/plans';
export const home = BASE_URL + 'api/v1/home';
export const loginByGoogle = BASE_URL + 'api/v1/auth/google/join';

export const usageHistory = BASE_URL + 'api/v1/history/services';
export const getUsageHistoryById =
  BASE_URL + 'api/v1/history/services/{request_id}';
export const getRemainingVolumeUrl =
  BASE_URL + 'api/v1/dashboard/remaining_charts';
export const dashboardMapUrl = BASE_URL + 'api/v1/dashboard/map';
export const getRequestsChartUrl =
  BASE_URL + 'api/v1/dashboard/requests_charts';
export const getRecentActivityUrl = BASE_URL + 'api/v1/history/signin';

export const transactionListUrl = BASE_URL + 'api/v1/history/payments';
export const transactionDetailUrl = BASE_URL + 'api/v1/history/payments/:id';

export const getSettingUrl = BASE_URL + 'api/v1/settings';
export const adminSettingUrl = BASE_URL + 'api/v1/owner/settings/';
export const userUrl = BASE_URL + 'api/v1/user/update';
export const appUrl = BASE_URL + 'api/v1/tickets/unread';
export const feedback = BASE_URL + 'api/v1/rates';
export const getPaymentMethod = BASE_URL + 'api/v1/pmethods';
export const successfulPayment = BASE_URL + 'api/v1/payments/callback/{pay_id}';
export const currencyUrl = BASE_URL + 'api/v1/crypto/cryptomus/currencies';
export const walletAddressUrl = BASE_URL + 'api/v1/crypto/cryptomus/wallet';
export const currencyExchangeUrl = BASE_URL + 'api/v1/cryptomus/exchange-rates';

export const bannerUrl = BASE_URL + 'api/v1/banners/list';
export const blogUrl =
  'http://92.205.130.223:1337/api/blogs?populate=*&sort=createdAt%3Adesc';
