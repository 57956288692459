function CopyIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.5" height="15.5" viewBox="0 0 25.5 25.5">
      <path
        fill={props.fill ? props.fill : "#787777"}
        d="M25.022 3.518L21.972.467A1.59 1.59 0 0020.844 0H12.7a3.188 3.188 0 00-3.192 3.187v12.75a3.254 3.254 0 003.231 3.187H22.3a3.2 3.2 0 003.187-3.187V4.644a1.6 1.6 0 00-.465-1.126zM23.1 15.937a.8.8 0 01-.8.8h-9.6a.8.8 0 01-.8-.8V3.194a.8.8 0 01.8-.8h6.375l.045 2.384a1.594 1.594 0 001.594 1.594h2.346v9.562zm-9.562 6.375a.8.8 0 01-.8.8H3.133a.8.8 0 01-.8-.8L2.38 9.567a.8.8 0 01.8-.8h4.779v-2.4H3.177A3.187 3.187 0 00-.01 9.562v12.75A3.189 3.189 0 003.178 25.5h9.562a3.2 3.2 0 003.187-3.187v-1.594h-2.345z"
        transform="translate(.01)"
      ></path>
    </svg>
  );
}

export default CopyIcon;
