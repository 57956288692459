import {useState,useContext} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Login from '../../pages/Login';
import Registraion from '../../pages/Registration';
import useHttp from '../../hook/useHttp';
import {registerUrl} from '../../extentions/apiUrls';
import SnackContext from '../../store/snack-context';
import ModalContext from '../../store/modal-context';
import ForgetPassword from './ForgetPassword';
import RegisterConfirmation from './RegisterConfirmation';
import ResendEmail from './ResendEmail';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Auth(props) {

  const tab = props.tab === undefined ? 0 : props.tab

  const [value, setValue] = useState(tab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const snackCtx = useContext(SnackContext)
  const modalCtx = useContext(ModalContext)

  const  {isLoading,sendRequest:registerRequest} = useHttp()
    
    function handleRegister(res) {
        
        if (res.data.status_code !== 200){
            snackCtx.handleOpen(res.data.message,'error')
        }
        else{

            const content={
              title:'',
              body:<RegisterConfirmation email={res?.data?.data?.email}/>
            }
            snackCtx.handleOpen(res.data.message,'success')
            modalCtx.modalHandler(content)
        }
        
    }
    const resendActivationEmailHandler = ()=>{
      const content={
        title:'',
        body:<ResendEmail/>
      }

      modalCtx.modalHandler(content)

    }
    const onRegisterButtonClick = (email,password,confirmPassword,g_response) =>{
        let data={
            email,
            password,
            confirmPassword,
            g_response
        }
        
        data = JSON.stringify(data)
        const options = {
            method:'POST',
            url:registerUrl,
            data:data,
        }
        registerRequest(options,handleRegister)
    }

    const forgetPasswordClickHandler = ()=>{
      const content={
        title:'',
        body:<ForgetPassword/>
      }

      modalCtx.modalHandler(content)
    }

  return (
    <>

        <Tabs 
          value={value} 
          onChange={handleChange} 
          variant="standard" 
          aria-label="basic tabs example"
          indicatorColor=''
        >
          <Tab 
            sx={{
              fontWeight:'bold',color:'#9b9a9a',fontSize:'24px',
              '&.Mui-selected':{color:"#76323F"}
            }} 
            label="Login" {...a11yProps(0)}
           />
          <Tab  
              sx={{
                fontWeight:'bold',color:'#9b9a9a',fontSize:'24px',
                '&.Mui-selected':{color:"#76323F"}
              }}  
              label="Register" {...a11yProps(1)} />

        </Tabs>
      
     
      <TabPanel value={value} index={0} >
        <Login onForgetPasswordClick = {forgetPasswordClickHandler} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Registraion registerHandler={onRegisterButtonClick} loading={isLoading} onResendEmailClick={resendActivationEmailHandler}/>
      </TabPanel>
    </>
  );
}