import {Outlet} from 'react-router';

const Ticket = ()=>{

    return(
        <Outlet/>
    )

}

export default Ticket;