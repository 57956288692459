import { Grid, Typography, Card, Box } from "@mui/material";
import ProductCard from "../../ui/product-card";
import { calculateBoost } from "../../../../utils/helper";
const ProductSection = ({ plans, onSelectPlan, selectedPlan, paymentMethod,buttonRef }) => {
  return (
    <Grid container sx={{ p: 1 }} spacing={{ xs: 1, sm: 2, lg: 3 }}>
      {paymentMethod?.attrs?.boost > 0 && (
        <Grid item xs={12}>
          <Card type="card" sx={{ backgroundColor: "#E5EAFF" }}>
            <Box>
              <Typography variant="h5">
                Your Wallet will be{" "}
                <span style={{ color: "#DBB26C", fontWeight: "bolder",marginLeft:4,marginRight:4 }}>
                  {calculateBoost(paymentMethod?.attrs?.boost)}x
                </span>{" "}
                charge by <span style={{textTransform:'capitalize'}}>{paymentMethod?.name}</span>
              </Typography>
            </Box>
          </Card>
        </Grid>
      )}
      <Box ref={buttonRef}></Box>
      {plans?.map((item, index) => {
        return (
          <Grid key={index} item xs={12} lg={6}>
            <ProductCard
              id={item.id}
              price={item?.price}
              captcha={item?.item?.captcha}
              recaptcha={item?.item?.recaptcha}
              api={item?.remaining_apis}
              onSelectPlan={onSelectPlan}
              checked={selectedPlan?.id === item.id}
            />
          </Grid>
        );
      })}
      {paymentMethod?.attrs?.boost > 0 && (
        <Grid item xs={12}>
          <Card type="card" sx={{ backgroundColor: "#ECFCF3" }}>
            <Box sx={{ color: "#787777" }}>
              <Typography variant="h5">
                With paying
                <span style={{ marginLeft: 4, marginRight: 4, color: "#5BC17E", fontWeight: "bolder" }}>
                  {selectedPlan?.price}$
                </span>
                by <span style={{textTransform:'capitalize'}}>{paymentMethod?.name}</span>, your wallet will be charged
                <span style={{ marginLeft: 4, marginRight: 4, color: "#5BC17E", fontWeight: "bolder" }}>
                  {String((selectedPlan?.price * calculateBoost(paymentMethod?.attrs?.boost)).toFixed(2)).replace('.00','')}$
                </span>
              </Typography>
            </Box>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export default ProductSection;
