import { useState, useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import useHttp from "../../hook/useHttp";
import { ticketList as ticketListUrl, ticketStatusUrl } from "../../extentions/apiUrls";
import TicketTable from "./TicketTable";
import SnackContext from "../../store/snack-context";
import { Card, Box, CircularProgress, Grid } from "@mui/material";

let initialPagination = {
  current: 1,
  lastPage: 1,
};
const TicketList = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams({});
  const [ticketList, setTicketList] = useState(undefined);
  const { isLoading, sendRequest: getTicketListRequest } = useHttp();
  const { sendRequest: updateTicketStatus } = useHttp();
  const [pagination, setPagination] = useState(initialPagination);
  const snackCtx = useContext(SnackContext);

  const handlePageChange = (event, value) => {
    let params = Object.fromEntries([...searchParams]);
    setSearchParams({ ...params, page: Number(value) });
    setPagination((prevState) => {
      return {
        ...prevState,
        current: value,
      };
    });
  };

  const handleGetTicketList = (res) => {
    if (res.data.status_code === 200) {
      setTicketList(res.data.data.data);
      setPagination(() => {
        return {
          current: res.data.data.current_page,
          lastPage: res.data.data.last_page,
        };
      });
    }
  };

  const onRowClickHandler = (id) => {
    navigate("/support/tickets/ticket-detail/:id".replace(":id", id));
  };
  useEffect(() => {
    let params = Object.fromEntries([...searchParams]);
    setPagination((prevState) => {
      return {
        ...prevState,
        page: Number(params.page),
      };
    });

    let options = {
      method: "GET",
      url: ticketListUrl,
      params:{page:params?.page ? params.page : 1}
    };
    getTicketListRequest(options, handleGetTicketList);
  }, [getTicketListRequest, searchParams]);

  const updateTicketStatusHandler = (res) => {
    const newTicketList = [...ticketList];
    const updatedTicketList = newTicketList.map((item) => {
      if (item.id === res.data.data.id) {
        item.status = res.data.data.status;
      }
      return item;
    });
    setTicketList(updatedTicketList);
    snackCtx.handleOpen(res.data.message);
  };
  const closeTicketHandler = (id) => {
    const options = {
      method: "PUT",
      url: ticketStatusUrl,
      data: { ticket_id: id, status: "closed" },
    };
    updateTicketStatus(options, updateTicketStatusHandler);
  };

  return ticketList === undefined ? (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
      <CircularProgress />
    </Box>
  ) : (
    <Grid container>
      <Grid item xs={12} md={11}>
        <Card type="card">
          <TicketTable
            isLoading={isLoading}
            tableRows={ticketList}
            onRowClick={onRowClickHandler}
            onCloseClick={closeTicketHandler}
          />
          <Pagination
            sx={{ display: "flex", justifyContent: "center", marginTop: "32px" }}
            count={pagination.lastPage}
            page={Number(pagination.current)}
            onChange={handlePageChange}
            shape="rounded"
            size="small"
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default TicketList;
