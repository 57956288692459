import { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import { Badge, Collapse, Divider } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "./MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useTheme } from "@mui/material/styles";
import { checkPermission } from "../../utils/helper";
import DashboardContext from "../../store/dashboard-context";
const MultiLevelMenu = ({ item, closeDrawer }) => {
  const children = item.children;
  const theme = useTheme();
  const location = useLocation();
  const Icon = item.icon;
  const [open, setOpen] = useState(false);
  const authCtx = useContext(AuthContext);
  const appCtx = useContext(DashboardContext);
  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return item.showInMenu && checkPermission(item.permissions, authCtx.checkPermission) ? (
    <>
      <ListItem
        button
        onClick={handleClick}
        sx={{ color: "gray", "&.Mui-selected": { bgcolor: "primary.main", color: "white" } }}
        selected={location.pathname === item.path}
      >
        {Icon ? (
          <ListItemIcon sx={{ "&.MuiListItemIcon-root": { minWidth: "24px !important" } }}>
            <Icon
              fill={
                location.pathname === item.path
                  ? theme.palette.primary.selectedDashboard
                  : theme.palette.primary.dashboardIcon
              }
            />
          </ListItemIcon>
        ) : null}
        <Divider sx={{ height: "2px" }} />
        <ListItemText primaryTypographyProps={{ variant: "body1" }} primary={item.title} />
        {item.title === "Tickets" ? (
          <Badge
            badgeContent={appCtx.unreadTicket}
            sx={{
              "& .MuiBadge-badge": { backgroundColor: "#FFE5E7", color: "#DB6C6C", mr: 1 },
            }}
          />
        ) : null}
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse sx={{ ml: 1 }} in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((child, key) => (
            <MenuItem sx={{ ml: 1 }} closeDrawer={closeDrawer} key={key} item={child} />
          ))}
        </List>
      </Collapse>
      <Divider sx={{ height: "2px", color: "#FAFBFB" }} />
    </>
  ) : (
    <List component="div" disablePadding>
      {children.map((child, key) => (
        <MenuItem closeDrawer={closeDrawer} key={key} item={child} />
      ))}
    </List>
  );
};

export default MultiLevelMenu;
