import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DragFileUpload from "../../upload/drag/DragFileUpload";
import CircularProgressComponent from "../../layout/CirCularProgressComponent";
import Zoom from "@mui/material/Zoom";
import styles from "./TestServiceForm.module.css";
import { styled } from "@mui/material/styles";
const Image = styled("div")((props) => ({
  width: "auto",
  height: 50,
  background: "#F2F2F3",
  borderRadius: "8px",
  padding: "8px",
  display: "inline-block",
  marginRight: 16,
}));
const TestServiceForm = (props) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} sx={{ display: "flex" }}>
            <Box sx={{ display: "grid" }}>
              <Box className={styles.container}>
                {props.images.map((image, index) => {
                  return (
                    <Image key={index}>
                      <img src={image} alt="Captcha" onClick={(e) => props.onImageClick(image)} />
                    </Image>
                  );
                })}
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid container sx={{ p: 2 }}>
          <Grid item xs={12}>
            <DragFileUpload fileName={props.fileName} file={props.file} fileUpload={props.fileUpload} />
          </Grid>
        </Grid>
        {props.readFile ? (
          <Grid container justifyContent="center" sx={{ p: 2 }}>
            <Grid item xs={12} sx={{ display: "flex" }}>
              <img src={props.readFile} style={{ maxWidth: "150px" }} alt={"captcha bypass"} />
            </Grid>
          </Grid>
        ) : null}
        {props.result !== "" ? (
          <Grid container sx={{ p: 2 }}>
            <Grid item xs={12} md={3}>
              <Typography component={"span"} color="primary.text" variant="h3">
                Result:
              </Typography>

              <Zoom timeout={1000} in={props.startAnimation}>
                <Typography component={"span"} color="primary.text" variant="h4" sx={{ m: 1, p: 1 }}>
                  {props.result}
                </Typography>
              </Zoom>
            </Grid>
          </Grid>
        ) : null}
        <Grid container >
          <Grid item xs={12} sm={'auto'}>
            {props.isLoading ? (
              <CircularProgressComponent minHeight={"0"} />
            ) : (
              <Button fullWidth sx={{maxWidth:'100%'}} onClick={(e) => props.handleShowResult()} variant={"contained"} disabled={!props.readFile}>
                Show Result
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container>
          {props.codeLoading ? (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <CircularProgressComponent />
            </Box>
          ) : (null
            // <Grid item xs={12} md={11} justifyContent={"center"} alignItems={"center"} sx={{ mt: 4 }}>
            //   <CaptchaHelpForm title={"Captcha"} codeSample={props.codeSample || {}} />
            // </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TestServiceForm;
