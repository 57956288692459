import { useEffect, useState, useContext } from "react";
import useHttp from "../../hook/useHttp";
import { feedback, statistics } from "../../extentions/apiUrls";
import FeedbackForm from "./form";
import { Box } from "@mui/system";
import { CircularProgress } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import SnackContext from "../../store/snack-context";
const Feedback = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [samplMessage, setSampleMessage] = useState([]);
  const payId = searchParams.get("session_id") || searchParams.get("token") || searchParams.get("uuid");
  const { isLoading, sendRequest } = useHttp();
  const { isLoading: sendLoading, sendRequest: sendCommentRequest } = useHttp();
  const [message, setMessage] = useState("");
  const [customMessage, setCustomMessage] = useState("");
  const snackCtx = useContext(SnackContext);
  useEffect(() => {
    const sendRequestHandler = (res) => {
      setSampleMessage(res.data.data.payment_rate_suggestion_messages);
    };
    const options = {
      method: "GET",
      url: statistics,
    };

    sendRequest(options, sendRequestHandler);
  }, [sendRequest]);

  const changeMessageHandler = (event) => {
    setMessage(event.target.handler);
  };
  const selectMessageHandler = (text) => {
    setCustomMessage(text);
    setMessage(text === "Custom message" ? "" : text);
  };

  const sendCommentRequestHandler = (res) => {
    snackCtx.handleOpen(res.data.message);
    navigate("/");
  };
  const submitHandler = () => {
    const options = {
      method: "post",
      url: feedback,
      data: {
        rate_type: "payments",
        score: "bad",
        target_id: payId,
        comment: message,
      },
    };
    sendCommentRequest(options, sendCommentRequestHandler);
  };

  return isLoading ? (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
      <CircularProgress />
    </Box>
  ) : (
    <FeedbackForm
      customMessageList={samplMessage}
      onChangeMessage={changeMessageHandler}
      onSelectCutomMessage={selectMessageHandler}
      message={message}
      customMessage={customMessage}
      onSubmit={submitHandler}
      submitLoading={sendLoading}
      payId={payId}
    />
  );
};

export default Feedback;
