import {useState} from 'react'
import {Grid,Typography,Divider,Box} from '@mui/material'
import CopyIcon from '../../../../icons/CopyIcon'
import CodeEditor from '@uiw/react-textarea-code-editor';
import SnackContext from "../../../../../store/snack-context";
import { useContext } from "react";
const CaptchaHelpForm = ({codeSample})=>{
    const [selectedLanguage,setSelectedLanguage] = useState(Object.keys(codeSample)[0])
    const snackCtx = useContext(SnackContext);
    const selectLanguageHandler = (text)=>{
        setSelectedLanguage(text)
    }
    return(
        
        <Grid container sx={{background:'#FBFBFB',p:'1.4rem',borderRadius:2,border:'1px solid #DCDCDC'}}>
            <Grid item xs={12} container spacing={2}>
                {
                    Object.keys(codeSample)?.map((item,index)=>{
                        return (
                            <Grid key={index} item xs="auto">
                                <Box 
                                    onClick={()=>selectLanguageHandler(item)}
                                    sx={{
                                            cursor:'pointer',
                                            padding:'0.5rem 1rem',
                                            background:selectedLanguage === item ?'#7897FE' :'white',
                                            color:selectedLanguage === item ?'white' :'#7897FE' ,
                                            border:'1px solid #7897FE',
                                            borderRadius:2
                                            }}
                                        >
                                    <Typography variant={'body2'}>
                                        {item}
                                    </Typography>
                                </Box>
                            </Grid>
                        )
                    })
                }
                
                
            </Grid>
            <Grid item xs={12} sx={{mt:2}}>
                <Box 
                    sx={{
                        borderRadius:2,
                        backgroundColor:'#0A1b31',
                        color:'#DBB26C',
                        height:'550px',
                        overflowY:'scroll',
                        position:'relative',
                        "&::-webkit-scrollbar": {
                            width: "0.4em",
                          },
            
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "primary.main",
                            borderRadius: "4px",
                          },
                        }}
                >
                    <CodeEditor
                       
                        data-color-mode="dark"
                        disabled={true}
                        value={codeSample[selectedLanguage]}
                        language="js"
                        placeholder="Please enter JS code."
                        // onChange={(evn) => setCode(evn.target.value)}
                        padding={15}
                        style={{
                            width:'100%',
                            height:'96%',
                            overflowY:'auto',
                            fontSize: 12,
                            backgroundColor: "#0A1b31",
                            color:'#DBB26C !important',
                            fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                            
                        }}
                    />
                    <Box onClick={()=>{
                        snackCtx.handleOpen("Copied to clipboard", "");
                        navigator.clipboard.writeText(codeSample[selectedLanguage])
                        }}
                        component="span" sx={{cursor:'pointer',position:'absolute',color:'#DBB26C',bottom:10,right:10,border:'2px solid #DBB26C',padding:'0.1rem 1rem',borderRadius:2}}>
                        <div style={{marginRight:2,display:'flex',alignItems:'center'}}></div>
                        <span style={{marginLeft:1,marginTop:1}}><CopyIcon fill={'#DBB26C'}/></span>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default CaptchaHelpForm