import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { checkPermission } from "../../utils/helper";
import { Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
const SingelLevelMenu = (props) => {
  const item = props.item;
  const theme = useTheme();
  const Icon = item.icon;
  const authCtx = useContext(AuthContext);
  const location = useLocation();
  return item.showInMenu && checkPermission(item.permissions, authCtx.checkPermission) ? (
    <>
      <ListItem
        key={item.title}
        disablePadding
        sx={{ color: "gray", "&.Mui-selected": { bgcolor: "primary.main", color: "white" } }}
        component={item?.target ? "a" : Link}
        to={item.url ? item.url : item.path}
        href={item.url ? item.url : item.path}
        selected={location.pathname === item.path}
        onClick={props.closeDrawer}
        target={item?.target ? '_blank':'_self'}
      >
        <ListItemButton>
          {Icon ? (
            <ListItemIcon sx={{ "&.MuiListItemIcon-root": { minWidth: "24px !important" } }}>
              <Icon
                fill={
                  location.pathname === item.path
                    ? theme.palette.primary.selectedDashboard
                    : theme.palette.primary.dashboardIcon
                }
              />
            </ListItemIcon>
          ) : null}
          <ListItemText primaryTypographyProps={{ variant: "body1" }} primary={item.title} />
        </ListItemButton>
      </ListItem>
      <Divider sx={{ height: "2px", color: "#FAFBFB" }} />
    </>
  ) : null;
};

export default SingelLevelMenu;
