

function TickIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <path
        fill="#5bc17e"
        d="M18.5 36A17.5 17.5 0 1136 18.5 17.5 17.5 0 0118.5 36zm0-3.182A14.318 14.318 0 104.182 18.5 14.318 14.318 0 0018.5 32.818zm-5.625-14.784l3.375 3.375 7.875-7.875a1.591 1.591 0 012.25 2.25l-9 9a1.591 1.591 0 01-2.25 0l-4.5-4.5a1.591 1.591 0 112.25-2.25z"
        transform="translate(-1 -1)"
      ></path>
    </svg>
  );
}

export default TickIcon;
