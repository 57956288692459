import {useRef,useEffect,useState} from 'react'
import classes from './DragFileUpload.module.css';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '../../icons/UploadIcon';
import { Typography } from '@mui/material';
const DragFileUpload = (props)=>{
    const[drag,setDrag] = useState(false)
    // const[file,setFile] = useState(undefined)
    const dropArea = useRef()
    const fileUpload = useRef()
    const handleUploadFile = (e)=>{
        // setFile(e.target.files[)
        const file = e.target.files[0]
        const fileName = file.name;
        const extension = fileName.split(".").pop();
        const isSupported = props.acceptedFiles? props.acceptedFiles.includes(extension.toUpperCase()) : true;
        if(isSupported)
        {
            props.fileUpload(e.target.files[0])
        }
        else
        {
            props.toast('File not supported','')
        }
        
    }
    const handleRemoveFile =(e)=>{
        e.preventDefault()
        e.stopPropagation()
        fileUpload.current.value=""
        props.fileUpload(undefined)
    }
    const handleDragEnter = (e)=>{
        e.preventDefault()
        e.stopPropagation()
        setDrag(true)
    }
    const handleDragLeave = (e)=>{
        e.preventDefault()
        e.stopPropagation()
        setDrag(false)
    }
    const handleDragOver = (e)=>{
        e.preventDefault()
        e.stopPropagation()    
        
    }
    const handleDragDrop = (e)=>{
        e.preventDefault()
        e.stopPropagation()
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const file = e.dataTransfer.files[0]
            const fileName = file.name;
            const extension = fileName.split(".").pop();
            const isSupported = props.acceptedFiles? props.acceptedFiles.includes(extension.toUpperCase()) : true;
            if(isSupported)
            {
                props.fileUpload(file)
                e.dataTransfer.clearData()
            }
            else
            {
                props.toast('File not supported','')
            }

          }
        setDrag(false)
    }

    useEffect(
        ()=>   
     
        {
            let div = dropArea.current
            div.addEventListener('dragenter',handleDragEnter)
            div.addEventListener('dragleave',handleDragLeave)
            div.addEventListener('dragover',handleDragOver)
            div.addEventListener('drop',handleDragDrop)
            return ()=>{
                div.removeEventListener('dragenter',handleDragEnter)
                div.removeEventListener('dragleave',handleDragLeave)
                div.removeEventListener('dragover',handleDragOver)
                div.removeEventListener('drop',handleDragDrop)
            }
        },[]
    )

    return(
        <>
        <Grid container alignItems="center" 
              justifyContent="center" 
              className={drag ? classes.drag : null} 
              ref={dropArea} 
              onClick = {()=>fileUpload.current.click()}
              style={{
                  borderStyle:'dashed',
                  height:'200px',
                  backgroundColor:'#fafbfb',
                  
                  cursor:'pointer',
                  borderRadius:'8px',
                  borderColor:'#9E9E9E',
                  borderWidth:'2px'
                
                }}
        >
            <input type="file" ref={fileUpload} onChange={handleUploadFile} style={{display:'none'}} />
            {
                props.file ? 
                <>
                <Grid item xs={12} sx={{textAlign:'center'}}>
                    <Typography variant='h4' color="primary.text">{props.file.name || props.fileName}</Typography>
                </Grid>
                <DeleteIcon sx={{cursor:'pointer',height:30,width:30}} onClick={handleRemoveFile}/>
                </>
                :
                <>
                <Grid item xs={12} sx={{textAlign:'center'}}>
                    <UploadIcon/>
                </Grid>
                <Grid item xs={12} sx={{textAlign:'center'}}>
                    <Typography variant='body2' color="primary.text">
                         Drag File Here Or Click!
                    </Typography>
                    
                </Grid>
                {props.acceptedFiles ?
                    <Grid item xs={12} sx={{textAlign:'center'}}>
                        <Typography variant='body2' color="primary.text">
                            accepted files : {props.acceptedFiles.map(item=>{return item + ', '})}
                        </Typography>
                    </Grid>: null
                }
                {
                     <Grid item xs={12} sx={{textAlign:'center'}}>
                         <Typography variant='body2' color="primary.text">
                            Size Limit : {props?.sizeLimit || '5MB'}
                        </Typography>
                       
                     </Grid>
                }
                </>
            }
        </Grid>
        </>
    )


}

export default DragFileUpload;

