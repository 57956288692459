import { useState } from "react";
import { Grid, Divider, Box } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Title from "../../../layout/Title";
import CaptchaHelpForm from "./form";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
const CaptchaHelp = ({ codeSample, codeLoading }) => {
  const [value, setValue] = useState(0);
  const [tokenRadioButton, setTokenRadioButton] = useState("0");
  const tokenRadioButtonChangeHandler = (e) => {
    setTokenRadioButton(e.target.value);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Tabs variant="scrollable" value={value} onChange={handleChange}>
          <Tab label="Captcha" sx={{ textTransform: "none" }} />
          <Tab label="reCaptcha v2" sx={{ textTransform: "none" }} />
          <Tab label="reCaptcha v3" sx={{ textTransform: "none" }} />
        </Tabs>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <TabPanel value={value} index={0}>
          <CaptchaHelpForm codeLoading={codeLoading} codeSample={codeSample?.captcha} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CaptchaHelpForm codeLoading={codeLoading} codeSample={codeSample?.re_captcha?.v2} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CaptchaHelpForm codeLoading={codeLoading} codeSample={codeSample?.re_captcha?.v3} />
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default CaptchaHelp;
