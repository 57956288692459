import { useState } from "react";
import useHttp from "../hook/useHttp";
import { useLocation } from "react-router-dom";
import BannerContext from "./banner-context";
import { bannerUrl } from "../extentions/apiUrls";
const BannerProvider = (props) => {
  const location = useLocation();
  const [banners, setBanners] = useState([]);
  const [banner, setBanner] = useState(undefined);
  const { sendRequest } = useHttp();
  const loadBanners = () => {
    const requestHandler = (res) => {
      // setBanners(res.data.data);
      const banner = res.data.data?.find((item) => {
        return item.slug === location.pathname;
      });
      // setBanner(banner);
    };
    const options = {
      method: "get",
      url: bannerUrl,
    };
    sendRequest(options, requestHandler);
  };
  const getBanners = () => {
    return banners;
  };

  const loadBanner = (banner) => {
    setBanner(banner);
  };
  const getBanner = () => {
    return banner;
  };
  const handleClose = (id) => {
    const index = banners.findIndex((item) => {
      return item.id === id;
    });
    const updatedBanners = [...banners];
    updatedBanners.splice(index, 1);
    setBanners(updatedBanners);
    setBanner((prevState) => {
      return {
        ...prevState,
        show: 0,
      };
    });
  };
  const bannerContext = {
    loadBanners,
    getBanners,
    loadBanner,
    getBanner,
    banner,
    handleClose,
  };

  return <BannerContext.Provider value={bannerContext}>{props.children}</BannerContext.Provider>;
};

export default BannerProvider;
