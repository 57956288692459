import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { noContent as noContentImg } from "../../extentions/imagesUrl";
import CirCularProgressComponent from "../layout/CirCularProgressComponent";
import { Box, Card, Typography, Grid, Divider, Pagination, Button } from "@mui/material";
import Title from "../layout/Title";
import { CircularProgress } from "@material-ui/core";
const Bullet = styled("div")((props) => ({
  "&::before": {
    color: props.color ? props.color : "#7F8081",
    content: "'\\2022'",
    fontSize: "24px",

    fontWeight: "bold",
  },
}));

const Values = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.primary.text,
}));
const HistoryTable = (props) => {
  const data = props.data;
  const createTableOrderRows = () => {
    return (
      <TableBody>
        {data.map((item, idx) => {
          let color = null;
          if (item.status === "success") color = "#6CDBB2";
          else if (item.status === "error") color = "#DB6C82";
          else color = "#f76802";
          return (
            <TableRow key={item.id}>
              <TableCell align="center">{idx + 1}</TableCell>
              <TableCell align="center">{item?.service_name}</TableCell>

              <TableCell align="center">{new Date(item?.created_at * 1000).toLocaleString()}</TableCell>
              {/* <TableCell align="center">{item?.inputs?.client_ip }</TableCell> */}
              <TableCell align="center">
                <Values>
                  <Bullet color={color} />
                  <Typography sx={{ ml: 1 }} variant="body2" component={"div"}>
                    {" "}
                    {item?.status}{" "}
                  </Typography>
                </Values>
              </TableCell>
              {/* <TableCell align="center">
                                {
                                    item.inputs.image
                                        ?
                                            <img width={45} src={`data:image/jpeg;base64,${item?.inputs?.image}`}/>
                                        :
                                            '-'
                                }
                            </TableCell> */}
              <TableCell sx={{ width: "100px",}} align="center">
                <span
                  style={{
                    display: "block",
                    width: "100px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    
                  }}
                >
                  {item?.response?.Data?.Result ||
                    item?.response?.Data?.Response ||
                    item?.response?.Data?.RecaptchaId ||
                    item?.response?.Data?.RecaptchaResponse}
                </span>
              </TableCell>
              <TableCell align="center">
                {props.detailLoading && item.id === props.selectedId ? (
                  <CircularProgress />
                ) : (
                  <Button
                    variant="table"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      props.onDetail(item.id);
                    }}
                  >
                    Detail
                  </Button>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };
  const createHistoryTable = () => {
    if (props.data !== undefined)
      return (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Row</TableCell>
                <TableCell align="center">Service</TableCell>

                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Status</TableCell>

                <TableCell align="center">Result</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            {props.isLoading ? null : createTableOrderRows()}
          </Table>
          {props.isLoading ? (
            <CirCularProgressComponent />
          ) : props.data.length === 0 ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img style={{ height: "auto", width: "300px" }} src={noContentImg} alt={"captcha bypass"} />
            </div>
          ) : null}
        </TableContainer>
      );
  };
  return (
    <Grid container>
      <Grid item xs={12} md={11}>
        <Card type="card">
          <Grid container>
            <Grid item xs={12}>
              <Title>Usage History</Title>
              <Divider sx={{ mb: 3 }} />
            </Grid>
            <Grid item xs={12}>
              {createHistoryTable()}
            </Grid>
            <Grid item xs={12}>
              <Pagination
                sx={{ display: "flex", justifyContent: "center", marginTop: "32px" }}
                count={props?.totalPage}
                page={Number(props?.page)}
                onChange={props?.onPageChange}
                shape="rounded"
                size="small"
                color="primary"
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default HistoryTable;
