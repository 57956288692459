

export default function HelpUsIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.483"
      height="14.747"
      viewBox="0 0 15.483 14.747"
    >
      <g transform="translate(.764 .778)">
        <path
          fill="none"
          fillRule="evenodd"
          stroke={props.fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M6.8 13.112a25.59 25.59 0 01-4.421-3.443 8.806 8.806 0 01-2.1-3.356C-.516 3.856.407 1.043 2.99.211a4.39 4.39 0 013.984.671h0a4.4 4.4 0 013.984-.671c2.583.832 3.513 3.645 2.722 6.1a8.806 8.806 0 01-2.1 3.356 25.59 25.59 0 01-4.422 3.443l-.173.107zM9.723 2.977a2.043 2.043 0 011.408 1.779"
          data-name="Path 15"
        ></path>
      </g>
    </svg>
  );
}


