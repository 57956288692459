

function UploadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 114.749 114.595"
    >
      <path
        fill="none"
        fillRule="evenodd"
        stroke="rgba(158,158,158,0.57)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="7.5"
        d="M110.845 79.074c0 19.991-11.781 31.772-31.772 31.772H35.574C15.53 110.845 3.75 99.065 3.75 79.074V35.522C3.75 15.53 11.094 3.75 31.085 3.75h11.168a12.749 12.749 0 0110.2 5.1l5.1 6.783a12.8 12.8 0 0010.2 5.1h15.808c20.042 0 27.437 10.2 27.437 30.6z"
        data-name="Path 61"
      ></path>
    </svg>
  );
}

export default UploadIcon;