function TrendIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 70.843 65.682">
      <g
        fill="none"
        stroke={props.back ? props.back : "#dbb26c"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.5"
        transform="translate(.972 .972)"
      >
        <path fillRule="evenodd" d="M17.78 41.212l9.627-12.513 10.981 8.627 9.422-12.158" data-name="Path 16"></path>
        <circle cx="6.182" cy="6.182" r="6.182" data-name="Ellipse 6" transform="translate(55.756 .778)"></circle>
        <path
          fillRule="evenodd"
          d="M43.672 3.487H18.007C7.372 3.487.778 10.395.778 20.149v26.179c0 9.754 6.465 16.632 17.229 16.632h30.384c10.634 0 17.229-6.878 17.229-16.632v-22.8"
          data-name="Path 17"
        ></path>
      </g>
    </svg>
  );
}

export default TrendIcon;
