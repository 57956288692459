function PhpIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="112.842" height="63.473" viewBox="0 0 112.842 63.473">
      <defs>
        <clipPath id="clip-path">
          <path
            d="M14.4-355.806c0 15.736 24.251 28.492 54.164 28.492s54.164-12.757 54.164-28.492c0-15.736-24.251-28.492-54.164-28.492S14.4-371.541 14.4-355.806"
            transform="translate(-14.4 384.299)"
          ></path>
        </clipPath>
        <radialGradient
          id="radial-gradient"
          cx="0.628"
          cy="0.8"
          r="1.248"
          gradientTransform="matrix(.526 0 0 1 -.009 .511)"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#aeb2d5"></stop>
          <stop offset="0.3" stopColor="#aeb2d5"></stop>
          <stop offset="0.75" stopColor="#484c89"></stop>
          <stop offset="1" stopColor="#484c89"></stop>
        </radialGradient>
        <clipPath id="clip-path-2">
          <path d="M0-405h112.842v63.473H0z" transform="translate(0 405)"></path>
        </clipPath>
      </defs>
      <g transform="translate(2.257 3.244)">
        <g clipPath="url(#clip-path)">
          <g>
            <g>
              <path
                fill="url(#radial-gradient)"
                d="M14.4-355.806c0 15.736 24.251 28.492 54.164 28.492s54.164-12.757 54.164-28.492c0-15.736-24.251-28.492-54.164-28.492S14.4-371.541 14.4-355.806"
                transform="translate(-14.4 384.299)"
              ></path>
            </g>
          </g>
        </g>
      </g>
      <g>
        <g clipPath="url(#clip-path-2)">
          <g transform="translate(4.373 5.36)">
            <path
              fill="#777bb3"
              d="M-280.052-283.849c28.745 0 52.048-11.809 52.048-26.377s-23.3-26.377-52.048-26.377-52.048 11.81-52.048 26.377 23.3 26.377 52.048 26.377"
              transform="translate(332.1 336.602)"
            ></path>
          </g>
        </g>
      </g>
      <g>
        <g clipPath="url(#clip-path-2)">
          <g transform="translate(18.743 18.551)">
            <path
              d="M-69.636-88.544a8.638 8.638 0 005.245-1.3 7.009 7.009 0 002.267-4.379c.373-1.921.231-3.262-.422-3.986-.667-.739-2.11-1.114-4.289-1.114h-3.777l-2.094 10.775zM-81.99-75.271a.587.587 0 01-.453-.214.587.587 0 01-.123-.486l5.55-28.553a.588.588 0 01.576-.476h11.96c3.759 0 6.557 1.021 8.316 3.034s2.314 4.853 1.623 8.408a13.421 13.421 0 01-1.438 4 13.387 13.387 0 01-2.647 3.317 11.612 11.612 0 01-4.356 2.61 19.732 19.732 0 01-5.985.77h-4.843l-1.383 7.116a.588.588 0 01-.577.476z"
              transform="translate(82.578 105)"
            ></path>
          </g>
          <g transform="translate(18.155 17.963)">
            <path
              fill="#fff"
              d="M-70.151-36.893h3.293c2.629 0 3.543.577 3.853.921.515.571.612 1.775.281 3.481a6.461 6.461 0 01-2.049 4.025 8.1 8.1 0 01-4.886 1.173h-2.358zm5.651-6.857h-11.964a1.175 1.175 0 00-1.154.951l-5.55 28.553a1.176 1.176 0 00.247.972 1.175 1.175 0 00.907.428h6.22a1.175 1.175 0 001.154-.954l1.291-6.64h4.359a20.306 20.306 0 006.167-.8 12.2 12.2 0 004.575-2.74 13.981 13.981 0 002.758-3.459 14.007 14.007 0 001.5-4.173c.728-3.743.137-6.74-1.757-8.907-1.875-2.147-4.822-3.235-8.758-3.235m-8.94 17.631h3.784q3.762 0 5.6-1.417a7.526 7.526 0 002.486-4.732q.617-3.183-.562-4.492t-4.725-1.309h-4.258l-2.323 11.95m8.94-16.456q5.4 0 7.873 2.833t1.488 7.91a12.785 12.785 0 01-1.374 3.824 12.752 12.752 0 01-2.531 3.171 10.986 10.986 0 01-4.14 2.484 19.067 19.067 0 01-5.8.741h-5.328l-1.475 7.591h-6.22l5.55-28.553h11.96"
              transform="translate(83.189 43.75)"
            ></path>
          </g>
          <g transform="translate(45.221 10.96)">
            <path
              d="M-85.118-159.959a.587.587 0 01-.453-.214.587.587 0 01-.124-.486l2.455-12.634c.234-1.2.176-2.064-.162-2.428-.207-.223-.828-.6-2.666-.6h-4.447l-3.085 15.886a.588.588 0 01-.577.476h-6.171a.587.587 0 01-.454-.214.587.587 0 01-.123-.486l5.55-28.553a.588.588 0 01.577-.475h6.171a.588.588 0 01.454.214.588.588 0 01.123.486l-1.339 6.892h4.784c3.645 0 6.116.643 7.556 1.965 1.467 1.349 1.925 3.5 1.361 6.41l-2.582 13.287a.588.588 0 01-.577.476z"
              transform="translate(100.938 189.688)"
            ></path>
          </g>
          <g transform="translate(44.634 10.372)">
            <path
              fill="#fff"
              d="M-69.389 0h-6.171a1.175 1.175 0 00-1.154.951l-5.55 28.549a1.175 1.175 0 00.247.972 1.175 1.175 0 00.907.428h6.171a1.176 1.176 0 001.154-.951l2.994-15.407h3.963c1.834 0 2.219.392 2.235.409.111.12.258.674.016 1.916L-67.032 29.5a1.175 1.175 0 00.247.972 1.175 1.175 0 00.907.428h6.269a1.176 1.176 0 001.154-.951l2.582-13.287c.606-3.119.088-5.459-1.54-6.955-1.553-1.426-4.155-2.12-7.954-2.12h-4.072l1.2-6.192a1.176 1.176 0 00-.247-.972A1.175 1.175 0 00-69.389 0m0 1.175l-1.475 7.591h5.5q5.189 0 7.158 1.81t1.182 5.865l-2.582 13.287h-6.269l2.452-12.628q.419-2.156-.308-2.94t-3.1-.784h-4.929l-3.179 16.358h-6.171l5.55-28.553h6.171"
              transform="translate(82.285)"
            ></path>
          </g>
          <g transform="translate(67.291 18.551)">
            <path
              d="M-69.636-88.544a8.638 8.638 0 005.245-1.3 7.009 7.009 0 002.267-4.379c.373-1.921.231-3.262-.422-3.986-.667-.739-2.11-1.114-4.289-1.114h-3.777l-2.094 10.775zM-81.99-75.271a.587.587 0 01-.453-.214.587.587 0 01-.124-.486l5.55-28.553a.588.588 0 01.577-.476h11.961c3.759 0 6.557 1.021 8.316 3.034s2.314 4.853 1.623 8.408a13.432 13.432 0 01-1.438 4 13.387 13.387 0 01-2.647 3.317 11.612 11.612 0 01-4.356 2.61 19.732 19.732 0 01-5.985.77h-4.844l-1.383 7.116a.588.588 0 01-.577.476z"
              transform="translate(82.578 105)"
            ></path>
          </g>
          <g transform="translate(66.704 17.963)">
            <path
              fill="#fff"
              d="M-70.15-36.893h3.293c2.629 0 3.543.577 3.853.921.515.571.612 1.775.281 3.48a6.462 6.462 0 01-2.049 4.025 8.1 8.1 0 01-4.886 1.173h-2.357zm5.65-6.857h-11.963a1.175 1.175 0 00-1.154.951l-5.55 28.553a1.176 1.176 0 00.247.972 1.175 1.175 0 00.907.428h6.22a1.176 1.176 0 001.154-.951l1.29-6.64h4.359a20.306 20.306 0 006.167-.8 12.2 12.2 0 004.576-2.74 13.974 13.974 0 002.758-3.459 14.008 14.008 0 001.5-4.173c.728-3.743.137-6.74-1.757-8.907-1.875-2.147-4.822-3.235-8.758-3.235m-8.94 17.631h3.783q3.762 0 5.6-1.417a7.525 7.525 0 002.486-4.732q.618-3.183-.562-4.492t-4.725-1.309h-4.262l-2.322 11.95m8.94-16.456q5.4 0 7.873 2.833t1.488 7.91a12.784 12.784 0 01-1.374 3.824 12.752 12.752 0 01-2.531 3.171 10.986 10.986 0 01-4.14 2.484 19.067 19.067 0 01-5.8.741h-5.328l-1.475 7.591h-6.22l5.55-28.553H-64.5"
              transform="translate(83.188 43.75)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PhpIcon;
