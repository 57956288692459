import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import CirCularProgressComponent from '../layout/CirCularProgressComponent';
const RedirectPage = () => {
    const location = useLocation()

    useEffect(() => {
        if(location.state)
        {
            const url = location.state.url
            window.location.replace(url);
        }
      
    }, [])
  
    // Render some text when redirecting
    // You can use a loading gif or something like that
    return(
        <CirCularProgressComponent/>
    )
  }

export default RedirectPage