import { Box, CircularProgress } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { successfulPayment } from "../../extentions/apiUrls";
import useHttp from "../../hook/useHttp";
import SnackContext from "../../store/snack-context";
import SuccessfulPaymentForm from "./form";

const SuccessfulPayment = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [payment, setPayment] = useState({});
  const { isLoading, sendRequest: getPaymentDetailRequest } = useHttp();
  const snackCtx = useContext(SnackContext);
  const payId = searchParams.get("session_id") || searchParams.get("token") || searchParams.get("uuid");

  useEffect(() => {
    const getPaymentDetailRequestHandler = (res) => {
      setPayment(res.data.data);
    };
    const options = {
      method: "get",
      url: successfulPayment.replace("{pay_id}", payId),
    };
    getPaymentDetailRequest(options, getPaymentDetailRequestHandler);
  }, [getPaymentDetailRequest]);

  const redirectHandler = (e) => {
    navigate(e.target.name);
  };

  return isLoading ? (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
      <CircularProgress />
    </Box>
  ) : (
    <SuccessfulPaymentForm onRedirect={redirectHandler} payment={payment} />
  );
};
export default SuccessfulPayment;
