function NodeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="61"
      height="61"
      preserveAspectRatio="xMinYMin meet"
      viewBox="0 0 19.52 19.52"
    >
      <path fill="#F7DF1E" d="M0 0h19.52v19.52H0V0z"></path>
      <path d="M5.133 16.312l1.494-.904c.288.511.55.943 1.179.943.603 0 .983-.236.983-1.153V8.961h1.834v6.263c0 1.9-1.114 2.765-2.739 2.765-1.467 0-2.319-.76-2.752-1.677m6.487-.196l1.494-.865c.393.642.904 1.114 1.808 1.114.76 0 1.245-.38 1.245-.904 0-.629-.498-.852-1.337-1.218l-.458-.197c-1.323-.563-2.201-1.271-2.201-2.765 0-1.376 1.048-2.424 2.686-2.424 1.166 0 2.005.406 2.607 1.468l-1.428.917c-.315-.563-.655-.786-1.179-.786-.537 0-.878.341-.878.786 0 .55.341.773 1.127 1.114l.459.196c1.559.668 2.437 1.35 2.437 2.883 0 1.651-1.297 2.555-3.04 2.555-1.703 0-2.804-.812-3.341-1.874"></path>
    </svg>
  );
}

export default NodeIcon;
