
import Dashboard from '../pages/Dashboard'



const DashboardComponent = (props)=>{
    // const [mobileOpen, setMobileOpen] = useState(false);
    // const handleDrawerToggle = () => {
    //     setMobileOpen(!mobileOpen);
    // };
  return(
      <Dashboard mobileOpen={props.mobileOpen} handleDrawerToggle={props.handleDrawerToggle}/>
      
  )

}

export default DashboardComponent