import {useState} from 'react'
import AuthContext from "./auth-context";

let newUser = localStorage.getItem('user')
let user = JSON.parse(newUser)
let initialState = {
    firstName:   user ? user.firstName   : null,
    lastName:    user ? user.lastName    : null,
    email:       user ? user.email       : null,
    token:       user ? user.token       : null,
    role:        user ? user.role        : null,
    permissions: user ? user.permissions : [],
    userId:      user ? user.userId      : null

}

const AuthProvider = (props)=>{
    
    const[auth,setAuth]= useState(initialState)
    const loginHandler = (token,role,permissions,firstName,lastName,email,userId)=>{
        setAuth({
            firstName,
            lastName,
            email,
            token,
            role,
            permissions:permissions,
            userId
        });
        const user = {
            firstName:firstName,
            lastName:lastName,
            email:email,
            token:token,
            role:role,
            permissions:permissions,
            userId:userId
        }
        localStorage.setItem('user',JSON.stringify(user))
    }

    const handlePermission = (permission) =>{
        try {
            if(auth.permissions.indexOf(permission) !== -1)
            {
                return true
            }
            else{
                return false
            }
        }
        catch{
            return false
        }
    }

    const logoutHandler = (redirect = true)=>{
        localStorage.removeItem('user')
        setAuth({})
        if(redirect)
            window.location.href = 'https://www.metabypass.tech';
        // navigate('/login')
    }
    const contextValue={
        token:auth.token,
        role:auth.role,
        permissions:auth.permissions,
        firstName:auth.firstName,
        lastName:auth.lastName,
        email:auth.email,
        userId:auth.userId,
        login:loginHandler,
        checkPermission:handlePermission,
        logout:logoutHandler
    }

    return(
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;