import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Zoom from "@mui/material/Zoom";
import { noContent as noContentImg } from "../../extentions/imagesUrl";
import { activeCurrency } from "../../extentions/appConfig";
import { Divider } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Title from "../layout/Title";
import { styled } from "@mui/material/styles";
const Bullet = styled("div")((props) => ({
  "&::before": {
    color: props.color ? props.color : "#7F8081",
    content: "'\\2022'",
    fontSize: "24px",

    fontWeight: "bold",
  },
}));

const Values = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.primary.text,
}));
const UserOrderTable = (props) => {
  
  const handleTableRowClick = (id) => {
    props.handleRowClick(id);
  };

  const createTableOrderRows = () => {
    return (
      <TableBody>
        {props?.orders?.map((item) => {
          return (
            <TableRow key={item.id} sx={{ cursor: "pointer" }} onClick={(e) => handleTableRowClick(item.id)}>
              <TableCell align="center">{item.uuid}</TableCell>
              <TableCell align="center">{item?.plan?.name}</TableCell>
              <TableCell align="center">
                {item.plan_price}
                {activeCurrency}
              </TableCell>
              <TableCell align="center">
                {item.actived_date ? new Date(item?.actived_date * 1000).toLocaleString() : "--"}
              </TableCell>
              <TableCell align="center">{new Date(item.expired_date * 1000).toLocaleString()}</TableCell>
              <TableCell align="center">
                <Values>
                  <Bullet
                    color={
                      item?.status === "actived"
                        ? "#6CDBB2"
                        : item?.status === "inactived"
                        ? "#FF6886"
                        : item?.status === "processing"
                        ? "#7897FE"
                        : item?.status === 'canceled'
                        ? 'red'
                        : item?.status === "new"
                        ? '#e5e1e1'
                        : item?.status === "reserved"
                        ? "orange"
                        : item?.status === "pending"
                        ? "yellow"
                        : item?.status === "expired"
                        ? 'ff7400'
                        : "#7897FE"
                    }
                  />
                  {item?.status}
                </Values>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  const createTableOrder = () => {
    if (props.orders !== undefined)
      return (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">UUID</TableCell>
                <TableCell align="center">Plan Name</TableCell>
                <TableCell align="center">Price</TableCell>
                <TableCell align="center">Active Date</TableCell>
                <TableCell align="center">Expiration Date</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            {props.isLoading ? null : createTableOrderRows()}
          </Table>
          {props.isLoading ? (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 3, width: "100%", height: "100px" }}>
              <CircularProgress />
            </Box>
          ) : props.orders.length === 0 ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img style={{ height: "auto", width: "300px" }} src={noContentImg} alt={"captcha bypass"} />
            </div>
          ) : null}
        </TableContainer>
      );
    else {
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img style={{ height: "auto", width: "300px" }} src={noContentImg} alt={"captcha bypass"} />
        </div>
      );
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{ display: "grid" }}>
          <Zoom in={true}>
            <Card type="card">
              <Title>Order List</Title>
              <Divider sx={{ mb: 3 }} />
              <Grid container justifyContent="center" sx={{ p: 2 }}>
                <Grid item xs={12}>
                  {createTableOrder()}
                </Grid>
              </Grid>
            </Card>
          </Zoom>
        </Box>
      </Grid>
    </Grid>
  );
};

export default UserOrderTable;
