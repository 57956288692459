import {useState} from 'react'

import ModalContext from './modal-context';



const ModalProvider = (props)=>{
    const modal={isOpen:false,haveContent:false,content:{title:"",body:''}}
    const [modalState,setModalState] = useState(modal)
    
    const modalHandler = (content,back=false)=>{
        if (content.body){
            if(back){
                saveBackContent()
            }
            setModalState(
                (prevState)=>(
                    {
                        ...prevState,
                        isOpen:true,
                        content:{
                            title:content.title,
                            body:content.body
                        },
                        hasBack:back,
                        
                    }
                )
                
            )
        }
        else
        {
            setModalState(
                (prevState)=>(
                    {
                        ...prevState,
                        isOpen:false,
                        hasBack:false,
                        backContent:'' 
                    }
                )
                
            )

        }

        
    }

    

    const saveBackContent  = ()=> {
        setModalState(
            (prevState)=>(
                {
                    ...prevState,
                   backContent:prevState.content
                }
            )
            
        )
    }

    const loadBackContent = ()=>{
        modalHandler(modalState.backContent)
    }
    const modalContext = {
        isOpen:modalState.isOpen,
        haveContent:modalState.haveContent,
        content:modalState.content,
        modalHandler:modalHandler,
        hasBack:modalState.hasBack,
        backContent:modalState.backContent,
        loadBack:loadBackContent
        
    }

    return(
        <ModalContext.Provider value={modalContext}>
            {props.children}
        </ModalContext.Provider>

    )
}

export default ModalProvider;