function PurchaseIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13.744"
        height="14.066"
        viewBox="0 0 13.744 14.066"
      >
        <g
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          transform="translate(.75 .75)"
        >
          <path
            fillRule="evenodd"
            d="M8.943 12.566h-5.65c-2.075 0-3.667-.724-3.215-3.638l.527-3.949a2.176 2.176 0 012.081-2.011h6.889a2.237 2.237 0 012.082 2.011l.527 3.949c.383 2.585-1.166 3.638-3.241 3.638zm.092-9.744A2.876 2.876 0 006.111 0h0a2.978 2.978 0 00-2.075.822 2.777 2.777 0 00-.861 2h0"
            data-name="Path 56"
          ></path>
          <path
            d="M0.024 0L0 0"
            data-name="Line 30"
            transform="translate(8.092 4.557)"
          ></path>
          <path
            d="M0.024 0L0 0"
            data-name="Line 31"
            transform="translate(4.144 4.557)"
          ></path>
        </g>
      </svg>
    );
    }


    export default PurchaseIcon