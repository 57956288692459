

import { hasChildren } from "../../utils/helper";
import MultiLevelMenu from "./MultiLevelMenu";
import SingelLevelMenu from "./SingelLevelMenu";

const MenuItem = ({ item,closeDrawer }) => {
    const Component = hasChildren (item) ? MultiLevelMenu : SingelLevelMenu;
    return <Component closeDrawer={closeDrawer} item={item} />;
  };
export default MenuItem