import {Grid,Avatar,Box,Typography, Card}  from '@mui/material'
import PlusIcon from '../../../icons/PlusIcon'
import TestServiceIcon from '../../../icons/TestServiceIcon'
import UsageHistoryIcon from '../../../icons/UsageHistoryIcon'
import APIDocIcon from '../../../icons/ApiDocIcon'
import TicketIcon from '../../../icons/TicketIcon'
import LinkSection from '../ui/link-section'
import { useNavigate } from 'react-router'
const UserForm = ({user})=>{
    
    const navigate = useNavigate()
    return(
        <Card type={'card'} sx={{height:'100%',padding:'1.4rem'}}>
        <Grid container >
            <Grid item xs={12}>
                <Box sx={{display:{xs:'block' ,sm:'flex'},justifyContent:'space-between'}}>
                    <Box sx={{display:'flex',gap:1}}>
                        <Avatar sx={{backgroundColor:'#E5EAFF',width:{xs:40,md:50},height:{xs:40,md:50}}}>

                        </Avatar>
                        <Box>
                            <Typography variant="body1" color="#787777" sx={{fontWeight:600,fontSize:'14px'}}>
                                {user.email}
                            </Typography>
                            <Typography variant="body2" color="#787777" sx={{fontWeight:100,fontSize:'12px'}}>
                               {user.first_name} {user.last_name}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{display:'flex',justifyContent:'end'}}>
                        <Box
                            onClick={()=>{navigate('/profile')}}
                            sx={{
                                border:'solid 1px #7897fe',
                                backgroundColor:'#FFFFFF',
                                color:'#7897fe',
                                p:'0.5rem 0.6rem',
                                fontSize:'0.8rem',
                                width:{xs:'100%',sm:'90px'},
                                height:'33px',
                                cursor:'pointer',
                                borderRadius:2,
                                display:'flex',
                                justifyContent:'center',
                                alignItem:'center',
                                mt:{xs:2,md:0},
                                boxSizing:'border-box'

                            }}>
                            
                            <span>Edit Profile</span>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Grid item container spacing={2}  sx={{mt:4}} justifyContent="center">
                
                    <Grid item xs={12} sm={6}>
                        <Box sx={{
                            display:'flex',
                            flexDirection:"column",
                            gap:1,
                            alignItems:'center',
                            padding:'1rem 2rem',
                            backgroundColor:'#5BC17E',
                            color:'white',
                            borderRadius:3.5,
                            cursor:'pointer',
                            boxSizing:'border-box',
                            
                            }}
                        onClick={()=>navigate('/add-funds')}
                    >

                        <Avatar sx={{background:'#FFFFFF',width:30,height:30}}><PlusIcon/></Avatar>
                        <span>Add Funds</span>
                    </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box  onClick={()=>navigate('/application')} sx={{
                            display:'flex',
                            flexDirection:"column",
                            gap:1,
                            alignItems:'center',
                            padding:'1rem 2rem',
                            backgroundColor:'#E5EAFF',
                            color:'#7897FE',
                            borderRadius:3.5,
                            cursor:'pointer',
                            boxSizing:'border-box',
                            
                            }}>
                        <TestServiceIcon width={30} height={30} fill={'#7897FE'}/>
                        <span>Test Service</span>
                        </Box>
                    </Grid>                
  
                </Grid>
            </Grid>
            <Grid item container spacing={2} sx={{mt:4}}>
                <Grid item xs={12} >
                    <LinkSection link={'/history/orders'} icon={<UsageHistoryIcon fill={'#202020'}  />} arrow={"#202020"} text="Order History" avatar={'#EBEBEB'} background="#FAFBFB" color={"#202020"}/>
                </Grid>
                <Grid item xs={12}>
                <LinkSection link={'/support/tickets/ticket-list'} icon={<TicketIcon fill={'#202020'}  />} arrow={"#202020"} text="Tickets" avatar={'#EBEBEB'} background="#FAFBFB" color={"#202020"}/>
                </Grid>
                <Grid item xs={12}>
                    <LinkSection link={'https://app.metabypass.tech/docs.html'} out={true} icon={<APIDocIcon fill={'#202020'}  />} arrow={"#202020"} text="API Documentation" avatar={'#EBEBEB'} background="#FAFBFB" color={"#202020"}/>
                </Grid> 
            </Grid>
        </Grid></Card>
    )   

}

export default UserForm