import{Grid,Typography,TextField,Button, Card, Divider, CircularProgress} from '@mui/material'
import TicketButton from '../../ui/ticket-button'
const TicketForm = ({submitLoading,department,category,customMessage,onSubmit,ticketOption,onSelectDepartment,message,onSelectCategory,categoryList,customMessageList,onSelectCutomMessage,onChangeMessage})=>{
    
    return(
    <Card type="card" sx={{height:'100%',p:'1.4rem'}}>
    <Grid container spacing={1} sx={{height:'100%'}}>
        <Grid item xs={12}>
            <Typography color="#202020" variant={'h2'} fontWeight={600}>
                Need Help ?
            </Typography>
            <Typography color="#787777" variant="body1">
                If you have problem select an item
            </Typography>
            <Divider sx={{my:2}}/>
        </Grid>
        <Grid item xs={12} container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="body1" color="#787777">
                    Select Department
                </Typography>
            </Grid>
            {
                ticketOption.map((item,index)=>{
                    return(
                        <Grid key={index} item xs={'auto'}>
                            <TicketButton 
                                text={item.operator_name}
                                color={item.operator_name === department ? '#FFFFFF' : "#0A1b31"} 
                                backgroundColor={item.operator_name === department ? "#0A1b31" :"#FFFFFF"}
                                borderColor="#0A1b31"
                                callBack={onSelectDepartment}
                            />
                         </Grid>
                    )
                })
            }
        </Grid>
        <Grid item xs={12} container  spacing={1}>
            <Grid item xs={12}>
                <Typography variant="body1" color="#787777">
                    Select Category
                </Typography>
            </Grid>
            {
                categoryList.map((item,index)=>{

                    return(
                        <Grid key={index} item xs={'auto'}>
                            <TicketButton 
                                    text={item.name} 
                                    color={item.name === category ? '#FFFFFF' : "#0A1b31"} 
                                    backgroundColor={item.name === category ? "#0A1b31" :"#FFFFFF"}
                                    borderColor="#0A1b31"
                                    callBack={onSelectCategory}
                                />
                        </Grid>
                    )
                })
            }
        </Grid>
        <Grid item xs={12} container  spacing={1}>
        <Grid item xs={12}>
                <Typography variant="body1" color="#787777">
                    Select Message
                </Typography>
            </Grid>

            {
                customMessageList.map((item,index)=>{
                    if(item.length>0)
                    return(
                        <Grid key={index} item xs={'auto'}>
                            <TicketButton 
                                text={item[0]}
                                color={item[0] === customMessage ? '#FFFFFF' : "#0A1b31"} 
                                backgroundColor={item[0] === customMessage ? "#0A1b31" :"#FFFFFF"}
                                borderColor="#0A1b31"
                                callBack={onSelectCutomMessage}
                                />
                        </Grid>
                    )
                })
            }
            <Grid item xs={'auto'}>
                <TicketButton 
                    text="Custom message" 
                    color={'Custom message' === customMessage ? '#FFFFFF' : "#0A1b31"} 
                    backgroundColor={'Custom message' === customMessage ? "#0A1b31" :"#FFFFFF"}
                    borderColor="#0A1b31"
                    callBack={onSelectCutomMessage}
                />
            </Grid>
        </Grid>
        <Grid item xs={12} sx={{mt:2}}>
            <TextField
                multiline
                rows={4}
                sx={{
                    
                    backgroundColor:'#FAFBFB'
                }}
                label={'Write message'}
                fullWidth
                value={message}
                onChange={onChangeMessage}
            />
        </Grid>
        <Grid item xs={12} sx={{mt:2}}>
            {
                submitLoading ? <CircularProgress/>:
            
            <Button onClick={onSubmit} variant="contained" sx={{minWidth:'120px',textTransform:'capitalize'}}>
                Submit
            </Button>
}
        </Grid>
    </Grid></Card>)
}

export default TicketForm