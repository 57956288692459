import Chart from "react-apexcharts";
const LinearChart = (props) => {
  const x = props.data.map((item) => item.human_day);
  const total = props.data.map((item) => item.total);
  const success = props.data.map((item) => item.success);
  const failed = props.data.map((item) => item.error);
  const pending = props.data.map((item) => item.pending);
  const series = [
    {
      name: "Total",
      data: total,
    },
    {
      name: "Suucess",
      data: success,
    },
    {
      name: "Error",
      data: failed,
    },
    {
      name: "Pending",
      data: pending,
    },
  ];
  const options = {
    colors: ["#7897FE", "#1BC167", "#C11B1B",'#e5de00'],

    xaxis: {
      categories: x,
    },
  };
  return <Chart type="line" series={series} options={options} height={"420px"} />;
};

export default LinearChart;
