import { useState, useEffect, useCallback } from "react";
import useHttp from "../../hook/useHttp";
import { userOrder as userOrderUrl } from "../../extentions/apiUrls";
import UserOrderTable from "./UserOrderTable";
import Divider from "@mui/material/Divider";
import DialogComponent from "../layout/DialogComponent";
import { Typography, Box, CircularProgress, Grid, Card } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

import Title from "../layout/Title";
let initialPagination = {
  current: 1,
  lastPage: 1,
};
const Bullet = styled("div")((props) => ({
  "&::before": {
    color: props.color ? props.color : "#7F8081",
    content: "'\\2022'",
    fontSize: "24px",

    fontWeight: "bold",
  },
}));

const Values = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.primary.text,
}));
const UserOrder = () => {
  const [pagination, setPagination] = useState(initialPagination);
  const [userOrder, setUserOrder] = useState(undefined);
  const [openDialog, setOpenDialog] = useState(false);
  const { isLoading, sendRequest: getUserOrderRequest } = useHttp();
  const [dialogContent, setDialogContet] = useState(undefined);

  const handleGetUserOrderRequest = (res) => {
    setUserOrder(res.data.data.data);
    setPagination((prevState) => {
      return {
        ...prevState,
        lastPage: res.data.data.last_page,
      };
    });
  };
  const handleRowClick = (id) => {
    const selectedOrder = userOrder.filter((item) => item.id === id)[0];
    const content = (
      <>
        <Box sx={{ width: "100%", justifyContent: "end", display: "flex" }}>
          <CloseIcon sx={{ cursor: "pointer" }} onClick={handleCloseDialog} />
        </Box>
        <Grid container>
          <Grid item xs={12}>
            <Title>{selectedOrder.plan.name}</Title>
          </Grid>
        </Grid>
        <Divider sx={{ width: "100%" }} />
        <Grid container sx={{ p: 1 }}>
          <Grid item xs={12}>
            <Typography component="span" variant={"h6"} sx={{ fontWeight: 500 }}>
              UUID:
            </Typography>
            <Typography component="span" variant={"h6"} sx={{ ml: 1, fontWeight: 100 }}>
              {selectedOrder.uuid}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ width: "100%" }} />
        <Grid container sx={{ p: 1 }}>
          <Grid item xs={12} md={8}>
            <Typography component="span" variant={"h6"} sx={{ fontWeight: 500 }}>
              Price:
            </Typography>
            <Typography component="span" variant={"h6"} sx={{ ml: 1, fontWeight: 100 }}>
              {selectedOrder.plan_price}$
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography component="span" variant={"h6"} sx={{ fontWeight: 500 }}>
              Remaining:
            </Typography>
            <Typography component="span" variant={"h6"} sx={{ ml: 1, fontWeight: 100 }}>
              {selectedOrder.remaining_money ? selectedOrder.remaining_money + '$' : '-'}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ width: "100%" }} />
        <Grid container sx={{ p: 1 }}>
          <Grid item xs={12} md={8} sx={{ display: "flex", alignItems: "center" }}>
            <Typography component="span" variant={"h6"} sx={{ fontWeight: 500 }}>
              Purchased Date:
            </Typography>
            <Typography component="span" variant={"h6"} sx={{ ml: 1, fontWeight: 100 }}>
              {new Date(selectedOrder.created_at * 1000).toLocaleString()}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: "flex", alignItems: "center" }}>
            <Typography component="span" variant={"h6"} sx={{ fontWeight: 500 }}>
              Status:
            </Typography>

            <Typography component="span" variant={"h6"} sx={{ ml: 1, fontWeight: 100 }}>
              <Values>
                <Bullet
                  color={
                    selectedOrder?.status === "actived"
                        ? "#6CDBB2"
                        : selectedOrder?.status === "inactived"
                        ? "#FF6886"
                        : selectedOrder?.status === "processing"
                        ? "#7897FE"
                        : selectedOrder?.status === 'canceled'
                        ? 'red'
                        : selectedOrder?.status === "new"
                        ? '#e5e1e1'
                        : selectedOrder?.status === "reserved"
                        ? "orange"
                        : selectedOrder?.status === "pending"
                        ? "yellow"
                        : selectedOrder?.status === "expired"
                        ? 'ff7400'
                        : "#7897FE"
                    }
                />
                <span>{selectedOrder.status}</span>
              </Values>
            </Typography>
          </Grid>
        </Grid>
      </>
    );
    setOpenDialog(true);
    setDialogContet(content);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handlePageChange = (event, value) => {
    setPagination((prevState) => {
      return {
        ...prevState,
        current: value,
      };
    });
    fetchData(value);
  };

  const fetchData = (page = 1) => {
    let options = {
      method: "GET",
      url: userOrderUrl,
      params: { page: page },
    };

    getUserOrderRequest(options, handleGetUserOrderRequest);
  }

  useEffect(() => {
    fetchData();
  }, [getUserOrderRequest]);

  return userOrder === undefined ? (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
      <CircularProgress />
    </Box>
  ) : (
    <Grid container>
      <Grid item xs={12} md={11}>
        <Card type="card">
          <Grid container>
            <Grid item xs={12}>
              <UserOrderTable isLoading={isLoading} handleRowClick={handleRowClick} orders={userOrder} />
              <DialogComponent open={openDialog} handleClose={handleCloseDialog} content={dialogContent} />
            </Grid>
            <Grid item xs={12} sx={{ p: 4 }}>
              <Pagination
                sx={{ display: "flex", justifyContent: "center" }}
                count={pagination.lastPage}
                page={Number(pagination.current)}
                onChange={handlePageChange}
                shape="rounded"
                color="primary"
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default UserOrder;
