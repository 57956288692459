import { Typography } from "@mui/material"



const Title = (props)=>{

    return(
        <>
            <Typography component={'div'} variant="title"  sx={{display:'flex',mt:1,alignItems:'center'}} >
                <span>{props.children}</span>
                
            </Typography>
            
           
        </>
        
    )
}

export default Title