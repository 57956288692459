import {
  Avatar,
  Card,
  Grid,
  Box,
  Typography,
  Divider,
  Switch,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import WalletIcon from "../icons/WalletIcon";
import { activeCurrency } from "../../extentions/appConfig";
import TrendIcon from "../icons/TrendIcon";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Title from "../layout/Title";
const Image = styled(Avatar)((props) => ({
  width: 72,
  height: 72,
  backgroundColor: props.back,
  position: "absolute",
  top: 20,
  right: 20,
}));
const Bullet = styled("div")((props) => ({
  "&::before": {
    color: props.color ? props.color : "#7F8081",
    content: "'\\2022'",
    fontSize: "24px",

    fontWeight: "bold",
  },
}));

const Values = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: theme.palette.primary.text,
}));
const ProfileForm = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={11}>
        <Grid container spacing={3}>
          <Grid component={"div"} item xs={12} md={6}>
            <Card type="card" sx={{ position: "relative", height: "100%" }}>
              <Image back={"#E5FFF0"}>
                <WalletIcon back={"#6CDBB2"} />
              </Image>
              <Typography sx={{ mt: 2, fontWeight: "bold" }} component={"div"} color="primary.text" variant="h4">
                Wallet
              </Typography>
              <Typography sx={{ mt: 1 }} component={"div"} color="primary.text" variant="body1">
                Balance
              </Typography>
              <Typography sx={{ fontWeight: 500 }} component={"div"} color="#6CDBB2" variant="h1">
                {activeCurrency} {props?.data?.funds?.total_remaining_money}
              </Typography>
              <Typography sx={{ mt: 1 }} component={"div"} color="primary.text" variant="body2">
                Created At : {new Date(props?.data?.funds?.wallet?.created_at * 1000).toLocaleString()}
              </Typography>
              <Typography sx={{ mt: 1 }} component={"div"} color="primary.text" variant="body2">
                Updated At : {new Date(props?.data?.funds?.wallet?.updated_at * 1000).toLocaleString()}
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography component={"div"} variant={"body1"} sx={{ flexGrow: 1, width: "80%" }}>
                  This option will lock your wallet balance account and only use your package balance when use services.
                </Typography>
                <Switch
                  checked={props?.settings?.wallet?.lock}
                  onChange={props?.onWalletChange}
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "#6CDBB2",
                    },
                    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                      backgroundColor: "lightgreen",
                    },
                  }}
                />
              </Box>
            </Card>
          </Grid>
          <Grid component={"div"} item xs={12} md={6} sx={{ mt: { xs: 3, md: 0 } }}>
            <Card type="card" sx={{ position: "relative", height: "100%" }}>
              <Image back={"#FFF9E5"}>
                <TrendIcon />
              </Image>
              <Typography sx={{ mt: 2, fontWeight: "bold" }} component={"div"} color="primary.text" variant="h4">
                Activity
              </Typography>
              <Typography sx={{ mt: 1 }} component={"div"} color="primary.text" variant="body1">
                Total Request
              </Typography>
              <Typography sx={{ fontWeight: 500 }} component={"div"} color="#dbb26c" variant="h1">
                {props?.data?.service_inquiries?.all_count}
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Grid container>
                <Grid item xs={12} md={7} lg={6} xl={6}>
                  <Values>
                    <Bullet color={"#DBB26C"} />
                    <Typography sx={{ ml: 1 }} variant="body2" component={"div"}>
                      service request(s):
                    </Typography>
                    <Typography component={"div"} variant={"body2"} sx={{ fontWeight: "normal !important" }}>
                      {props?.data?.service_inquiries?.all_count}
                    </Typography>
                  </Values>
                </Grid>
                <Grid item xs={12} md={9} lg={6} xl={6}>
                  <Values>
                    <Bullet color={"#6C86DB"} />
                    <Typography sx={{ ml: 1 }} variant="body2" component={"div"}>
                      last request(s) within 24 hours :
                    </Typography>
                    <Typography component={"div"} variant={"body2"} sx={{ fontWeight: "normal !important" }}>
                      {props?.data?.service_inquiries?.latest_count}
                    </Typography>
                  </Values>
                </Grid>
                <Divider sx={{ my: 2, width: "100%" }} />
                <Grid item xs={12} lg={6} xl={6}>
                  <Values>
                    <Bullet color={"#6CDBB2"} />
                    <Typography sx={{ ml: 1 }} variant="body2" component={"div"}>
                      Successful Request(s):
                    </Typography>
                    <Typography component={"div"} variant={"body2"} sx={{ fontWeight: "normal !important" }}>
                      {props?.data?.service_inquiries?.success_count}
                    </Typography>
                  </Values>
                </Grid>

                <Grid item xs={12} lg={6} xl={6}>
                  <Values>
                    <Bullet color={"#ED2057"} />
                    <Typography sx={{ ml: 1 }} variant="body2" component={"div"}>
                      Failed Request(s):
                    </Typography>
                    <Typography component={"div"} variant={"body2"} sx={{ fontWeight: "normal !important" }}>
                      {props?.data?.service_inquiries?.error_count}
                    </Typography>
                  </Values>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={11} sx={{ mt: 5 }}>
        <Card type="card">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Title>Profile</Title>

              <Divider sx={{ mb: 3 }} />
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="customer-type">Customer Type:</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="Customer-type"
                  name="legal"
                  onChange={props.handleChange}
                  value={props.data?.user?.legal * 1}
                >
                  <FormControlLabel value={0} control={<Radio />} label="real" />
                  <FormControlLabel value={1} control={<Radio />} label="legal" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={7} md={6}>
              <TextField
                variant="outlined"
                value={props.data?.user?.first_name || ""}
                name="first_name"
                label={"First Name"}
                fullWidth
                size="small"
                onChange={props.handleChange}
                error={
                  props.data?.user?.legal == 0 &&
                  (props.data?.user?.first_name === "" || props.data?.user?.first_name === null)
                }
              />
            </Grid>
            <Grid item xs={12} sm={7} md={6}>
              <TextField
                variant="outlined"
                value={props.data?.user?.last_name || ""}
                name="last_name"
                label={"Last Name"}
                onChange={props.handleChange}
                fullWidth
                size="small"
                error={
                  props.data?.user?.legal == 0 &&
                  (props.data?.user?.last_name === "" || props.data?.user?.last_name === null)
                }
              />
            </Grid>
            <Grid item xs={12} sm={7} md={6}>
              <TextField
                variant="outlined"
                value={props.data?.user?.company || ""}
                name="company"
                label={"Company"}
                onChange={props.handleChange}
                fullWidth
                size="small"
                error={
                  props.data?.user?.legal == 1 &&
                  (props.data?.user?.company === null || props.data?.user?.company === "")
                }
              />
            </Grid>
            <Grid item xs={12} sm={7} md={6}>
              <TextField
                variant="outlined"
                value={props.data?.user?.country || ""}
                name="country"
                label={"Country"}
                onChange={props.handleChange}
                fullWidth
                size="small"
                error={props.data?.user?.country === null || props.data?.user?.country === ""}
              />
            </Grid>
            <Grid item xs={12} sm={7} md={6}>
              <TextField
                variant="outlined"
                value={props.data?.user?.postal_code || ""}
                name="postal_code"
                label={"Zip/Postal Code"}
                onChange={props.handleChange}
                fullWidth
                size="small"
                error={props.data?.user?.postal_code === null || props.data?.user?.postal_code === ""}
              />
            </Grid>
            <Grid item xs={12} sm={7} md={6}>
              <TextField
                variant="outlined"
                value={props.data?.user?.city || ""}
                name="city"
                label={"City"}
                onChange={props.handleChange}
                fullWidth
                size="small"
                error={props.data?.user?.city === null || props.data?.user?.city === ""}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                value={props.data?.user?.tax_number || ""}
                name="tax_number"
                label={"Tax Number"}
                onChange={props.handleChange}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                value={props.data?.user?.address || ""}
                name="address"
                label={"Address Line"}
                onChange={props.handleChange}
                fullWidth
                size="small"
                multiline
                rows={5}
                error={props.data?.user?.address === null || props.data?.user?.address === ""}
              />
            </Grid>
            <Grid item xs={12} sm={'auto'}>
              {props.saveLoading ? (
                <CircularProgress />
              ) : (
                <Button sx={{maxWidth:'100%'}} variant="contained" fullWidth disabled={!props.shouldUpdate} onClick={props.onSave}>
                  save
                </Button>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ProfileForm;
