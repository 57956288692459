const WalletIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : "35"}
      height={props.height ? props.height : "35"}
      viewBox="0 0 45.301 42.022"
    >
      <g
        fill="none"
        stroke={props.back ? props.back : "#6c86db"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        transform="translate(1.25 1.25)"
      >
        <path fillRule="evenodd" d="M42.801 25.485h-9.053a6.019 6.019 0 110-12.037h9.053" data-name="Path 1"></path>
        <path d="M0.698 0L0 0" data-name="Line 1" transform="translate(34.075 19.329)"></path>
        <path
          fillRule="evenodd"
          d="M11.736 0h19.33A11.736 11.736 0 0142.8 11.736v16.05a11.736 11.736 0 01-11.735 11.736H11.736A11.736 11.736 0 010 27.786v-16.05A11.736 11.736 0 0111.736 0z"
          data-name="Path 2"
        ></path>
        <path d="M0 0L12.074 0" data-name="Line 2" transform="translate(10.144 10.149)"></path>
      </g>
    </svg>
  );
};

export default WalletIcon;
