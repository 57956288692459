

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/material";
import AppbarLogo from "../icons/AppbarLogo";
const Appbar = (props) => {
  const AdminAppBar = (
    <AppBar
      position="fixed"
      sx={{ boxShadow: 1, background: "#fff", height: "56px", display: { xs: "block", md: "none" } }}
    >
      <Toolbar>
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={props.handleDrawerToggle}
          sx={{ mr: 2, display: { md: "none" }, color: "primary.main" }}
        >
          <MenuIcon />
        </IconButton>

        <Box sx={{ flexGrow: 1, display: "inline-grid", justifyContent: "end" }}>
          <AppbarLogo />
        </Box>
      </Toolbar>
    </AppBar>
  );
  return AdminAppBar;
};

export default Appbar;
