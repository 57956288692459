

export default function PlanListIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.408"
      height="16.864"
      viewBox="0 0 15.408 16.864"
    >
      <g
        fill="none"
        stroke={props.fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        transform="translate(.75 .75)"
      >
        <path
          d="M6.025 0L0 0"
          data-name="Line 6"
          transform="translate(3.96 11.244)"
        ></path>
        <path
          d="M6.025 0L0 0"
          data-name="Line 7"
          transform="translate(3.96 7.751)"
        ></path>
        <path
          d="M2.299 0L0 0"
          data-name="Line 8"
          transform="translate(3.96 4.265)"
        ></path>
        <path
          fillRule="evenodd"
          d="M10.149 0l-6.42.003A3.547 3.547 0 000 3.845v7.674a3.55 3.55 0 003.76 3.845h6.417a3.549 3.549 0 003.73-3.842V3.845A3.551 3.551 0 0010.149 0z"
          data-name="Path 7"
        ></path>
      </g>
    </svg>
  );
}


