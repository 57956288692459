import {Typography,Grid} from '@mui/material';

const RegisterConfirmation = (props)=>{

    return(
        <Grid container sx={{p:2}}>
            <Grid item xs={12} sx={{marginBottom:'24px'}}>
                <Typography component="div" variant='h6'>
                    Registration Completed
                </Typography>
                <Typography component="div" variant='subtitle1' sx={{p:1}}>
                   Activation Email has been sent to {props.email}
                </Typography>
            </Grid>
        </Grid>
    )


}

export default RegisterConfirmation;