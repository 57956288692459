export default function TestServiceIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : "15.407"}
      height={props.height ? props.height : "12.93"}
      viewBox="0 0 15.407 12.93"
    >
      <g
        fill="none"
        stroke={props.fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        transform="translate(.75 .75)"
      >
        <path
          fillRule="evenodd"
          d="M12.682 3.473v-.73A2.737 2.737 0 009.938 0h-.527M1.254 3.473v-.73A2.737 2.737 0 013.988 0h.552m8.141 6.261v2.426a2.737 2.737 0 01-2.743 2.743h-.527M1.254 6.261v2.426a2.737 2.737 0 002.743 2.743h.546"
          data-name="Path 5"
        ></path>
        <path d="M13.907 0L0 0" data-name="Line 3" transform="translate(0 6.261)"></path>
      </g>
    </svg>
  );
}
