import {useState,useEffect,useContext} from 'react'
import {useLocation,useNavigate} from "react-router-dom";
import PasswordResetForm from "./PasswordResetForm"
import InValidPasswordKey from './InValidPasswordKey';
import useHttp from "../../hook/useHttp";
import {checkPasswordReset,updatePasswordReset} from '../../extentions/apiUrls';
import CircularProgressComponent from '../layout/CirCularProgressComponent';
import SnackContext from '../../store/snack-context';
const PasswordReset = ()=>{
    const[valid,setValid] = useState(false)
    const[hash,setHash] = useState(null)
    const[password,setPassword]=useState('')
    const[confirmPassword,setConfirmPassword]=useState('')
    const[recaptcha,setRecaptcha]=useState(null)
    let navigate = useNavigate()
    const snackCtx = useContext(SnackContext)
    const { search } = useLocation();
    const params =new URLSearchParams(search)
    const{isLoading,sendRequest:sendResetPasswordRequest} = useHttp()
    const key = params.get('key')

    

    useEffect(
        ()=>{
            const checkPasswordRequestHandler = (res)=>{
                
                if(res.data.status_code === 200)
                {   
                    setHash(res.data.data.hash_key)
                    setValid(true)
                }
                else
                {
                   setValid(false)
                   snackCtx.handleOpen(res.data.message)
                }
            }
            
            const options ={
                method:'POST',
                url:checkPasswordReset,
                data:{key:key}
            }
            sendResetPasswordRequest(options,checkPasswordRequestHandler)
            
        }
        ,[sendResetPasswordRequest])

    const passwordChangeHandler = (e)=>{
        setPassword(e.target.value)
    }
    const confirmPasswordChangeHandler = (e)=>{
        setConfirmPassword(e.target.value)
    }

    const recaptchaChangleHandler = (res)=>{
        setRecaptcha(res)
    }
    const resetPasswordHandler = ()=>{
      
        if(password === '')
        {

            snackCtx.handleOpen('Please enter password')
        }
      
        else if(confirmPassword === password )
        {
            const resetPasswordRequestHandler = (res)=>{

                if(res.data.status_code === 200)
                {   
                    navigate('/dashboard',{state:{showModal:true},replace:true})
                }
                else
                {
                  snackCtx.handleOpen(res.data.message)
                }
            }
            
            const options ={
                method:'PUT',
                url:updatePasswordReset,
                data:{password,confirm_password:confirmPassword,key:key,g_response:recaptcha}
            }

            sendResetPasswordRequest(options,resetPasswordRequestHandler,hash)
        }
        else
        {
            snackCtx.handleOpen('password and confirm password must be same!')
        }
    }

    return(
        
            isLoading 
                ? 
                    <CircularProgressComponent/>
                :
                    valid
                        ?
                            <PasswordResetForm 
                                onPasswordChange={passwordChangeHandler} 
                                onConfirmPasswordChange={confirmPasswordChangeHandler}  
                                password={password}
                                confirmPassword={confirmPassword}
                                onResetPassword={resetPasswordHandler}
                                recaptchaChangleHandler={recaptchaChangleHandler}
                            />
                        :
                            <InValidPasswordKey/>
    )
}

export default PasswordReset