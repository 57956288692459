import { Grid, Typography, Stack, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

const Text = styled(Typography)(({ theme }) => ({
  fontSize: '0.875em',
  color: 'GrayText',
}));
const Number = styled('span')(({ theme }) => ({
  fontSize: '1.275em',
  color: 'black',
}));

const Terms = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Stack spacing={2} divider={<Divider flexItem />} sx={{ my: 2 }}>
          <Text>
            <Number>1. </Number>
            Prices are quoted in US dollars.
          </Text>
          <Text>
            <Number>2. </Number>
            Payment may made by credit card, debit card or any other method.
          </Text>
          <Text>
            <Number>3. </Number>
            Metabypass reserves the absolute right to accept or refuse any
            payment made in any form.
          </Text>
          <Text>
            <Number>4. </Number>
            You will not be charged for any Orders that cannot be fulfilled and
            where appropriate Metabypass will refund to your account any sum
            deducted by Metabypass.
          </Text>
          <Text>
            <Number>5. </Number>
            To maximise security Metabypass does not hold any credit/debit card
            details.
          </Text>

          <Text>
            <Number>7. </Number>
            If you are using a free version of one of our Services it is really
            free we dont ask you for your credit card.
          </Text>
          <Text>
            <Number>8. </Number>
            If you are upgrading from a free plan to a paid plan we weill charge
            your card immediately and your billing cycle starts on the day of
            upgrade.
          </Text>
          <Text>
            <Number>9. </Number>
            Your statutory rights are unaffected by these terms.
          </Text>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Terms;
