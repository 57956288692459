import { Box, Typography, Tooltip } from "@mui/material";
import BoostIcon from "../../../icons/BoostIcon";

const PaymentCard = ({ checked, icon, name, callback, self, boost, disabled, disabledText }) => {
  return (
    <Tooltip title={disabledText} placement="right-end">
      <Box
        onClick={() => {
          if (disabled) return;
          callback(self);
        }}
        sx={{
          border: disabled ? "2px solid #838282" : checked ? "2px solid #5BC17E" : "2px solid #EEEEEE",
          p: 2,
          borderRadius: 2,
          cursor: "pointer",
          minHeight:'80px',
          boxSizing:'border-box',
          height:'100%',
          backgroundColor: disabled ? "#e0e0e0" : checked ? "#E3F5E9" : "white",
        
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src={icon} alt={"ICON"} width={45} height={45} />
            </Box>
            <Box>
              <Typography component={"span"} variant="h5" sx={{ fontWeight: 400, textTransform: "capitalize" }}>
                {name}
              </Typography>
              
              {boost > 0 && (
                <Box sx={{mt:2}}>
                  <Typography fontSize={"11px"}>
                    Your Wallet will be <span style={{ color: "#DBB26C", fontWeight: "bolder" }}>{boost}x</span> charged by
                    this payment method
                  </Typography>
                </Box>
              )}
              {self?.attrs.fee !== null ? (
                <div style={{ display: "flex" }}>
                  <Typography
                    component={"span"}
                    fontWeight="bold"
                    sx={{
                      textAlign: "center",
                      p: 0.3,
                      fontSize: "8px",
                    }}
                  >
                    {`Fee: ${self?.attrs?.fee}%`}
                  </Typography>
                </div>
              ) : null}
            </Box>
          </Box>

          {/* {boost > 0 && (
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
              {boost > 0 ? <BoostIcon /> : null}
              <span style={{ position: "absolute", color: "white", fontWeight: "bold", fontSize: "12px" }}>
                {boost}x
              </span>
            </Box>
          )} */}
        </Box>
      </Box>
    </Tooltip>
  );
};

export default PaymentCard;
