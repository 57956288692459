import Badge from "@mui/material/Badge";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";

import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { NoResultToShow } from "../../extentions/imagesUrl";
import { Divider, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Title from "../layout/Title";

const TicketTable = (props) => {
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      left: -13,
      top: 10,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
    },
  }));

  const createTableHeader = (props) => {
    return (
      <TableHead>
        <TableRow>
          <TableCell align="center">Row</TableCell>
          <TableCell align="center">Subject</TableCell>
          <TableCell align="center">Status</TableCell>
          <TableCell align="center">Section</TableCell>
          <TableCell align="center">Date</TableCell>
          <TableCell align="center">Priority</TableCell>
          <TableCell align="center">View</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const createTableBody = () => {
    const tableRows = props.tableRows.map((item, idx) => {
      return (
        <TableRow key={idx} onClick={(e) => props.onRowClick(item.id)} style={{ cursor: "pointer" }}>
          <TableCell align="center">{idx + 1}</TableCell>
          <TableCell align="center">
            <StyledBadge
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              color="primary"
              badgeContent={item?.unread_messages}
              invisible={false}
            >
              {item.title}
            </StyledBadge>
          </TableCell>
          <TableCell align="center">{item.status}</TableCell>
          <TableCell align="center">{item.section}</TableCell>
          <TableCell align="center">{new Date(item.created_at * 1000).toLocaleString()}</TableCell>
          <TableCell align="center">
            <Button variant="status" color={item?.priority}>
              {item?.priority}
            </Button>
          </TableCell>
          <TableCell align="center">
            {
              <Button variant="table" onClick={(e) => props.onRowClick(item.id)}>
                Show
              </Button>
            }
          </TableCell>
        </TableRow>
      );
    });

    return <TableBody>{tableRows}</TableBody>;
  };

  const renderTable = () => {
    if (props.isLoading) {
      return (
        <>
          <Table>{createTableHeader()}</Table>
          <div style={{ overflow: "hidden", marginTop: "5vh", display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        </>
      );
    }

    if (props.tableRows.length === 0) {
      return (
        <>
          <Table>{createTableHeader()}</Table>
          <div style={{ overflow: "hidden", marginTop: "5vh", display: "flex", justifyContent: "center" }}>
            <img src={NoResultToShow} width={300} height={"auto"} alt="No Result" />
          </div>
        </>
      );
    } else {
      return (
        <Table>
          {createTableHeader()}
          {createTableBody()}
        </Table>
      );
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <Title>Ticket List</Title>
          </Box>
          <Button
            component={Link}
            to={"/support/tickets/create-ticket"}
            variant="contained"
            endIcon={<AddIcon />}
            fullWidth
          >
            Create Ticket
          </Button>
        </Box>

        <Divider sx={{ mb: 2 }} />

        <Grid item xs={12} sx={{ p: 1 }}>
          <TableContainer>{renderTable()}</TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TicketTable;
