




const useKeyPress = ()=>{

    const keyPressHandler = (event,key,fn)=>{
        let op = false
        if(Array.isArray(key))
            for (let index = 0; index < key.length; index++) {
                if(key[index] === event.code){
                    op = true
                    break
                }
                
            }
        else
            if(key === event.code)
                op=true
        if(op)
        {
            fn()
        }

    }

    return{
        keyPressHandler,
    }

}

export default useKeyPress