import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  Box,
  Divider,
} from '@mui/material';
import React from 'react';
import { useState } from 'react';

function DialogContent({ onConfirm, onCancel }) {
  const [enable, setEnable] = useState(false);
  const onChangeHandler = (e) => {
    setEnable(e.target.checked);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">
          Payment Terms and Conditions for Metabypass
        </Typography>
        <Typography variant="h6" sx={{ my: 2 }}>
          Last Updated: October 1, 2023
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} sx={{ height: '500px', overflowY: 'auto' }}>
          <Grid item xs={12}>
            <Typography variant="caption">
              1. Acceptance of Terms By using the Metabypass platform and its
              services, you agree to the following Payment Terms and Conditions.
              If you do not agree with these terms, please refrain from using
              our platform.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              2. Payment Authorization By making a deposit on Metabypass, you
              authorize us to charge the specified payment method for the
              agreed-upon amount, which will be credited to your Metabypass
              wallet in USD.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              3. Pricing and Currency All prices are displayed in USD. We
              reserve the right to change pricing or add fees, which will be
              clearly communicated to you.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              4. Payment Methods We accept the following payment methods:
              Stripe, PayPal, Bitcoin, Ethereum, and Tether. Please be aware of
              any limitations or restrictions on payment methods.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              5. No Refund Policy Please note that we do not offer refunds. All
              payments made are final and non-refundable.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              6. Digital Products Metabypass exclusively offers digital
              products. No physical delivery is involved.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              7. Dispute Resolution If you encounter any issues, please contact
              our support team at [support@metabypass.tech] to resolve the
              matter before filing a dispute with your bank or credit card
              company.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              8. Contact Information You can reach our customer support team at
              [support@metabypass.tech] for any inquiries, concerns, or
              assistance.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              9. Terms of Use and Privacy Policy These Payment Terms and
              Conditions are subject to our platform's Terms of Use and Privacy
              Policy. Please review these documents for additional information
              on using our services and data handling.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              10. Customer Education We provide educational materials and FAQs
              on our platform to help you understand our digital products and
              services better and to assist in resolving common issues.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              11. Customer Service Response Time We aim to respond to customer
              inquiries or complaints within a reasonable time during our
              regular business hours.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              12. Compliance with Regulations We comply with all relevant laws
              and regulations.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              13. Updates to Terms We reserve the right to update these Payment
              Terms and Conditions. Any changes will be communicated to you, and
              the revised terms will become effective within 7 days after
              posting.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              14. Acknowledgment By using Metabypass and making a deposit, you
              acknowledge that you have read, understood, and agreed to these
              Payment Terms and Conditions, including the no refund policy and
              payments being credited to your Metabypass wallet, for the purpose
              of using our digital services.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ my: 2 }} />
        <FormControlLabel
          control={<Checkbox onChange={onChangeHandler} />}
          label="I'm agree to the terms of Metabypass agreement."
        />
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            gap: 2,
            flexWrap: 'wrap',
          }}>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onConfirm} disabled={!enable}>
            Confrim
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

export default DialogContent;
