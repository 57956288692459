
import { Avatar,Box, Typography,Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import QuestionIcon from '../icons/QuestionIcon';

const Warning = (props)=>{
    return (
        <Grid container spacing={5} sx={{p:5}}>
            <Grid item xs={12}>
                <Box sx={{display:'flex',justifyContent:'center'}}>
                    <Avatar sx={{backgroundColor:'#E5EAFF',width:64,height:64}}>
                        <QuestionIcon/>
                    </Avatar>
                </Box>
            </Grid>
            <Grid item xs={12} >
                <Typography component={'div'} variant={'h3'} sx={{textAlign:'center',fontWeight:'500'}} color="primary.main">
                    {props.caption}
                </Typography>
                
                
            </Grid>
            <Grid item xs={12}>
                <Typography component={'div'} variant={'h6'} sx={{textAlign:'center',fontWeight:'100'}} color="primary.text">
                    {props?.description}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}  justifyContent="center">
                    <Grid item xs={12} sm="auto">
                        <Button variant={props.delete ? "Delete" : "contained"}  onClick={props.onConfirm} sx={{maxWidth:"100%"}} fullWidth>
                            {props.confirmCaption || 'Yes'}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm="auto">
                    {
                        props.haveReject
                         ?
                            <Button 
                                variant="outlined"
                                sx={{
                                    maxWidth:'100%',
                                    '&:hover':{
                                        background:'white',   
                                    }
                                }} 
                                onClick={props.onReject}
                                fullWidth
                             >
                                {props.rejectCaption || 'No'}
                            </Button>
                         :
                          null

                    }
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid item xs={12}>
                <div style={{display:'flex',justifyContent:'center',gap:16}}>
                    <Button variant={props.delete ? "Delete" : "contained"}  onClick={props.onConfirm}>
                        {props.confirmCaption || 'Yes'}
                    </Button>
                    {
                        props.haveReject
                         ?
                            <Button 
                                variant="outlined"
                                sx={{
                                    '&:hover':{
                                        background:'white',   
                                    }
                                }} 
                                onClick={props.onReject}
                             >
                                {props.rejectCaption || 'No'}
                            </Button>
                         :
                          null

                    }
                    
                </div>
            </Grid> */}


        </Grid>        
    )

}

export default Warning;