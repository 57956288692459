import React from 'react';

const ModalContext = React.createContext(
    {
        isOpen:false,
        haveContent:"",
        modalHandler:(content)=>{},
        content:'',
        hasBack:false,
        backContent:'',
        loadBack:()=>{}
    }
);

export default ModalContext;