
import {Outlet} from 'react-router-dom';


const AuthContainer = ()=>{
    

    return(
        <Outlet/>
    )


}

export default AuthContainer;