import { Button, Grid, TextField, CircularProgress, Box } from "@mui/material";
import { useState, useEffect } from "react";
import useHttp from "../../../hook/useHttp";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import moment from "moment";
import { getRequestsChartUrl } from "../../../extentions/apiUrls";
import SearchIcon from "../../icons/SearchIcon";
import LinearChart from "./LinearChart";

const FilterDate = (props) => {
  const [filter, setFilter] = useState("daily");
  const [startDate, setStartDate] = useState(moment().toDate());

  const [endDate, setEndDate] = useState(moment().toDate());
  const [data, setData] = useState(undefined);
  const { sendRequest: getChartStatsRequest } = useHttp();

  const getChartStatsRequestHandler = (res) => {
    if (res.data.status_code === 200) {
      setData(res.data.data);
    }
  };
  useEffect(() => {
    if (filter === "daily") {
      setStartDate(moment().subtract(7, "day"));
    }
    if (filter === "monthly") {
      setStartDate(moment().subtract(7, "month"));
    }
  }, [filter]);

  useEffect(() => {
    let options = {
      method: "GET",
      url: getRequestsChartUrl,
      params: {
        point: filter,
        from_date: moment().subtract(7, "day").unix(),
        to_date: moment(endDate).unix(),
      },
    };
    getChartStatsRequest(options, getChartStatsRequestHandler);
  }, [getChartStatsRequest]);

  const handleStartChange = (newValue) => {
    setStartDate(newValue);
  };
  const handleEndChange = (newValue) => {
    setEndDate(newValue);
  };
  const handleChange = (event) => {
    setFilter(event.target.value);
  };
  const filterHandler = () => {
    let options = {
      method: "GET",
      url: getRequestsChartUrl,
      params: {
        point: filter,
        from_date: moment(startDate).unix(),
        to_date: moment(endDate).unix(),
      },
    };

    getChartStatsRequest(options, getChartStatsRequestHandler);
  };

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} md={3} lg={3} xl={3}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            label="Start Date"
            inputFormat="YYYY-MM-DD"
            value={startDate}
            onChange={handleStartChange}
            renderInput={(params) => <TextField {...params} size="small" fullWidth />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} md={3} lg={3} xl={3}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            label="End Date"
            inputFormat="YYYY-MM-DD"
            value={endDate}
            onChange={handleEndChange}
            renderInput={(params) => <TextField {...params} size="small" fullWidth />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} md={3} lg={3} xl={3}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">filter</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filter}
            label="Filter"
            size="small"
            onChange={handleChange}
          >
            <MenuItem value={"monthly"}>monthly</MenuItem>
            <MenuItem value={"daily"}>daily</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3} lg={3} xl={3}>
        <Button
          variant="contained"
          sx={{ minWidth: "100px !important" }}
          onClick={filterHandler}
          startIcon={<SearchIcon />}
        >
          Filter
        </Button>
      </Grid>
      <Grid item xs={12}>
        {data === undefined ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />{" "}
          </Box>
        ) : (
          <LinearChart data={data} />
        )}
      </Grid>
    </Grid>
  );
};

export default FilterDate;
