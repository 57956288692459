
import {Grid,Card, Box} from '@mui/material'
import { loginImage } from '../extentions/imagesUrl'
import { styled } from '@mui/material/styles';
import {default as AuthForm} from '../components/tab/Auth';
import { brandImage } from '../extentions/imagesUrl';
const Image = styled('img')(({ theme }) => ({
 
    width: '100%',
    maxWidth:'300px',
    height: 'auto'
    
  }));
  const Logo = styled('img')(({ theme }) => ({
 
    width: '100%',
    maxWidth:'200px',
    height: 'auto'
    
  }));
const Auth = ()=>{

    return(
        <Grid container sx={{justifyContent:"center",alignItems:'center'}}>
            <Grid item xs={12} xl={12}>
                <Card sx={{boxShadow:0,borderRadius:0}}>
                    <Grid container sx={{justifyContent:{xs:'center',md:'start'}}} >
                        <Grid item xs={12} sm={4} md={6} lg={8} xl={8} sx={{display:{xs:'none',md:'block'}}}>
                          <Box
                            sx={{
                               
                                backgroundImage:`url('${loginImage}')`,
                                width:'100%',
                                height:'100vh',
                                backgroundSize:'cover',
                                display:'flex',justifyContent:'center',alignItems:'center'
                            }}
                          >
                              <a href='https://www.metabypass.tech'>
                                <Image src={brandImage}/>
                              </a>
                               
                          </Box>
                        </Grid>
                        <Grid item xs={12} sx={{display:{xs:'block',md:'none'},p:1,mb:3}}>
                            <Box sx={{display:'flex',justifyContent:'center'}}>
                                <a href='https://www.metabypass.tech'>
                                    <Logo src={brandImage}/>
                                </a>
                            </Box>
                            
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                            <Grid container  sx={{height:'100vh',p:2,justifyContent:{xs:'center',md:'start'}}} alignItems="center">
                                    <Grid item xs={12} sm={10} md={12} >
                                        <AuthForm/>
                                    </Grid>             
                                  
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
                
            </Grid>
        </Grid>
       
    )

}

export default Auth