function RightArrowIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9.161" height="15.625" viewBox="0 0 9.161 15.625">
      <path
        fill="none"
        stroke={props.color ? props.color : "#fff"}
        strokeWidth="2"
        d="M.714.7l2.919 2.976 2.043 2.083 2.078 2.118-.976.976-2.084 2.084-.088.088-1.238 1.238-2.653 2.653"
        data-name="Path 67"
      ></path>
    </svg>
  );
}

export default RightArrowIcon;
