import { Grid, Typography,Box, Divider, Button } from "@mui/material"
import TableRow from "./table-row"
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import RequestDetailJsonForm from "./json";

const RequestDetailForm = ({onClose,inputs,response})=>{

    const [showJson,setShowJson] = useState(false)
    const data = { "inputs": {...inputs},"response":{...response}}
    const changeCodeHandler = ()=>{

        setShowJson((prev)=>{return !prev})
    }
    return(
        <Grid container sx={{padding:2}}>
            <Grid item xs={12}>
                <Box sx={{ width: "100%", justifyContent: "end", display: "flex",mb:1 }}>
                    <CloseIcon sx={{ cursor: "pointer" }} onClick={onClose} />
                </Box>
                <Divider/>
            </Grid>
            {
                showJson ? <RequestDetailJsonForm json={JSON.stringify(data,null,2)} onClick={changeCodeHandler}/> :
            <>
            <Grid item xs={12} sx={{my:2}}>
                <Typography variant="h6">
                    Inputs
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{border: inputs ? '1px solid gray' : 0,borderBottom:0}}>
                {
                    inputs && Object.keys(inputs).map((key,index)=>{
                        return(
                            <Grid item xs={12} container>
                                <TableRow title={key} value={inputs[key]} img={key==='image'} />
                            </Grid>
                        )
                    })
                }
                
            </Grid>
            <Grid item xs={12} sx={{my:2}}>
                <Typography variant="h6">
                    Response
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{border: response ? '1px solid gray': 0,borderBottom:0}}>
                {
                    
                      response &&  Object.keys(response).map((key,index)=>{
                            return(
                                <Grid item xs={12} container>
                                    <TableRow title={key} value={response[key]} />
                                </Grid>
                            )
                        })
                    
                    
                    
                }
                
            </Grid>
            <Grid item xs={12} sm={'auto'} sx={{mt:2}}>
                <Button onClick={changeCodeHandler} variant="contained" sx={{maxWidth:'100%'}} fullWidth>
                    Show Json
                </Button>
            </Grid>
            </>
            }
        </Grid>
    )

}

export default RequestDetailForm