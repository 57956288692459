import { Grid, TextField,Button,CircularProgress } from "@mui/material"


const LinkSection = (props)=>{

    return(
        <Grid container  rowSpacing={4}>
            <Grid item xs={12} sm={"auto"} >
                <TextField 
                    variant="outlined" 
                    sx={{maxWidth:'500px'}} 
                    label="Link" 
                    fullWidth 
                    value={props.linkValue} 
                    onChange={props.onLinkChange}
                    size={'small'}
                    />
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                <Grid item xs={12} sm={'auto'}>
                {
                     props.isLoading 
                     ?
                         <CircularProgress/>
                     :
                        <Button sx={{maxWidth:"100%"}} variant="contained" onClick={props.onButtonClick} fullWidth>Send Link</Button>
                }
               
            </Grid>
                </Grid>
            </Grid>
            
        </Grid>
    )

}

export default LinkSection