import { useState } from "react";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import GoogleIcon from "../icons/GoogleIcon";
import ReCAPTCHA from "react-google-recaptcha";
import { Divider } from "@mui/material";
const initialFormData = {
  email: "",
  password: "",
};
const LoginForm = (props) => {
  const [formData, setFormData] = useState(initialFormData);
  const [showRecaptcha, setShowRecaptcha] = useState(true);

  const loginClickHandler = (event) => {
    event.preventDefault();
    hideRecaptchaHandler();
    props.onButtonClick(formData.email, formData.password);
  };

  const hideRecaptchaHandler = () => {
    setShowRecaptcha(false);
    setTimeout(() => {
      setShowRecaptcha(true);
    }, 2000);
  };

  return (
    <Grid container spacing={1} sx={{ padding: "4px 24px" }}>
      <Grid item xs={12}>
        <Typography
          gutterBottom
          variant="body1"
          component="div"
          sx={{ fontWeight: "600", color: "landing.darkGray", mt: 3 }}
        >
          Login With Your Account
        </Typography>
      </Grid>
      <Grid item xs={12} md={10} lg={9} sx={{ mt: 2 }}>
        <TextField
          value={props.formData.email}
          id="email"
          label="Email"
          variant="outlined"
          type="email"
          fullWidth
          size="small"
          InputLabelProps={{ fontSize: "12px" }}
          onChange={props.emailChangeHandler}
          sx={{
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#76323F",
            },
            "& .MuiInputLabel-outlined.Mui-focused": {
              color: "#76323F",
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={10} lg={9} sx={{ mt: 1 }}>
        <TextField
          value={props.formData.password}
          id="standard-password-input"
          variant="outlined"
          label="Password"
          type="password"
          size="small"
          InputLabelProps={{ fontSize: "12px" }}
          onChange={props.passwordChangeHandler}
          onKeyUp={(e) => {
            props.onKeyPress(e);
          }}
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#76323F",
            },
            "& .MuiInputLabel-outlined.Mui-focused": {
              color: "#76323F",
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ my: 2 }}>
        {showRecaptcha ? (
          <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITEKEY} onChange={props.handleRecaptcha} />
        ) : null}
      </Grid>
      <Grid item xs={12} md={"auto"}>
        {props.isLoading ? (
          <Box sx={{ color: "#76323F" }}>
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <Button
            variant="contained"
            onClick={loginClickHandler}
            fullWidth
            sx={{
              fontSize: "15px",
              padding: "4px 4px",
              background: "#76323F",
              color: "white",
              borderRadius: 2,
              boxShadow: 0,
              "&:hover": {
                background: "#561f2a",
                boxShadow: 1,
              },
              maxWidth: "100%",
            }}
          >
            Login
          </Button>
        )}
      </Grid>

      <Grid item xs={12} md={"auto"}>
        <Button
          onClick={props.responseGoogle}
          variant="contained"
          startIcon={<GoogleIcon />}
          sx={{
            fontSize: "17px",
            background: "white",
            color: "black",
            padding: "8px 8px !important",
            borderRadius: 2,
            boxShadow: 0,
            border: 1,
            borderColor: "#787777",
            "&:hover": {
              background: "#FFFFFF",
              boxShadow: 1,
            },
            maxWidth: "100%",
          }}
          fullWidth
        >
          Login By Google
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography
          onClick={props.onForgetPasswordClick}
          component={"div"}
          variant="body2"
          sx={{ textAlign: "center", mt: 2, cursor: "pointer", color: "landing.gray" }}
        >
          Forget password?
        </Typography>
        <Divider sx={{ mt: 1 }} />
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Typography variant="body2" sx={{ color: "landing.gray" }}>
          By proceeding, you agree to our{" "}
          <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={props.onTermsClick} href="">
            Terms of Use
          </span>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LoginForm;
