import {Grid,Card,Typography} from '@mui/material';








const InValidPasswordKey = (props)=>{

    return(
        <Grid container sx={{marginTop:'128px'}} justifyContent="center" >
            <Grid item xs={12} md={6}>
                <Card sx={{p:2}}>
                        <Grid container sx={{p:2}} >
                            <Grid item xs={12}>
                                <Typography component={'div'} variant={'h6'} sx={{textAlign:'center'}}>
                                    Sorry something went run! your token is invalid.
                                </Typography>
                            </Grid>
                        </Grid>
                        
                </Card>
            </Grid>
        </Grid>
    )

}

export default InValidPasswordKey