import { useState, useContext, useEffect } from "react";
import Grid from "@mui/material/Grid";
import useHttp from "../../hook/useHttp";
import TestServiceTab from "./form/TestServiceTab";
import {
  testService as testServiceUrl,
  recaptchaTestService as recaptchaTestServiceUrl,
  recaptchaGetResult as recaptchaGetResultUrl,
  application as applicationUrl,
  statistics as staticUrl,
} from "../../extentions/apiUrls";
import snackContext from "../../store/snack-context";
import ModalContext from "../../store/modal-context";
import TicketForm from "../dashboard/new-design/ticket-section";
import ApplicationForm from "./form/application-section";
import CreateApplication from "../application/CreateApplication";
import { CircularProgress, Box } from "@mui/material";
import { default as DeleteWarning } from "../warnings/Warning";
import CaptchaHelp from "./form/captcha-doc";
import Implement from "./form/implement";

const RECAPTHA_FORM = {
  version: "2",
  sitekey: "",
  url: "",
};
const TestService = () => {
  const [fileName, setFileName] = useState("");
  const [codeSample, setCodeSample] = useState(undefined);
  const [application, setApplication] = useState({});
  const [token, setToken] = useState("");
  const snackCtx = useContext(snackContext);
  const modalCtx = useContext(ModalContext);
  const [file, setFile] = useState(undefined);
  const { isLoading, sendRequest: getResultRequest } = useHttp();
  const { isLoading: codeLoading, sendRequest: getStaticCodeRequest } = useHttp();
  const [result, setResult] = useState("");
  const [image, setImage] = useState();
  const [readFile, setReadFile] = useState(undefined);
  const [startAnimation, setStartAnimation] = useState(false);
  const [recaptchForm, setRecaptchaForm] = useState(RECAPTHA_FORM);
  const [tokenRadioButton, setTokenRadioButton] = useState("No");
  const { isLoading: applicationLoading, sendRequest: sendApplicationRequests } = useHttp();
  const [protocolRadioButton, setProtocolRadioButton] = useState("https://");
  const [radioButtonDisable, setRadioButtonDisable] = useState(false);

  const [recaptchaResponse, setRecapthaResponse] = useState(null);

  const tokenRadioButtonChangeHandler = (e) => {
    setTokenRadioButton(e.target.value);
    setToken("");
    setRecapthaResponse(null);
  };

  const tokenChangeHandler = (e) => {
    setToken(e.target.value);
  };

  const copyClientSecret = () => {
    navigator.clipboard.writeText(token);
    snackCtx.handleOpen("Copied to clipboard", "");
  };

  const getBase64 = (file, callBack, callback1) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function (upload) {
      // let result = upload.target.result.split(',')
      callback1(reader.result);
      let base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
      callBack(base64String);
    };
    reader.onerror = function (error) {};
  };

  const getImage = (res) => {
    setReadFile(res);
  };
  const getBase64Result = (res) => {
    setImage(res);
  };
  const handleUploadPicture = (file) => {
    if (file) {
      setFileName(file.name);
      getBase64(file, getBase64Result, getImage);
    }
  };
  const imageClickHandler = async (url) => {
    var filename = url.split("/").pop();
    setFileName(filename);
    let blob = await fetch(url).then((r) => r.blob());
    handleFileChange(blob);
  };
  const handleFileChange = (file) => {
    if (file) {
      setFile(file);
      getBase64(file, getBase64Result, getImage);
      setResult("");
    } else {
      setFile(undefined);
      setImage(undefined);
      setReadFile(undefined);
      setResult("");
    }
  };
  const handleGetResult = (res) => {
    if (res.data.status_code === 200) {
      setResult(res.data.data.result);
      setStartAnimation(true);
    } else {
      snackCtx.handleOpen(res.data.message, "");
    }
  };
  const handleShowResult = () => {
    const options = {
      method: "POST",
      url: testServiceUrl,
      data: { image: image },
    };
    setStartAnimation(false);
    getResultRequest(options, handleGetResult);
  };

  const recaptchaFormDataChangeHandler = (key, value) => {
    setRecapthaResponse(null);
    setToken(null);
    setRecaptchaForm((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };
  const getTokenResultHandler = (res) => {
    if (res.data.status_code === 200) {
      setToken(res.data.data.RecaptchaResponse || res.data.data.RecaptchaId);
      setRecaptchaForm((prevState) => {
        return {
          ...prevState,
          url: "",
          sitekey: "",
        };
      });
    } else {
      snackCtx.handleOpen(res.data.message, "");
    }
  };
  const getTokenClickHandler = () => {
    setToken(null);
    let data = { ...recaptchForm };
    data.url = protocolRadioButton + data.url.replace(/(^\w+:|^)\/\//, "");
    const options = {
      method: "POST",
      url: recaptchaTestServiceUrl,
      data: data,
    };
    getResultRequest(options, getTokenResultHandler);
  };

  const sendTokenResultHandler = (res) => {
    if (res.data.status_code === 200) {
      if (res.data.data.hasOwnProperty("step")) snackCtx.handleOpen(res.data.message, "");
      else setRecapthaResponse(res.data.data.RecaptchaResponse);
    } else {
      snackCtx.handleOpen(res.data.message, "");
    }
  };

  const sendTokenClickHandler = () => {
    setRecapthaResponse(null);
    const options = {
      method: "GET",
      url: recaptchaGetResultUrl,
      params: { recaptcha_id: token },
    };
    getResultRequest(options, sendTokenResultHandler);
  };

  const protocolRadioButtonChangeHandler = (e) => {
    setProtocolRadioButton(e.target.value);
  };

  const deleteApplicationHandler = () => {
    modalCtx.modalHandler("");
    const removeApplicationsResponseHandler = (res) => {
      modalCtx.modalHandler("");
      if (res.data.status_code === 200) {
        snackCtx.handleOpen(res.data.message, "");
        setApplication({});
      } else {
        snackCtx.handleOpen(res.data.message, "");
      }
    };
    const options = {
      method: "DELETE",
      url: applicationUrl,
    };
    sendApplicationRequests(options, removeApplicationsResponseHandler);
  };

  const handleDeleteWarningReject = () => {
    modalCtx.modalHandler("");
  };
  const handleDelete = (name, id) => {
    const content = {
      title: "",
      body: (
        <DeleteWarning
          title={"Deactivate Plan"}
          caption={`Are you sure ?`}
          description={"Your Api secret delete permanently"}
          onConfirm={deleteApplicationHandler}
          confirmCaption={"Delete"}
          onReject={handleDeleteWarningReject}
          rejectCaption={"Cancel"}
          haveReject={true}
          delete={true}
        />
      ),
    };

    modalCtx.modalHandler(content);
  };

  const createApplicationHandler = (res) => {
    if (res.data.status_code === 200) {
      snackCtx.handleOpen(res.data.message, "");
      setApplication(res.data.data);
    } else {
      snackCtx.handleOpen(res.data.message, "");
    }
  };

  const getApplicationRequestsHandler = (res) => {
    if (res.data.status_code === 200) {
      setApplication(res.data.data);
    }
  };
  useEffect(() => {
    let options = {
      method: "GET",
      url: applicationUrl,
    };
    sendApplicationRequests(options, getApplicationRequestsHandler);
  }, [sendApplicationRequests]);

  const getCodeRequestsHandler = (res) => {
    if (res.data.status_code === 200) {
      setCodeSample(res.data.data.code_samples);
    }
  };
  useEffect(() => {
    let options = {
      method: "GET",
      url: staticUrl,
    };
    getStaticCodeRequest(options, getCodeRequestsHandler);
  }, [getStaticCodeRequest]);

  const copySecretKeyHandler = (data) => {
    navigator.clipboard.writeText(data);
    snackCtx.handleOpen("Copied to clipboard", "");
  };
  return (
    <Grid container>
      <Grid item xs={12} md={11}>
        {applicationLoading ? (
          <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : Object.keys(application).length === 0 ? (
          <CreateApplication onCreateApplication={createApplicationHandler} />
        ) : (
          <ApplicationForm
            onCopySecretKey={copySecretKeyHandler}
            onDeleteApplication={handleDelete}
            application={application}
          />
        )}
      </Grid>

      <Grid item xs={12} md={11} sx={{ mt: 2 }}>
        {/* <TestServiceForm 
                    fileName={fileName} 
                    handleShowResult={handleShowResult} 
                    handleUploadPicture={handleUploadPicture} 
                    file={file}
                    fileUpload={handleFileChange}
                    result={result} 
                    isLoading={isLoading}
                    readFile={readFile}
                    startAnimation={startAnimation}
                    /> */}
        {codeLoading || codeSample === undefined ? null : (
          <TestServiceTab
            token={token}
            fileName={fileName}
            handleShowResult={handleShowResult}
            handleUploadPicture={handleUploadPicture}
            file={file}
            fileUpload={handleFileChange}
            result={result}
            isLoading={isLoading}
            readFile={readFile}
            startAnimation={startAnimation}
            onRecaptchFormChange={recaptchaFormDataChangeHandler}
            recaptchData={recaptchForm}
            onGetTokenClick={getTokenClickHandler}
            onImageClick={imageClickHandler}
            tokenRadioButton={tokenRadioButton}
            onTokenRadioButtonChange={tokenRadioButtonChangeHandler}
            onTokenChange={tokenChangeHandler}
            radioButtonDisable={radioButtonDisable}
            onCopyClientSecret={copyClientSecret}
            onSendToken={sendTokenClickHandler}
            recaptchaResponse={recaptchaResponse}
            protocolRadioButton={protocolRadioButton}
            onProtocolRadioButtonChange={protocolRadioButtonChangeHandler}
            codeLoading={codeLoading}
            codeSample={codeSample}
          />
        )}
      </Grid>
      <Grid item xs={12} md={11} justifyContent={"center"} alignItems={"center"} sx={{ mt: 4 }}>
        {codeSample === undefined ? null : <Implement codeLoading={codeLoading} codeSample={codeSample} />}
      </Grid>
      <Grid item xs={12} md={11} justifyContent={"center"} alignItems={"center"} sx={{ mt: 4 }}>
        <TicketForm />
      </Grid>
    </Grid>
  );
};

export default TestService;
