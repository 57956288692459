
import { Card, Typography,Box, Avatar,Grid } from '@mui/material'
import React from 'react'
import WalletIcon from '../../../icons/WalletIcon'
import PlusIcon from '../../../icons/PlusIcon'
import LinkSection from '../ui/link-section'
import PlanListIcon from '../../../icons/PlanListIcon'

function BalanceSection({balance,navigate}) {
  return (
    <Card type="card" sx={{position:'relative',height: "100%",padding:'1.4rem' }}>
        <Grid container spacing={1}  >
            <Grid item xs={12}>
                <Typography variant='h1' fontWeight={600} color="#202020">Balance</Typography>
                <Box sx={{position:'absolute',top:10,right:20}}>
                    <Avatar sx={{backgroundColor:'#E5FFF0',width: 60, height: 60   }}>
                        <WalletIcon back={'#5BC17E'} />
                    </Avatar>
                </Box>
                <Typography variant='h3' fontWeight={400} color="#5BC17E">$ {balance}</Typography>
            </Grid>
      
        
            <Grid item xs={12} sx={{mt:6.5}}>
                <LinkSection link={'/add-funds'}icon={<PlusIcon/>} text="Add Funds" avatar={'#FFFFFF'} background="#5BC17E" color={"#FFFFFF"}/>
            </Grid>
            <Grid item xs={12} sx={{mt:2}}>
                <LinkSection link={'/history/orders'} icon={<PlanListIcon fill={'#202020'}  />} arrow={"#202020"} text="Order History" avatar={'#EBEBEB'} background="#FAFBFB" color={"#202020"}/>
            </Grid>
            <Grid item xs={12} sx={{mt:2}}>
                <LinkSection link={'/history/payment-history'} icon={<WalletIcon back={'#202020'} width={15} height={15} />} arrow={"#202020"} avatar={'#EBEBEB'} text="Payment History"  background="#FAFBFB" color={"#202020"}/>
            </Grid>
        </Grid>
    </Card>
  )
}

export default BalanceSection