
import { Grid, Typography,Box } from '@mui/material'
import React from 'react'

function StatisticForm({title,sectionOne,valueOne,sectionTwo,valueTwo,sectionThree,valueThree}) {
  return (
    <Grid container spacing={2} sx={{borderRadius:2,p:1}}>
        <Grid item xs={12}>
            <Typography variant='body1' fontWeight={900}>
                {title}
            </Typography>
        </Grid>
        <Grid item xs={12} container>
            <Grid item xs={'auto'}>
                <Typography variant='body2' sx={{mr:1}} >
                    {sectionOne}
                </Typography>
            </Grid>
            <Grid item xs sx={{position:'relative'}}>
                <Box sx={{position:'absolute',left:0,right:13,bottom:4,borderBottom:'1px dashed gray'}}>

                </Box>
            </Grid>
            <Grid item xs={'auto'}>
                <Typography variant='body2' >
                    {valueOne}
                </Typography>
            </Grid>
        </Grid>
        <Grid item xs={12} container>
            <Grid item xs={'auto'}>
                <Typography variant='body2' sx={{mr:1}}>
                    {sectionTwo}
                </Typography>
            </Grid>
            <Grid item xs sx={{position:'relative'}}>
                <Box sx={{position:'absolute',left:0,right:13,bottom:4,borderBottom:'1px dashed gray'}}>

                </Box>
            </Grid>
            <Grid item xs={'auto'}>
                <Typography variant='body2' >
                    {valueTwo}
                </Typography>
            </Grid>
        </Grid>
        <Grid item xs={12} container>
            <Grid item xs={'auto'}>
                <Typography variant='body2' sx={{mr:1}}>
                    {sectionThree}
                </Typography>
            </Grid>
            <Grid item xs sx={{position:'relative'}}>
                <Box sx={{position:'absolute',left:0,right:13,bottom:4,borderBottom:'1px dashed gray'}}>

                </Box>
            </Grid>
            <Grid item xs={'auto'}>
                <Typography variant='body2' >
                    {valueThree}
                </Typography>
            </Grid>
        </Grid>
    </Grid>
  )
}

export default StatisticForm