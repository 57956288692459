import { Grid,Box } from "@mui/material";
import bitpay from "../../../icons/bitpay.svg";
import stripe from "../../../icons/stripe-img.svg";
import paypal from "../../../icons/paypal-img.svg";
import bitcoin from "../../../icons/btc.svg";
import tether from "../../../icons/tether.svg";
import ether from "../../../icons/ether.svg";
import paytabs from "../../../icons/paytabs.svg";
import crypto from "../../../icons/crypto.png";
import PaymentCard from "../../ui/payment-card";
import { calculateBoost } from "../../../../utils/helper";
const PaymentSection = ({ onSelectPayment, paymentMethod, paymentMethodList,buttonRef }) => {
  const icons = {
    stripe,
    paypal,
    bitcoin,
    tether,
    ether,
    paytabs,
    crypto,
    bitpay,
    
  };
  return (
    <Grid container ref={buttonRef}  spacing={3} sx={{ my: 3}} >
      {paymentMethodList?.map((item, index) => {
        if (item.visible === 1) {
          return (
            <Grid item key={index}  xs={12}  lg={6} xl={4}  >
              <Box  sx={{height:'100%'}}>
                <PaymentCard
                  self={item}
                  callback={onSelectPayment}
                  icon={icons[item.name]}
                  name={item.name}
                  checked={paymentMethod?.name === item.name}
                  boost={calculateBoost(item.attrs.boost)}
                  disabled={item.attrs.is_disable}
                  disabledText={item.attrs.disableText}
                />
               
              </Box>
            </Grid>
          );
        }
      })}
    </Grid>
  );
};

export default PaymentSection;
