import TicketDetailFormMessage from "./TicketDetailFormMessage";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { TextField, Typography, Grid, Box, Button } from "@mui/material";
import FileUploadIcon from "../icons/FileUploadIcon";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { styled } from "@mui/material/styles";
import { useEffect, useRef } from "react";
const Bullet = styled("div")((props) => ({
  "&::before": {
    color: props.color ? props.color : "#7F8081",
    content: "'\\2022'",
    fontSize: "24px",

    fontWeight: "bold",
  },
}));

const Values = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: theme.palette.primary.text,
}));
const TicketDetailForm = (props) => {
  const handleTextareaChange = (e) => {
    props.onMessageChange(e.target.value);
  };

  const messagesRef = useRef(null);

  const scrollToBottom = () => {
    messagesRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [props.messages]);
  return (
    <Grid container>
      <Grid item xs={12} sx={{ p: 1 }}>
        <div>
          <Grid
            container
            alignItems="end"
            id={"messages_container"}
            sx={{
              height: "auto",
              p: 1,
              maxHeight: "400px",
              overflowX: "hidden",
              overflowY: "auto",

              "&::-webkit-scrollbar": {
                width: "0.4em",
              },

              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "primary.main",
                borderRadius: "4px",
              },
            }}
          >
            {props.messageLoading ? (
              <Grid container justifyContent="center" sx={{ margin: "auto" }}>
                <CircularProgress />
              </Grid>
            ) : (
              props.messages.map((item, idx) => {
                return (
                  <TicketDetailFormMessage
                    key={idx}
                    from={item.from}
                    fromMe={item.fromMe}
                    message={item.message}
                    attach={item.attach}
                    date={item.date}
                  />
                );
              })
            )}
            <div ref={messagesRef}></div>
          </Grid>

          <Grid
            container
            spacing={2}
            sx={{ alignItems: "center", mt: 2, display: props.status !== "closed" ? "block" : "none" }}
          >
            <Grid item xs={12}>
              <TextField
                onChange={handleTextareaChange}
                placeholder="Write your message ..."
                autoCorrect="off"
                spellCheck={false}
                size="small"
                fullWidth
                multiline
                rows={5}
                value={props.message}
                sx={{ "& .MuiInputBase-root": { borderRadius: "8px", background: "#FFFFFF" } }}
              />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2} alingItems="center">
                  <Grid item xs={12} sm={'auto'}>
              {props.sendMessageLoading ? (
                <CircularProgress />
              ) : (
                <Button fullWidth size={"small"} onClick={props.onSendMessage} endIcon={<SendOutlinedIcon />} variant="contained" sx={{maxWidth:'100%'}}>
                  Send
                </Button>
                
              )}
            </Grid>
                  <Grid item xs={12} sm={'auto'}>
              <Button
                fullWidth
                size={"small"}
                endIcon={<FileUploadIcon />}
                variant="contained"
                component="label"
                sx={{
                  background: "white",
                  color: "#787777",
                  border: 1,
                  borderColor: "#9E9E9E",
                  "&:hover": { background: "white" },
                  maxWidth:'100%'
                }}
              >
                <input type="file" hidden onChange={props.fileUpload} />
                Choose File
              </Button>
            </Grid>
            <Grid item xs={12} sm={'auto'} sx={{mt:0.5}}>
              <Typography component={"div"} variant="body2" color="primary.text">
                Accepted File Format : JPEG,JPG,PNG,PDF
              </Typography>
              <Typography component={"div"} variant="body2" color="primary.text">
                Size Limit : 8MB
              </Typography>
            </Grid>

                </Grid>
            </Grid>
            
            <Grid item xs={12}>
              {props?.file ? (
                <Values>
                  <Bullet color={"#7897FE"} />
                  <Typography sx={{ ml: 1 }} variant="body2" component={"div"}>
                    {" "}
                    {props?.file?.name}{" "}
                  </Typography>
                  <DeleteOutlinedIcon onClick={props.fileUpload} sx={{ cursor: "pointer" }} />
                </Values>
              ) : null}
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default TicketDetailForm;
