import React from "react";

function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.192"
      height="13.44"
      viewBox="0 0 13.192 13.44"
    >
      <g
        fill="none"
        stroke="#f3f3f3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        transform="translate(1.25 1.25)"
      >
        <circle cx="4.874" cy="4.874" r="4.874" data-name="Ellipse 28"></circle>
        <path
          d="M0 0L1.911 1.906"
          data-name="Line 29"
          transform="translate(8.263 8.516)"
        ></path>
      </g>
    </svg>
  );
}

export default SearchIcon;
