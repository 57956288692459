import { Box } from "@mui/material"


const TicketButton = ({color,text,backgroundColor,borderColor,callBack})=>{

    const clickHandler = ()=>{
        callBack(text)
    }
    return(
        <Box 
            sx={{width:'auto',
                height:'17px',
                borderRadius:2,
                border:`solid 2px ${borderColor}`,
                backgroundColor:backgroundColor,
                padding:'8px 12px',
                color:color,
                cursor:'pointer',
                alignItems:'center',
                fontSize:'0.8rem',
                
            }}
            onClick={clickHandler}>
            <span>{text}    </span>
        </Box>
    )
}

export default TicketButton