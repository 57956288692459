const BASE_URL = process.env.PUBLIC_URL + "/assets/images/";

export const appBarIcon = BASE_URL + "logo_transparent.png";
export const circle = BASE_URL + "circle.svg";
export const landingImage = BASE_URL + "landing.png";
export const pageNotFound = BASE_URL + "404_generated.png";
export const BlogItemPicture = BASE_URL + "blog-pic.svg";
export const NoResultToShow = BASE_URL + "logo192.png";
export const aboutTeam = BASE_URL + "about-team.jpg";
export const whyChooseUs = BASE_URL + "whychooseus1.png";
export const meeting = BASE_URL + "meeting.svg";
export const applicationServices = BASE_URL + "service.svg";
export const team = BASE_URL + "team.svg";
export const service = BASE_URL + "service.svg";
export const faq = BASE_URL + "faq.png";
export const warning = BASE_URL + "warning.png";
export const blogPic1 = BASE_URL + "blog1.jpg";
export const blogPic2 = BASE_URL + "blog2.jpg";
export const blogPic3 = BASE_URL + "blog3.jpg";
export const diamon = BASE_URL + "diamon-c.svg";
export const dollar = BASE_URL + "dollar-c.svg";
export const metric = BASE_URL + "metrics-c.svg";
export const landing = BASE_URL + "ai.png";
export const dragAndDrop = BASE_URL + "icons8-drag-and-drop-96.png";
export const noContent = BASE_URL + "logo192.png";
export const captchaV2 = BASE_URL + "captchaV2.png";
export const captchaV3 = BASE_URL + "captchaV3.png";
export const lock = BASE_URL + "lock.png";
export const offer = BASE_URL + "offer.png";
export const offerW600 = BASE_URL + "offer-w6.png";
export const captcha1 = BASE_URL + "captcha.jpg";
export const captcha2 = BASE_URL + "captcha2.png";
export const captcha3 = BASE_URL + "captcha3.jpg";
export const captcha4 = BASE_URL + "captcha4.jpg";
export const captcha5 = BASE_URL + "captcha5.png";
export const captcha6 = BASE_URL + "captcha6.jpg";
export const captcha7 = BASE_URL + "captcha7.png";
export const captcha8 = BASE_URL + "captcha8.jpg";
export const captcha9 = BASE_URL + "captcha9.png";
export const captcha10 = BASE_URL + "captcha10.jpg";
export const captcha11 = BASE_URL + "captcha11.jpg";
export const captcha12 = BASE_URL + "captcha12.png";
export const captcha13 = BASE_URL + "captcha13.png";
export const captcha14 = BASE_URL + "captcha14.jpg";
export const captcha15 = BASE_URL + "captcha15.jpg";
export const WhatWeDo = BASE_URL + "WhatWeDo.png";
export const what = BASE_URL + "what.svg";
export const about = BASE_URL + "about.svg";
export const benefit = BASE_URL + "benefit.svg";
export const hero = BASE_URL + "hero.png";
export const loginImage = BASE_URL + "login.png";
export const brandImage = BASE_URL + "brand.svg";
export const drawerLogo = BASE_URL + "drawerlogo.svg";
export const bubbleLogo = BASE_URL + "bubble.svg";
