import {forwardRef} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const DialogComponent = (props)=>{
    
    return(
        <Dialog
         open={props.open}
         TransitionComponent={Transition}
         onClose={props.handleClose}
         fullWidth={true}
         PaperProps={{
            style: { borderRadius: 16 }
          }}
        >
            <DialogContent  >
                
                {props.content}
            </DialogContent>

        </Dialog>
    )
}

export default DialogComponent;