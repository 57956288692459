import "./App.css";

import { useEffect,useContext } from "react";
import ModalProvider from "./store/ModalProvider";
import ModalComponent from "./components/layout/ModalComponent";
import SnackBarComponent from "./components/layout/SnackBarComponent";
import SnackProvider from "./store/SnackProvider";
import AuthProvider from "./store/AuthProvider";
import DashboardProvider from "./store/DashboardProvider";
import AppRoute from "./components/AppRoute";
import BannerContext from "./store/banner-context";


function App() {
  const bannerCtx = useContext(BannerContext)
  useEffect(()=>{
    bannerCtx.loadBanners()
  },[])
  return (
    <AuthProvider>
      <ModalProvider>
        <SnackProvider>
          <DashboardProvider>
            
              <ModalComponent />

              <SnackBarComponent />

              <AppRoute />
            

            {/* <ReactRoute
        path="/dashboard"
        isExact={true}
        element={<Home/>}
      /> */}
            {/* <AppRoute
        path="/login"
        element={<Home/>}
      />) */}
          </DashboardProvider>
        </SnackProvider>
      </ModalProvider>
    </AuthProvider>
  );
}

export default App;
