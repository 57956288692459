import {Grid,TextField,Box,Button,CircularProgress,Typography} from '@mui/material';


const ForgetPasswordForm = (props)=>{

    const enterEmail = ( 
        <Grid container spacing={2} sx={{p:2}}>
            <Grid item xs={12}>
                <Typography gutterBottom variant="body2" component="div" sx={{fontWeight:'normal',color:'landing.darkGray'}}>
                    Change Password Request
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField label={"Enter your email"} value={props.email} onChange ={props.onEmailChange} variant="outlined" fullWidth />
            </Grid>
            <Grid item xs={12}>
                <Box  sx={{display:'flex',justifyContent:'center',mt:4}}> 
                    {
                        props.loading 
                            ? 
                                <CircularProgress/> 
                            :
                
                                <Button variant='contained' onClick={props.onSendRequestClick} sx={{background:'#76323F',fontSize:'12px'}} fullWidth>
                                    send request
                                </Button>
                    }
                </Box>
            </Grid>
        </Grid>
    
    )

    const successRequest = (
        <Grid container sx={{p:2}}>
            <Grid item xs={12}>
                <Typography variant='subtitle1' component={"div"}>
                    Your password change request has been sent to {props.email}
                </Typography>
            </Grid>
        </Grid>
    )

    return(
       props.sendRequestSuccess ? successRequest : enterEmail
    )

}

export default ForgetPasswordForm