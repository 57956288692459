import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { CircularProgress, InputAdornment, TextField } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CaptchaHelpForm from "../form/captcha-doc";
const RecaptchaTestForm = (props) => {
  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <TextField
          disabled={props.isLoading}
          variant="outlined"
          placeholder="please enter link"
          fullWidth
          value={props?.recaptchData?.url}
          onChange={(e) => props.onRecaptchFormChange("url", e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment
                sx={{
                  backgroundColor: "primary.main",
                  height: 55,
                  width: 50,
                  marginLeft: "-14px",
                  marginRight: 0,
                  maxHeight: 55,
                  padding: "0px 8px",
                  justifyContent: "center",
                  color: "white",
                  borderRadius: "4px 0px 0px 4px",
                  borderWidth: 1,
                  borderColor: "primary.main",
                  borderStyle: "solid",
                }}
                position="start"
              >
                <span style={{ color: "white" }}>{props.protocol}</span>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={"Site key"}
          variant="outlined"
          placeholder="please enter site key"
          fullWidth
          value={props.recaptchData.sitekey}
          disabled={props.isLoading}
          onChange={(e) => props.onRecaptchFormChange("sitekey", e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <FormControl disabled={props.isLoading}>
          <FormLabel id="select-version-label">Select version</FormLabel>
          <RadioGroup
            row
            aria-labelledby="select-version-label"
            name="row-radio-buttons-group"
            value={props.recaptchData.version}
            onChange={(e) => props.onRecaptchFormChange("version", e.target.value)}
          >
            <FormControlLabel value="2" control={<Radio />} label="V2" />
            <FormControlLabel value="3" control={<Radio />} label="V3" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" component="div">
          {props.isLoading ? (
            props.recaptchData.version === "3" ? (
              <>
                <span>Please wait a moment </span>
                <br />
                <span>Your token will be ready immediately </span>
              </>
            ) : (
              <>
                <span>Your recaptcha id will be ready immediately </span>
              </>
            )
          ) : props.token ? (
            <>
              {props.recaptchData.version === "3" ? (
                <span style={{ fontSize: "1.1rem", fontWeight: 500 }}>Your Token : </span>
              ) : (
                <span style={{ fontSize: "1.1rem", fontWeight: 500 }}>Recaptcha Response : </span>
              )}
              <ContentCopyIcon onClick={props.onCopyClientSecret} style={{ cursor: "pointer" }} />
              <br />
              <span style={{ fontSize: "0.9rem", wordBreak: "break-all", textAlign: "justify" }}>{props.token}</span>
            </>
          ) : null}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} sm={'auto'}>
            <div style={{ display: "flex", justifyContent: "start" }}>
              {props.isLoading ? (
                <CircularProgress />
              ) : (
                <Button fullWidth sx={{maxWidth:'100%'}} variant="contained" onClick={props.onGetTokenClick}>
                  {props.recaptchData.version === "3" ? "Get Token" : "Get Recaptcha Id"}
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
            
      </Grid>
      {/* <Grid item xs={12} md={11} justifyContent={"center"} alignItems={"center"} sx={{ mt: 4 }}>
        <CaptchaHelpForm title={"Recaptcha"} codeSample={props.codeSample || {}} />
      </Grid> */}
    </Grid>
  );
};

export default RecaptchaTestForm;
