import { useState, useEffect, useCallback } from "react";
import { Grid, Card, Divider, CircularProgress, Box } from "@mui/material";
import useHttp from "../../hook/useHttp";
import TransactionTable from "./TransactionTable";
import { transactionListUrl } from "../../extentions/apiUrls";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Title from "../layout/Title";
const TransactionList = () => {
  const [transactions, setTransactions] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [perPage, setPerPage] = useState(12);
  const [page, setPage] = useState(1);
  const { isLoading, sendRequest: getTransactionListRequest } = useHttp();
  const navigate = useNavigate();

  const rowClickHandler = (id) => {
    let url = "/history/payment-history/:id".replace(":id", id);
    navigate(url);
  };
  const handleChange = (event, value) => {
    setPage(value);
    // fetchData(value);
  };
  const getTransactionListRequestHandler = (res) => {
    if (res.data.status_code === 200) {
      setPage(res?.data?.data?.current_page);
      setTotalPage(res?.data?.data?.last_page);
      setTransactions(res.data.data.data);
    }
  };
  const fetchData = useCallback(
    (arg = 1) => {
      const options = {
        method: "GET",
        url: transactionListUrl,
        params: { page: arg },
      };
      getTransactionListRequest(options, getTransactionListRequestHandler);
    },
    [getTransactionListRequest]
  );

  useEffect(() => {
    fetchData(page);
  }, [getTransactionListRequest, fetchData, page]);
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={11}>
          {isLoading ? (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Card type="card">
                <Title>Payment History</Title>
                <Divider sx={{ mb: 3 }} />
                <div style={{ display: "grid" }}>
                  <TransactionTable
                    page={page}
                    perPage={perPage}
                    transactions={transactions}
                    onRowClick={rowClickHandler}
                  />
                </div>
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                  <Pagination
                    shape="rounded"
                    count={totalPage}
                    page={page * 1}
                    onChange={handleChange}
                    color="primary"
                  />
                </Box>
              </Card>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default TransactionList;
