import {useState,useContext} from 'react'
import {createEmailResendUrl} from '../../extentions/apiUrls';
import useHttp from "../../hook/useHttp";
import SnackContext from '../../store/snack-context';
import ResendEmailForm from './ResendEmailForm';

const ResendEmail = ()=>{

    const[email,setEmail] = useState('')
    const[sendRequestSuccess,setSendRequestSuccess] = useState(false)
    const{isLoading,sendRequest:sendChangePasswordRequest} = useHttp()
    const snackCtx = useContext(SnackContext)

    const emailChangeHandler = (e)=>{
        setEmail(e.target.value)
    }
    const sendChangePasswordRequestHandler = (res)=>{

        if (res.data.status_code === 200)
        {
            setSendRequestSuccess(true)
        }
        else{
            snackCtx.handleOpen(res.data.message)
        }
    }
    
    
    const onSendRequestClickHandler = ()=>{

        if(email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
        {
            const options={
                url:createEmailResendUrl,
                method:'POST',
                data:{email}
            }
            sendChangePasswordRequest(options,sendChangePasswordRequestHandler)
        }
        else
        {
            snackCtx.handleOpen('email is invalid')
        }
        
    }

    return(
        <ResendEmailForm 
            onSendRequestClick={onSendRequestClickHandler} 
            email={email} 
            loading={isLoading} 
            onEmailChange={emailChangeHandler} 
            sendRequestSuccess={sendRequestSuccess}
        />
    )
}

export default ResendEmail