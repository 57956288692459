import {useState} from 'react'
import SnackContext from './snack-context';

let defaultState = {
    snackMessage:'',
    snackType:'',
    showSnack:false
}

const SnackProvider = (props)=>{
    const[snackState,setSnackState] = useState(defaultState)
    
    const handleShowSnack = (msg,type)=>{
        setSnackState((state)=>{return{
            snackMessage:msg,
            snackType:type,
            showSnack:true
        }})

    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setSnackState((state)=>{
            return{
                ...state,
                showSnack:false
            }
        })
      };
    
    const contextValue={
        snackMessage:snackState.snackMessage,
        snackType:snackState.snackType,
        showSnack:snackState.showSnack,
        handleClose:handleClose,
        handleOpen:handleShowSnack,

    }
    return(
        <SnackContext.Provider value={contextValue}>
            {props.children}
        </SnackContext.Provider>
    )
}

export default SnackProvider;