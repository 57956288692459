import { useState } from "react";
import { Grid, Box, Typography, Divider, Card } from "@mui/material";
import Title from "../../../layout/Title";
import CaptchaHelp from "../captcha-doc";
import Package from "../package";
import PythonIcon from "../../../icons/PythonIcon";
import PhpIcon from "../../../icons/PhpIcon";
import NodeIcon from "../../../icons/NodeIcon";

export default function Implement({ codeLoading, codeSample }) {
  const [selected, setSelected] = useState("Package");
  return (
    <Card type="card">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Title>Implements</Title>
          <Divider sx={{ mb: 3 }} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs="auto">
              <Box
                name="Package"
                onClick={() => setSelected("Package")}
                sx={{
                  cursor: "pointer",
                  padding: "0.5rem 1rem",
                  background: selected === "Package" ? "#7897FE" : "white",
                  color: selected === "Package" ? "white" : "#7897FE",
                  border: "1px solid #7897FE",
                  borderRadius: 2,
                }}
              >
                <Typography variant={"h4"}>Package</Typography>
              </Box>
            </Grid>
            <Grid item xs="auto">
              <Box
                name="Pure Code"
                onClick={() => setSelected("Pure Code")}
                sx={{
                  cursor: "pointer",
                  padding: "0.5rem 1rem",
                  background: selected === "Pure Code" ? "#7897FE" : "white",
                  color: selected === "Pure Code" ? "white" : "#7897FE",
                  border: "1px solid #7897FE",
                  borderRadius: 2,
                }}
              >
                <Typography variant={"h4"}>Pure Code</Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {selected === "Pure Code" && (
          <Grid item xs={12}>
            <CaptchaHelp codeLoading={codeLoading} codeSample={codeSample} />
          </Grid>
        )}
        {selected === "Package" && (
          <Grid item xs={12} sx={{ mt: 3 }}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={"auto"}>
                <Package
                  text={"python"}
                  Icon={PythonIcon}
                  link={"https://github.com/metabypass/captcha-solver-python"}
                />
              </Grid>

              <Grid item xs={"auto"}>
                <Package text={"php"} Icon={PhpIcon} link={"https://github.com/metabypass/captcha-solver-php"} />
              </Grid>
              <Grid item xs={"auto"}>
                <Package
                  text={"javascirpt"}
                  Icon={NodeIcon}
                  link={"https://github.com/metabypass/captcha-solver-javascript"}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Card>
  );
}
