import React from 'react';

const AuthContext = React.createContext({
    token:'',
    role:'',
    permissions:[],
    firstName:'',
    lastName:'',
    email:'',
    checkPermission: (permission)=>{},
    login:(token,role,permissions,firstName,lastName,email)=>{},
    logout:()=>{}
});

export default AuthContext;